import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Button, Flex, Grid, Icon, Text } from "@aws-amplify/ui-react";
import { LoadingSpinner, LoadingSpinnerPage } from "../LoadingPage";
import { AppNavButton, FormTemplate } from "../../custom-ui-components";
import BackButton from "../../custom-ui-components/BackButton";
import HelicopterBackground from "../../Images/Helicopter Image.png";

const HelicopterDeliveries = function(props) {
	const width = props.width * 0.9;

	const helicopterImage = <img src={HelicopterBackground} alt="Helicopter" width={width + "px"}/>;

	return (
		<Flex direction={"column"} gap={"3vh"} alignItems={"center"} marginTop={"50px"} marginBottom={"300px"}>
			<Text {...props.titleTextProps} >James's Helicopter Deliveries</Text>
			<Text {...props.standardTextProps} >Deliveries available in Southern England!</Text>
			<Text {...props.standardTextProps} >Specialising in hot tub deliveries!</Text>
			<Text {...props.standardTextProps} >Get in touch for more info here: <a href="https://rb.gy/dd4207">special deliveries</a></Text>
			{helicopterImage}
		</Flex>
	);
}

export default HelicopterDeliveries;