import { banks, subjects, getSessionLengthMins, getSessionLength, compareObjects, isJSON, getErrorString } from "../../App";

const validationEnd = "frontend";

const validSubjectList = [
    "Mathematics",
    "English",
    "Physics",
    "Chemistry",
    "Biology",
    "History",
    "Geography",
    "French",
    "Spanish",
    "German",
    "Economics",
    "Business",
    "Computer Science",
    "Religious Education",
    "Sociology",
    "Psychology",
    "Engineering",
    "Music Theory",
    "Physical Education",
];

const validBanks = {
    "Barclays": "Barclays Bank UK PLC", 
    "The Co-operative Bank": "Co-operative Bank Plc", 
    "HSBC": "HSBC UK Bank Plc", 
    "Chase": "J.P. Morgan Europe Limited",
    "Lloyds Bank": "Lloyds Bank Plc",
    "Monzo": "Monzo Bank Ltd",
    "Natwest": "National Westminster Bank Plc",
    "The Royal Bank of Scotland (RBS)": "Royal Bank of Scotland Plc", 
    "Santander": "Santander UK Plc",
    "Starling": "Starling Bank Limited", 
    "TSB": "TSB Bank plc",
    "Halifax": "Halifax",
    "First Direct": "First Direct Limited",
    "Nationwide": "Nationwide Building Society", 
    "Metro Bank": "Metro Bank PLC", 
    "Revolut": "Revolut Ltd"
};

const validExamBoards = [
    "AQA",
    "OCR",
    "Pearson",
    "WJEC Eduqas",
];

const validQualificationLevels = [
    "A-Level",
    "GCSE",
    "Key Stage 3",
    "Key Stage 2",
    "Key Stage 1",
    "Other Level 3 Qualification",
    "Other Level 2 Qualification"
];

const validEducationTypes = [
	"Secondary School",
	"Sixth Form/College",
	"University",
	"Adult Education",
	"Home Education"
]

const qualificationWeightings = {
    "PhD": {
        "resultWeightings": {
            "Pass": 1
        },
        "qualificationWeighting": 1
    },
    "Master's degree": {
        "resultWeightings": {
            "Distinction": 1,
            "Merit": 0.95,
            "Pass": 0.9
        },
        "qualificationWeighting": 0.95
    },
    "Bachelor's degree": {
        "resultWeightings": {
            "First-Class Honours (1st)": 1,
            "Upper Second-Class Honours (2:1)": 0.95,
            "Lower Second-Class Honours (2:2)": 0.9,
            "Third-Class Honours (3rd)": 0.85,
            "Pass": 0.8
        },
        "qualificationWeighting": 0.9
    },
    "A-Level": {
        "resultWeightings": {
            "A*": 1,
            "A": 0.9,
            "B": 0.8,
            "C": 0.6,
            "D": 0.4,
            "E": 0.2,
            "U": 0,
            "F": 0
        },
        "qualificationWeighting": 0.8
    },
    "AS-Level": {
        "resultWeightings": {
            "A": 1,
            "B": 0.8,
            "C": 0.6,
            "D": 0.4,
            "E": 0.1,
            "U": 0,
            "F": 0
        },
        "qualificationWeighting": 0.71
    },
    "GCSE": {
        "resultWeightings": {
            "9": 1,
            "8": 0.9,
            "7": 0.8,
            "6": 0.6,
            "5": 0.4,
            "4": 0.2,
            "3": 0,
            "2": 0,
            "1": 0
        },
        "qualificationWeighting": 0.64
    },
    "IB-Higher": {
        "resultWeightings": {
            "7": 1,
            "6": 0.9,
            "5": 0.8,
            "4": 0.6,
            "3": 0.4,
            "2": 0.2,
            "1": 0
        },
        "qualificationWeighting": 0.71
    },
    "IB-Standard": {
        "resultWeightings": {
            "7": 1,
            "6": 0.9,
            "5": 0.8,
            "4": 0.6,
            "3": 0.4,
            "2": 0.2,
            "1": 0
        },
        "qualificationWeighting": 0.64
    }
};
const validQualificationResults = {};
for (const [qualificationType, qualificationDetails] of Object.entries(qualificationWeightings)) {
    const resultWeightings = qualificationDetails.resultWeightings;
    validQualificationResults[qualificationType] = Object.keys(resultWeightings);
}

//This function will check a value based on the validations given
export const validateField = function (value, validations, extraLogging = false) {
    //Validations is an array of validation objects
    //Iteratively check all validation objects
    const validationResults = [];
    for (let validation of validations) {
        validationResults.push(validate(value, validation, extraLogging));
    }

    let foundError = false;
    let foundErrorMessages = [];
    let standardValue = null;
    let standardValues = {};
    let warning = null;

    for (const result of validationResults) {
        if (result.hasError == true) {
            foundError = true;
            foundErrorMessages.push(result.errorMessage);
        }
        const validationResultInfo = Object.entries(result);
        for (const [key, value] of validationResultInfo) {
            if (key.substring(0, 8) == "standard" && value != null) {
                standardValue = value;
                standardValues[key] = value;
            }
        }
        if (result.warning != null) {
            warning = result.warning;
        }
    }
    let returnObject = {
        hasError: false,
        errorMessage: "",
        ...standardValues,
    };
    if (standardValue != null) {
        returnObject.standardValue = standardValue;
    }
    else if (warning !== null) {
        returnObject.warning = warning;
    }
    if (foundError == true) {
        returnObject ={
            hasError: true,
            errorMessage: foundErrorMessages[0]
        };
    }

    //console.log(returnObject);
    return returnObject;
}

const validate = function (value, validation, extraLogging = false) {
    try {
        const validationType = validation.type;

        const returnError = function (errorMessage, printError = extraLogging) {
            const printValidationTypes = [
            ]
            if (printError || printValidationTypes.includes(validationType)) {
                console.log("Error validating for '" + validationType + "': " + errorMessage);
                console.log("Value type: '" + typeof(value) + "', Value:");
                console.log(value);
            }
            return {
                hasError: true,
                errorMessage: errorMessage
            };
        };
        
        const returnWarning = function (warningMessage) {
            return {
                hasError: false,
                errorMessage: "",
                warning: warningMessage
            };
        };
        
        const catchError = function (valueType, error, printError = extraLogging) {
            printError && console.error("Error in " + valueType + " validation: " + getErrorString(error));
            return {
                hasError: true,
                errorMessage: valueType + " in invalid format (caused error)"
            };
        };

        if (validationType == "Required") {
            if (value == undefined || value == "" || value == null) {
                return returnError("Field is required");
            }
            const questionType = validation.questionType;
            if (questionType == "text" || questionType == "radio" || questionType == "dropdown" || questionType == "time" || questionType == "date") {
                if (value == undefined || value == "" || value == null) {
                    return returnError("Field is required");
                }
            }
            else if (questionType == "checkbox") {
                const values = Object.values(value);
                let anyValueSelected = false;
                for (const selected of values) {
                    //Check if it's a checkbox object where a minimum of one needs to be selected
                    anyValueSelected = anyValueSelected || selected;
                }
                if (anyValueSelected == false) {
                    return returnError("Field is required");
                }
            }
            else if (questionType == "autocomplete") {
                try {
                    const currentAnswer = value.label;
                    if (currentAnswer == null || currentAnswer == "") {
                        return returnError("Field is required");
                    }
                }
                catch (error) {
                    return catchError("Autocomplete", error);
                }
            }
            else if (questionType == "timerange") {
                const startTime = value.startTime;
                const endTime = value.endTime;

                if (startTime == "" && endTime == "") {
                    return returnError("Field is required");
                }
                else if (startTime == "") {
                    return returnError("Start time is required");
                }
                else if (endTime == "") {
                    return returnError("End time is required");
                }
            }
            else if (questionType == "dayavailability" || questionType == "tutorsubjects") {
                if (value.length == 0) {
                    return returnError("Field is required");
                }
            }
            else if (questionType == "weekavailability") {
                let anyDayValid = false;
                const dayAnswers = Object.values(value);
                for (const dayAnswer of dayAnswers) {
                    if (dayAnswer.length !== 0) {
                        anyDayValid = true;
                    }
                }
                if (anyDayValid == false) {
                    return returnError("Field is required");
                }
                
            }
            else if (questionType == "subjects") {
                if (typeof(value) != "object") {
                    return returnError("Subjects must be an array");
                }
                if (value.length == 0) {
                    return returnError("Field is required");
                }
            }
            else if (questionType == "existingornewaddress") {
                if (typeof(value) != "object") {
                    return returnError("Address must be object");
                }
                const address1stLine = value.address1stLine;
                const postcode = value.postcode;
                const label = value.label;
                
                if (label == null || label == "") {
                    return returnError("Address must be given");
                }

                const addressValidation = validate(address1stLine, {type:"Required", questionType:"text", "end": value.end}, extraLogging);
                const postcodeValidation = validate(postcode, {type:"Required", questionType:"text", "end": value.end}, extraLogging);
                if (addressValidation.hasError == true && postcodeValidation.hasError == true) {
                    return returnError("An address must be given");
                }
                else if (addressValidation.hasError == true) {
                    return returnError("1st address line must be complete");
                }
                else if (postcodeValidation.hasError == true) {
                    return returnError("Postcode must be complete");
                }
            }
        }

        else if (validationType == "Email" || validationType == "ValidEmail") {
            try {
                //Split into email prefix and domain
                let emailSections = value.split("@");
                //Check there is exactly 1 @ sign by ensuring the split only returned 2 array elements
                if (emailSections.length !== 2) {
                    return returnError("Email address must have 1 '@' symbol");
                }

                //Check the email domain
                let charCode = null;
                let currentCharType = null;
                let domain = emailSections[1];
                let foundDot = false;
                //Iteratively check all characters in the email domain
                for (let characterIndex = 0; characterIndex < domain.length; characterIndex = characterIndex + 1) {
                    //Get ASCII code 
                    charCode = domain.charCodeAt(characterIndex);
                    currentCharType = getCharCodeType(charCode);

                    if(currentCharType == "period") {
                        foundDot = true;
                    }

                    if (currentCharType != "letter" && currentCharType != "number" && currentCharType != "hyphen" && currentCharType != "period") {
                        return returnError("Email address domain must only contain alphanumetic characters, dots and hyphens");
                    }
                }

                if (foundDot == false) {
                    return returnError("Email address domain must include a '.'");
                }
            }
            catch (error) {
                return catchError("Email", error);
            }
        }

        else if (validationType == "UKMobPhone" || validationType == "ValidUKMobPhone" || validationType == "ValidPhoneNumber") {
            try {
                let phoneNumber = value;
                if (typeof(phoneNumber) != "string") {
                    return returnError("Phone number must be a string");
                }
                
                //Remove any spaces from the number to check the length
                phoneNumber = phoneNumber.split(" ").join("");
                //Remove any dashes from the number incase they are used
                phoneNumber = phoneNumber.split("-").join("");

                //Check all characters are numbers or '+'
                let plusFound = 0;
                for (let characterIndex = 0; characterIndex < phoneNumber.length; characterIndex = characterIndex + 1) {
                    let charCode = phoneNumber.charCodeAt(characterIndex);
                    let charType = getCharCodeType(charCode);
                    if (charType !== "number" && charType !== "plus" ) {
                        return returnError("Phone number must only contain numbers and '+'");
                    }
                    if (charType == "plus") {
                        plusFound = plusFound + 1;
                    }
                }
                //Check only 1 '+' used
                if (plusFound > 1) {
                    return returnError("Phone number must only contain one '+'");
                }
                
                //Check the length (allow a standard UK mobile number with the leading 0 missing)
                //e.g. Shortest possible: '7384648832'
                if (phoneNumber.length < 10) {
                    return returnError("Invalid mobile phone number, not enough digits");
                }
                //e.g. Longest possible: '+4407384648832'
                //I am allowing 07 after a +44 even though it's technically wrong...
                if (phoneNumber.length > 14) {
                    return returnError("Invalid mobile phone number, too many digits");
                }

                //Check if the number has a valid start and then remove any prefix so the rest of the number can be validated
                if (phoneNumber[0] == "+") {
                    //Check the country code is +44 if one is provided
                    if (phoneNumber[1] !== "4" || phoneNumber[2] !== "4") {
                        return returnError("Invalid UK mobile phone number, country code should be '+44'");
                    }
                    phoneNumber = phoneNumber.substring(3, phoneNumber.length)

                }
                else if (phoneNumber[0] == "4") {
                    //Check the country code is 44 if one is provided without the plus
                    if (phoneNumber[1] !== "4") {
                        return returnError("Invalid UK mobile phone number, country code should be '44'");
                    }
                    phoneNumber = phoneNumber.substring(2, phoneNumber.length)
                }
                else if (phoneNumber[0] == "0") {
                    //If the number starts with 0, remove it so we can test the rest of the number without the prefix
                    phoneNumber = phoneNumber.substring(1, phoneNumber.length)
                }
                else if (phoneNumber[0] == "7") {
                    //If the number starts with 7, leave it as is
                    phoneNumber = phoneNumber;
                }
                else {
                    //If the number doesn't start with one of the valid starts above, return error
                    return returnError("Invalid UK mobile phone number");
                }


                //Validate the number (now that the prefix has been removed)
                //Check the phone number starts with a 7
                if (phoneNumber[0] !== "7") {
                    return returnError("Invalid UK mobile phone number, number after prefix should be '7'");
                }
                //Check the phone number has 10 digits
                if (phoneNumber.length < 10) {
                    return returnError("Invalid mobile phone number, not enough digits");
                }
                else if (phoneNumber.length > 10) {
                    return returnError("Invalid mobile phone number, too many digits");
                }

                const standardFormatNumber = "+44 " + phoneNumber.substring(0, 4) + " " + phoneNumber.substring(4, 10);
                return ({
                    hasError: false,
                    errorMessage: "",
                    standardPhoneNumber: standardFormatNumber
                });
            }
            catch (error) {
                return catchError("Mobile phone number", error);
            }
        }

        else if (validationType == "ValidPostcode") {
            try {
                let postcode = value;
                if (postcode == null) {
                    return returnError("No postcode provided");
                }

                //Check that the postcode is a string
                if (typeof(postcode) != "string") {
                    return returnError("Postcode must be a string");
                }

                //Remove any spaces
                postcode = postcode.split(" ").join("");
                
                //Convert all letters to uppercase
                postcode = postcode.toUpperCase();

                //Split the post code into the 2 components
                //The start of the postcode can be 2-4 characters long
                const postcodeStart = postcode.substring(0, postcode.length-3);
                //The end of the postcode is always 3 characters long
                const postcodeLastThree = postcode.substring(postcode.length-3, postcode.length);

                //Check the start of the postcode is between 2 and 4 characters long
                if (postcodeStart.length < 2) {
                    return returnError("Postcode too short");
                }
                else if (postcodeStart.length > 4) {
                    return returnError("Postcode too long");
                }

                //Check that the first character is a letter (will have already been capitalised so ASCII range is only uppercase letters)
                const firstCharacterCode = postcodeStart.charCodeAt(0);
                if (firstCharacterCode < 65 || firstCharacterCode > 90) {
                    return returnError("First character must be a letter");
                }

                //Check the second character
                const secondCharacterCode = postcodeStart.charCodeAt(1);
                let secondCharacterType = null;
                if (postcodeStart.length == 2) {
                    if (secondCharacterCode < 48 || secondCharacterCode > 57) {
                        //"first part of postcode is 2 characters long and second character is not a number"
                        return returnError("Invalid postcode");
                    }
                    secondCharacterType = "number";
                }
                else if (postcodeStart.length == 4){
                    if (secondCharacterCode < 65 || secondCharacterCode > 90) {
                        //"first part of postcode is 4 characters long and second character is not a letter"
                        return returnError("Invalid postcode");
                    }
                    secondCharacterType = "letter";
                }
                else if (postcodeStart.length == 3){
                    if (secondCharacterCode < 65 || secondCharacterCode > 90) {
                        if (secondCharacterCode < 48 || secondCharacterCode > 57) {
                            //"second character is not a letter or a number"
                            return returnError("Invalid postcode");
                        }
                        secondCharacterType = "number";
                    }
                    else {
                        secondCharacterType = "letter";
                    }
                }
                
                //Check that the third character
                if (postcodeStart.length == 3) {
                    let thirdCharacterCode = postcodeStart.charCodeAt(2);
                    if (secondCharacterType == "letter") {
                        if (thirdCharacterCode < 48 || thirdCharacterCode > 57) {
                            //"second character is a letter and the third character is not a number"
                            return returnError("Invalid postcode");
                        }
                    }
                    if (secondCharacterType == "number") {
                        if ((thirdCharacterCode < 65 || thirdCharacterCode > 90) && (thirdCharacterCode < 48 || thirdCharacterCode > 57)) {
                            //"second character is a number and the third character is not a letter or a number"
                            return returnError("Invalid postcode");
                        }
                    }
                }
                else if (postcodeStart.length == 4) {
                    let thirdCharacterCode = postcodeStart.charCodeAt(2);
                    if (thirdCharacterCode < 48 || thirdCharacterCode > 57) {
                        //"first part of postcode is 4 characters long and the third character is not a number"
                        return returnError("Invalid postcode");
                    }
                }

                //Check that the fourth character is either a letter or a number
                if (postcodeStart.length == 4) {
                    let fourthCharacterCode = postcodeStart.charCodeAt(3);
                    if ((fourthCharacterCode < 65 || fourthCharacterCode > 90) && (fourthCharacterCode < 48 || fourthCharacterCode > 57)) {
                        //"fourth character is not a letter or a number"
                        return returnError("Invalid postcode");
                    }
                }

                //Check that the first character of the second part is a number
                let lastThreeFirst = postcodeLastThree.charCodeAt(0);
                if (lastThreeFirst < 48 || lastThreeFirst > 57) {
                    //"first character of second part of postcode is not a number"
                    return returnError("Invalid postcode");
                }
                //Check that the second character of the second part is a letter
                let lastThreeSecond = postcodeLastThree.charCodeAt(1);
                if (lastThreeSecond < 65 || lastThreeSecond > 90) {
                    //"second character of second part of postcode is not a letter"
                    return returnError("Invalid postcode");
                }
                //Check that the third character of the second part is a letter
                let lastThreeThird = postcodeLastThree.charCodeAt(2);
                if (lastThreeThird < 65 || lastThreeThird > 90) {
                    //"third character of second part of postcode is not a letter"
                    return returnError("Invalid postcode");
                }

                const standardPostcode = postcodeStart + " " + postcodeLastThree;
                return ({
                    hasError: false,
                    errorMessage: "",
                    standardPostcode: standardPostcode,
                });
            }
            catch (error) {
                return catchError("Postcode", error);
            }
        }

        else if (validationType == "ValidAccountNumber") {
            try {
                let accountNumber = value;

                //Check that the postcode is a string
                if (typeof(accountNumber) != "string") {
                    return returnError("Account number must be a string");
                }

                //Remove any spaces
                accountNumber = accountNumber.split(" ").join("");
                
                for (let charIndex = 0; charIndex < accountNumber.length; charIndex = charIndex + 1) {
                    const charValue = accountNumber.charCodeAt(charIndex);
                    const charType = getCharCodeType(charValue);
                    if (charType != "number") {
                        return returnError("Invalid character");
                    }
                }

                if (accountNumber.length < 8) {
                    return returnError("Account Number not long enough (enter 0s at the start if your account number is shorter)");
                }
                if (accountNumber.length > 10) {
                    return returnError("Account Number too long");
                }
            }
            catch (error) {
                return catchError("Account number", error);
            }
        }

        else if (validationType == "ValidBankDetails") {
            try {
                const bankDetails = value;
                if (bankDetails == null) {
                    return returnError("Bank details blank");
                }   

                const name = bankDetails.name;
                const firstName = bankDetails.firstName;
                const lastName = bankDetails.lastName;
                const accountNumber = bankDetails.accountNumber;
                const bankName = bankDetails.bankName;
                const sortCode = bankDetails.sortCode;
                const address = bankDetails.address;

                const validName = validate(name, {type: "Name"}, extraLogging);
                const validFirstName = validate(firstName, {type: "Name"}, extraLogging);
                const validLastName = validate(lastName, {type: "Name"}, extraLogging);
                const validAccountNumber = validate(accountNumber, {type: "ValidAccountNumber"}, extraLogging);
                const validSortCode = validate(sortCode, {type: "ValidSortCode"}, extraLogging);
                const validAddress = validate(address, {type: "ValidAddress"}, extraLogging);

                const validBanksArray = Object.values(validBanks);
                let foundValidBank = false;
                for (const validBank of validBanksArray) {
                    if (bankName == validBank) {
                        foundValidBank = true;
                    }
                }
                
                if (validName.hasError) {
                    return returnError(validName.errorMessage);
                }
                else if (validFirstName.hasError) {
                    return returnError(validFirstName.errorMessage);
                }
                else if (validLastName.hasError) {
                    return returnError(validLastName.errorMessage);
                }
                else if (validAccountNumber.hasError) {
                    return returnError(validAccountNumber.errorMessage);
                }
                else if (validSortCode.hasError) {
                    return returnError(validSortCode.errorMessage);
                }
                else if (validAddress.hasError) {
                    return returnError(validAddress.errorMessage);
                }
                else if (foundValidBank == false) {
                    return returnError("Invalid bank name");
                }

                const standardBankDetails = {
                    name: validName.standardName,
                    firstName: validFirstName.standardName,
                    lastName: validLastName.standardName,
                    accountNumber: accountNumber,
                    sortCode: validSortCode.standardSortCode,
                    bankName: bankName,
                    address: validAddress.standardAddress
                };

                if (bankDetails.hasOwnProperty("airwallexBeneficiaryID") && bankDetails.airwallexBeneficiaryID != null) {
                    standardBankDetails.airwallexBeneficiaryID = bankDetails.airwallexBeneficiaryID;
                }

                return ({
                    hasError: false,
                    errorMessage: "",
                    standardBankDetails: standardBankDetails
                });
            }
            catch (error) {
                return catchError("Bank details", error);
            }
        }

        else if (validationType == "ValidSortCode") {
            try {
                let sortCode = value;

                //Check that the postcode is a string
                if (typeof(sortCode) != "string") {
                    return returnError("Sort code must be a string");
                }

                //Remove any spaces
                sortCode = sortCode.split(" ").join("");
                
                let numDashes = 0;
                let dash1Index = null;
                let dash2Index = null;
                for (let charIndex = 0; charIndex < sortCode.length; charIndex = charIndex + 1) {
                    const charValue = sortCode.charCodeAt(charIndex);
                    const charType = getCharCodeType(charValue);
                    if (charType != "number" && charType != "hyphen") {
                        return returnError("Invalid character in sort code");
                    }
                    else if (charType == "hyphen") {
                        if (numDashes == 0) {
                            dash1Index = charIndex;
                        }
                        else if (numDashes == 1) {
                            dash2Index = charIndex;
                        }
                        else {
                            return returnError("Invalid sort code, too many hyphens");
                        }
                        numDashes = numDashes + 1;
                    }
                }

                if (dash1Index != null && dash1Index != 2) {
                    return returnError("Invalid sort code, wrong hyphen placement");
                }
                else if (dash2Index != null && dash2Index != 5) {
                    return returnError("Invalid sort code, wrong hyphen placement");
                }

                const numberSortCode = sortCode.split("-").join("");

                if (numberSortCode.length > 4 && (dash1Index == null && dash2Index != null || dash1Index != null && dash2Index == null)) {
                    return returnError("Invalid sort code, wrong hyphen placement");
                }

                if (numberSortCode.length < 6) {
                    return returnError("Invalid sort code, not long enough");
                }
                else if (numberSortCode.length > 6) {
                    return returnError("Invalid sort code, too long");
                }

                const standardSortCode = numberSortCode.substring(0, 2) + "-" + numberSortCode.substring(2, 4) + "-" + numberSortCode.substring(4, 6);
                return ({
                    hasError: false,
                    errorMessage: "",
                    standardSortCode: standardSortCode
                });

            }
            catch (error) {
                return catchError("Sort code", error);
            }
        }

        else if (validationType == "ValidAddress1stLine") {
            try {
                if (value == null) {
                    return returnError("Address 1st line can't be null");
                }
                if (typeof(value) != "string") {
                    return returnError("Address 1st line should be string");
                }
                if (value.length == 0) {
                    return returnError("Address 1st line can't be blank");
                }
                for (const character of value) {
                    const charCode = character.charCodeAt(0);
                    const charType = getCharCodeType(charCode);
                    const validCharList = ["letter", "number", "hyphen", "underscore", "period", "space", "hashtag", "apostrophe", "forwardslash", "comma"];
                    let validChar = false;
                    for (const validCharType of validCharList) {
                        if (charType == validCharType) {
                            validChar = true;
                            break;
                        }
                    }
                    if (validChar != true) {
                        return returnError("Invalid charcter in 1st line of address");
                    }
                }
            }
            catch (error) {
                return catchError("1st line of address", error);
            }
        }

        else if (validationType == "ValidWeekAvailability") {
            try {
                const weekAvailability = Object.values(value);
                let anyInvalidDays = false;
                for (let dayAvailabilityIndex = 0; dayAvailabilityIndex < weekAvailability.length; dayAvailabilityIndex = dayAvailabilityIndex + 1) {
                    const dayAvailability = weekAvailability[dayAvailabilityIndex];
                    const validDayAvailability = validate(dayAvailability, {"type": "ValidDayAvailability"}, extraLogging);
                    anyInvalidDays = anyInvalidDays || validDayAvailability.hasError;
                    if (anyInvalidDays) {
                        return returnError(validDayAvailability.errorMessage);
                    }
                }

                const days = Object.keys(value);
                if (days.length !== 7) {
                    return returnError("Incorrect number of days in week availability");
                }
                const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                for (const dayName of dayNames) {
                    if (!value.hasOwnProperty(dayName)) {
                        return returnError("Incorrect day keys in week availability");
                    }
                }

                const checkGivenAmountTime = function (availability, sessionLength, numSessions, bypassSanityCheck) {
                    //Ignore all availability windows that are shorter than the requested session length
                    let availabilityArray = availability;
                    if (!Array.isArray(availability)) {
                        availabilityArray = Object.values(availability);
                    }
                    
                    if (sessionLength == null) {
                        throw "Session length not given";
                    }
                    let sessionLengthMins = sessionLength;
                    if (typeof(sessionLengthMins) == "string") {
                        sessionLengthMins = getSessionLengthMins(sessionLength);
                    }
                    if (typeof(sessionLengthMins) != "number") {
                        throw "Session length not valid";
                    }
                    if (numSessions == null) {
                        throw "Number of sessions not given";
                    }
                    if (typeof(numSessions) != "number") {
                        throw "Number of sessions not a number";
                    }

                    //Number of time windows of length greater than session length
                    let numValidTimeWindows = 0;
                    //Number of days (out of 7) with a valid time window in
                    let numDaysWithValidWindow = 0;
                    //Total number of minutes of availability (not including windows shorter than session length)
                    let totalValidAvailability = 0;
                    for (const dayAvailability of availabilityArray) {
                        let validDay = false;
                        for (const availabilityWindow of dayAvailability) {
                            const startTime = availabilityWindow.startTime;
                            const endTime = availabilityWindow.endTime;
                            
                            const startTimeHours = parseInt(startTime.split(":")[0], 10);
                            const startTimeMinutes = parseInt(startTime.split(":")[1], 10);
                            const startTimeInt = (startTimeHours * 60) + startTimeMinutes;
                            
                            const endTimeHours = parseInt(endTime.split(":")[0], 10);
                            const endTimeMinutes = parseInt(endTime.split(":")[1], 10);
                            const endTimeInt = (endTimeHours * 60) + endTimeMinutes;
                            
                            const durationMinutes = endTimeInt - startTimeInt;
                            
                            if (durationMinutes >= sessionLengthMins) {
                                numValidTimeWindows = numValidTimeWindows + 1;
                                validDay = true;
                                totalValidAvailability = totalValidAvailability + durationMinutes;
                            }
                        }
                        if (validDay == true) {
                            numDaysWithValidWindow = numDaysWithValidWindow + 1;
                        }
                    }
                    if (numDaysWithValidWindow < numSessions) {
                        throw "Not enough availability given";
                    }
                    
                    const totalRequestedMinutes = sessionLengthMins * numSessions;
                    if (bypassSanityCheck != null && (bypassSanityCheck.bypass != true || !compareObjects(availability, bypassSanityCheck.previousAvailability))) {
                        if (totalValidAvailability < (totalRequestedMinutes * 3)) {
                            return "Warning: Limited availability given";
                        }
                    }
                    
                    return true;
                };

                if (validation.minSessionLength != null) {
                    try {
                        let numSessions = 1;
                        if (validation.numSessions != null) {
                            numSessions = validation.numSessions;
                        }
                        let bypassSanityCheck = null;
                        if (validation.bypassSanityCheck != null) {
                            if (typeof(validation.bypassSanityCheck) != "object"){
                                throw "bypassSanityCheck not an object";
                            }
                            bypassSanityCheck = validation.bypassSanityCheck;
                        }
                        const timeCheck = checkGivenAmountTime(value, validation.minSessionLength, numSessions, bypassSanityCheck);
                        if (timeCheck != true) {
                            return returnWarning(timeCheck);
                        }
                    }
                    catch (error) {
                        return returnError(error);
                    }
                }
            }
            catch (error) {
                return catchError("Week availability", error);
            }
        }

        else if (validationType == "ValidISODateTime") {
            try {
                if (value == null || value == "") {
                    return returnError("DateTime can't be null");
                }

                if (value.length != 24) {
                    return returnError("Invalid length for ISO DateTime: " + value.length);
                }

                if (value[4] != "-" || value[7] != "-" || value[10] != "T" || value[13] != ":" || value[16] != ":" || value[19] != ".") {
                    return returnError("Invalid format for ISO DateTime");
                }

                const year = value.substring(0, 4);
                if (parseInt(year) < 2024 || parseInt(year) > 3000) {
                    return returnError("Invalid year value: " + year);
                }
                const month = value.substring(5, 7);
                if (parseInt(month) < 0 || parseInt(month) > 12) {
                    return returnError("Invalid month value: " + month);
                }
                const day = value.substring(8, 10);
                if (parseInt(day) < 0 || parseInt(day) > 31) {
                    return returnError("Invalid day value: " + day);
                }
                const hour = value.substring(11, 13);
                if (parseInt(hour) < 0 || parseInt(hour) > 23) {
                    return returnError("Invalid hour value: " + hour);
                }
                const minute = value.substring(14, 16);
                if (parseInt(minute) < 0 || parseInt(minute) > 59) {
                    return returnError("Invalid minute value: " + minute);
                }
                const second = value.substring(17, 19);
                if (parseInt(second) < 0 || parseInt(second) > 59) {
                    return returnError("Invalid second value: " + second);
                }
                const millisecond = value.substring(20, 23);
                if (parseInt(millisecond) < 0 || parseInt(millisecond) > 999) {
                    return returnError("Invalid millisecond value: " + millisecond);
                }
                const timezone = value.substring(23, 24);
                if (timezone != "Z") {
                    return returnError("Invalid timezone value: " + timezone);
                }
            }
            catch (error) {
                return catchError("ISO DateTime", error);
            }
        }

        else if (validationType == "ValidTime") {
            try {
                if (value == null || value == "") {
                    return returnError("Time can't be null");
                }
                const timeArray = value.split(":");
                if (timeArray.length != 2) {
                    return returnError("Time in invalid format, has more than 1 ':'");
                }
                const hour = timeArray[0];
                const minute = timeArray[1];

                if (hour.length > 2) {
                    return returnError("Hour invalid length string");
                }
                if (minute.length > 2) {
                    return returnError("Minute invalid length string");
                }

                const hourInt = parseInt(timeArray[0]);
                const minuteInt = parseInt(timeArray[1]);

                if (hourInt < 0 || hourInt > 23) {
                    return returnError("Invalid hour value");
                }
                if (minuteInt < 0 || minuteInt > 59) {
                    return returnError("Invalid minute value");
                }
            }
            catch (error) {
                return catchError("Time", error);
            }
        }

        else if (validationType == "ValidTimeRange") {
            try {
                if (typeof(value) != "object") {
                    return returnError("Value not valid object (contact developer)");
                }
                const startTime = value.startTime;
                const endTime = value.endTime;

                if (startTime == "" && endTime == "") {
                    return returnError("Start and end time can't be blank");
                }
                else if (startTime == "") {
                    return returnError("Start time can't be blank");
                }
                else if (endTime == "") {
                    return returnError("End time can't be blank");
                }
                const startHour = parseInt(startTime.split(":")[0]);
                const startMinute = parseInt(startTime.split(":")[1]);
                const startTimeInt = (startHour * 60) + startMinute;
                const endHour = parseInt(endTime.split(":")[0]);
                const endMinute = parseInt(endTime.split(":")[1]);
                const endTimeInt = (endHour * 60) + endMinute;
                if (endHour < startHour) {
                    return returnError("Start time must be before end time");
                }
                else if (endHour == startHour) {
                    if (endMinute < startMinute) {
                        return returnError("Start time must be before end time");
                    }
                    if (endMinute == startMinute) {
                        return returnError("Start time and end time must be different");
                    }
                }
                
                const validStartTime = validate(startTime, {"type":"ValidTime"}, extraLogging)
                if (validStartTime.hasError == true) {
                    return returnError(validStartTime.errorMessage);
                }
                const validEndTime = validate(endTime, {"type":"ValidTime"}, extraLogging)
                if (validEndTime.hasError == true) {
                    return returnError(validEndTime.errorMessage);
                }

                const minSessionLength = value.minSessionLength;
                let desiredDurationMinutes = value.desiredDurationMinutes;
                if (desiredDurationMinutes == null) {
                    desiredDurationMinutes = validation.desiredDurationMinutes;
                }
                const durationMinutes = endTimeInt - startTimeInt;
                if (minSessionLength != null && desiredDurationMinutes == null) {
                    if (typeof(minSessionLength) == "number") {
                        desiredDurationMinutes = minSessionLength;
                    }
                    else {
                        if (minSessionLength == "45 Minutes") {
                            desiredDurationMinutes = 45;
                        } 
                        else if (minSessionLength == "1 Hour") {
                            desiredDurationMinutes = 60;
                        }
                        else if (minSessionLength == "1 Hour 30 Minutes") {
                            desiredDurationMinutes = 90;
                        }
                        else if (minSessionLength == "2 Hours") {
                            desiredDurationMinutes = 120;
                        }
                    }
                    if (durationMinutes < desiredDurationMinutes) {
                        return returnError("Time range shorter than requested session length");
                    }
                }
                else if (desiredDurationMinutes != null) {
                    if (durationMinutes < desiredDurationMinutes) {
                        return returnError("Time range shorter than requested session length");
                    }
                    else if (durationMinutes > desiredDurationMinutes) {
                        return returnError("Time range longer than requested session length");
                    }
                }
            }
            catch (error) {
                return catchError("Time range", error);
            }
        }

        else if (validationType == "NoTimeOverlaps") {
            try {
                if (typeof(value) != "object") {
                    return returnError("Given value is not an object: " + typeof(value));
                }
                //Check that every time range doesn't over lap with any other time range
                for (let index1 = 0; index1 < value.length; index1 = index1 + 1) {
                    const timeRange1 = value[index1];
                    for (let index2 = index1 + 1; index2 < value.length; index2 = index2 + 1) {
                        const timeRange2 = value[index2];

                        const startTime1 = timeRange1.startTime;
                        const endTime1 = timeRange1.endTime;
                        const startTime2 = timeRange2.startTime;
                        const endTime2 = timeRange2.endTime;

                        const startTime1Hour = parseInt(startTime1.split(":")[0]);
                        const startTime1Minute = parseInt(startTime1.split(":")[1]);
                        const endTime1Hour = parseInt(endTime1.split(":")[0]);
                        const endTime1Minute = parseInt(endTime1.split(":")[1]);

                        const startTime2Hour = parseInt(startTime2.split(":")[0]);
                        const startTime2Minute = parseInt(startTime2.split(":")[1]);
                        const endTime2Hour = parseInt(endTime2.split(":")[0]);
                        const endTime2Minute = parseInt(endTime2.split(":")[1]);

                        const startTime1Int = (startTime1Hour * 60) + startTime1Minute
                        const endTime1Int = (endTime1Hour * 60) + endTime1Minute
                        const startTime2Int = (startTime2Hour * 60) + startTime2Minute
                        const endTime2Int = (endTime2Hour * 60) + endTime2Minute

                        const day1 = timeRange1.day || null;
                        const day2 = timeRange2.day || null;

                        if (startTime1Int >= startTime2Int && startTime1Int <= endTime2Int && day1 == day2) {
                            return returnError("Times cannot overlap");
                        }
                        else if (startTime2Int >= startTime1Int && startTime2Int <= endTime1Int && day1 == day2) {
                            return returnError("Times cannot overlap");
                        }
                    } 
                }
            }
            catch (error) {
                return catchError("Time range", error);
            }
        }

        else if (validationType == "TimeWithinTimeRange") {
            try {
                if (!isJSON(value)) {
                    return returnError("Given value is not a JSON object: " + typeof(value));
                }
                if (value.time == null) {
                    return returnError("No time given");
                }
                const time = value.time;
                const validTime = validate(time, {"type": "ValidTime"}, extraLogging);
                if (validTime.hasError == true) {
                    return returnError(validTime.errorMessage);
                }

                if (value.timeRange == null) {
                    return returnError("No time range given");
                }
                const timeRange = value.timeRange;
                const validTimeRange = validate(timeRange, {"type": "ValidTimeRange"}, extraLogging);
                if (validTimeRange.hasError == true) {
                    return returnError(validTimeRange.errorMessage);
                }
                if (timeRange.startTime == null) {
                    return returnError("No start time given");
                }
                const startTime = timeRange.startTime;
                if (timeRange.endTime == null) {
                    return returnError("No end time given");
                }
                const endTime = timeRange.endTime;

                const startTimeHour = parseInt(startTime.split(":")[0]);
                const startTimeMinute = parseInt(startTime.split(":")[1]);
                const endTimeHour = parseInt(endTime.split(":")[0]);
                const endTimeMinute = parseInt(endTime.split(":")[1]);

                const startTimeInt = (startTimeHour * 60) + startTimeMinute;
                const endTimeInt = (endTimeHour * 60) + endTimeMinute;

                const timeHour = parseInt(time.split(":")[0]);
                const timeMinute = parseInt(time.split(":")[1]);

                const timeInt = (timeHour * 60) + timeMinute;
                if (timeInt < startTimeInt || timeInt > endTimeInt) {
                    return returnError("Time not within timerange");
                }
            
            }
            catch (error) {
                return catchError("Time/TimeRange", error);
            }
        }

        else if (validationType == "TimeRangeWithinTimeRange") {
            try {
                if (typeof(value) != "object") {
                    return returnError("Given value is not an object: " + typeof(value));
                }
                if (value.outerTimeRange == null) {
                    return returnError("No outer time range given");
                }
                const outerTimeRange = value.outerTimeRange;
                const validOuterTimeRange = validate(outerTimeRange, {"type": "ValidTimeRange"}, extraLogging);
                if (validOuterTimeRange.hasError == true) {
                    return returnError(validOuterTimeRange.errorMessage);
                }

                if (value.innerTimeRange == null) {
                    return returnError("No inner time range given");
                }
                const innerTimeRange = value.innerTimeRange;
                const validInnerTimeRange = validate(innerTimeRange, {"type": "ValidTimeRange"}, extraLogging);
                if (validInnerTimeRange.hasError == true) {
                    return returnError(validInnerTimeRange.errorMessage);
                }

                const innerStartTime = innerTimeRange.startTime;
                const innerEndTime = innerTimeRange.endTime;

                const startTimeTestValue = {
                    time: innerStartTime,
                    timeRange: outerTimeRange
                }
                const endTimeTestValue = {
                    time: innerEndTime,
                    timeRange: outerTimeRange
                }

                const validInnerStartTime = validate(startTimeTestValue, {"type": "TimeWithinTimeRange"}, extraLogging);
                const validInnerEndTime = validate(endTimeTestValue, {"type": "TimeWithinTimeRange"}, extraLogging);
                
                if (validInnerStartTime.hasError || validInnerEndTime.hasError) {
                    return returnError("Inner time range not within outer time range");
                }
            }
            catch (error) {
                return catchError("Time/TimeRange", error);
            }
        }

        else if (validationType == "TimeRangeWithinTimeRanges") {
            try {
                if (!isJSON(value)) {
                    return returnError("Given value is not a JSON object: " + typeof(value));
                }
                if (value.outerTimeRanges == null) {
                    return returnError("No outer time ranges given");
                }
                const outerTimeRanges = value.outerTimeRanges;
                if (outerTimeRanges.length < 1) {
                    return returnError("No outer time ranges given");
                }

                if (value.innerTimeRange == null) {
                    return returnError("No inner time range given");
                }
                const innerTimeRange = value.innerTimeRange;
                
                let isValid = false;
                for (const outerTimeRange of outerTimeRanges) {
                    const validationObject = {
                        outerTimeRange: outerTimeRange,
                        innerTimeRange: innerTimeRange
                    };
                    const validateTest = validate(validationObject, {"type": "TimeRangeWithinTimeRange"}, extraLogging);
                    if (validateTest.hasError == false) {
                        isValid = true;
                    }
                }
                if (isValid == false) {
                    return returnError("Inner time range not within any outer time range");
                }
                
            }
            catch (error) {
                return catchError("Time/TimeRange", error);
            }
        }

        else if (validationType === "ValidDayAvailability") {
            try {
                try {
                    const noOverlaps = validate(value, {"type": "NoTimeOverlaps"}, extraLogging);
                    if (noOverlaps.hasError) {
                        return returnError(noOverlaps.errorMessage);
                    }
                }
                catch (error) {
                    return returnError("Error for no overlaps within valid day availability");
                }

                let minSessionLength = validation.minSessionLength || 0;
                if (typeof(minSessionLength) == "string") {
                    minSessionLength = getSessionLengthMins(minSessionLength);
                }

                let anyTimeRangeErrors = false;
                let totalValidRanges = 0;
                for (let timeRangeIndex = 0; timeRangeIndex < value.length; timeRangeIndex = timeRangeIndex + 1) {
                    const timeRange = value[timeRangeIndex];
                    const validTimeRange = validate(timeRange, {"type": "ValidTimeRange"}, extraLogging);
                    anyTimeRangeErrors = anyTimeRangeErrors || validTimeRange.hasError;
                    if (anyTimeRangeErrors) {
                        return returnError(validTimeRange.errorMessage);
                    }

                    const startTime = timeRange.startTime;
                    const endTime = timeRange.endTime;
                    const sessionLength = getSessionLength(startTime, endTime, false);
                    if (sessionLength >= minSessionLength) {
                        totalValidRanges = totalValidRanges + 1;
                    }

                    let timeRangeAsArray = Object.entries(timeRange);
                    if (timeRangeAsArray.length !== 2) {
                        if (timeRangeAsArray.length == 3) {
                            if (timeRange.startTimeInt == null && timeRange.endTimeInt == null && timeRange.minSessionLength == null) {
                                return returnError("Time range doesn't just have start time and end time");
                            }
                        }
                        if (timeRangeAsArray.length == 4) {
                            if (timeRange.startTimeInt == null || timeRange.endTimeInt == null) {
                                return returnError("Time range doesn't just have start time and end time");
                            }
                        }
                    }
                    delete timeRange.startTimeInt;
                    delete timeRange.endTimeInt;
                    delete timeRange.minSessionLength;
                    timeRangeAsArray = Object.entries(timeRange);
                    if (timeRangeAsArray[0][0] != "startTime" || timeRangeAsArray[1][0] != "endTime") {
                        return returnError("Time range doesn't just have start time and end time");
                    }
                }

                let minValidRanges = validation.minValidRanges || 0;
                if (totalValidRanges < minValidRanges) {
                    return returnError("Not enough valid time ranges");
                }
            }
            catch (error) {
                return catchError("Day availability", error);
            }
        }

        else if (validationType == "ValidStartDate") {
            try {
                const lesson = value.lesson;
                const lessonStartDate = new Date(lesson.startDate);
                const startPointDate = new Date(value.startPointDate);

                if (lessonStartDate.valueOf() < startPointDate.valueOf()) {
                    return returnError("Lesson start date before requested start date");
                }
                const oneSecond = 1000;
                const oneMinute = oneSecond * 60;
                const oneHour = oneMinute * 60;
                const oneDay = oneHour * 24;
                const oneWeek = oneDay * 7;
                const twoWeeks = oneWeek * 2;
                if (lessonStartDate.valueOf() > (startPointDate.valueOf() + twoWeeks + oneDay)) {
                    return returnError("Lesson start date more than 2 weeks after requested start date");
                }
            }
            catch (error) {
                return catchError("Start date", error);
            }
        }

        else if (validationType == "NotSameSubject") {
            try {
                for (let answerIndex1 = 0; answerIndex1 < value.length; answerIndex1 = answerIndex1 + 1) {
                    const subject1 = value[answerIndex1];

                    for (let answerIndex2 = answerIndex1 + 1; answerIndex2 < value.length; answerIndex2 = answerIndex2 + 1) {
                        const subject2 = value[answerIndex2];
                        
                        if (subject1 == subject2) {
                            return returnError("Cannot choose the same subject twice");
                        }
                    }
                }
            }
            catch (error) {
                return catchError("Subjects", error);
            }
        }

        else if (validationType == "ValidSubjects") {
            try {
                const noSubjectRepetition = validate(value, {"type": "NotSameSubject"}, extraLogging);
                if (noSubjectRepetition.hasError) {
                    returnError(noSubjectRepetition.errorMessage);
                }

                let anySubjectErrors = false;
                for (let subjectIndex = 0; subjectIndex < value.length; subjectIndex = subjectIndex + 1) {
                    const subjectAnswer = value[subjectIndex];
                    const validSubjectAnswer = validate(subjectAnswer, {"type": "ValidSubject"}, extraLogging);
                    anySubjectErrors = anySubjectErrors || validSubjectAnswer.hasError;
                    if (anySubjectErrors) {
                        returnError(validSubjectAnswer.errorMessage);
                    }
                }
            }
            catch (error) {
                return catchError("Subjects", error);
            }
        }

        else if (validationType == "ValidSubject") {
            try {        
                const subjectName = value;
                let validSubjectName = false;
                for (const validSubject of validSubjectList) {
                    if (subjectName == validSubject) {
                        validSubjectName = true;
                    }
                }
                if (validSubjectName != true) {
                    return returnError("Invalid subject chosen: " + subjectName);
                }
            }
            catch (error) {
                return catchError("Subject", error);
            }
        }

        else if (validationType == "ValidExamBoard") {
            try {
                if (value == null) {
                    return returnError("No exam board given");
                }
                if (typeof(value) != "string") {
                    return returnError("Exam board not string");
                }
                if (!validExamBoards.includes(value)) {
                    return returnError("Invalid exam board chosen");
                }
            }
            catch (error) {
                return catchError("Exam board", error);
            }
        }

        else if (validationType == "ValidStudentSubject") {
            try {
                const studentSubjectObject = value;
                if (!isJSON(studentSubjectObject)) {
                    return returnError("Subject not in valid format");
                }
                const subjectName = studentSubjectObject.subject;
                const validSubjectName = validate(subjectName, {type: "ValidSubject"}, extraLogging);
                if (validSubjectName.hasError) {
                    return returnError(validSubjectName.errorMessage);
                }

                const qualificationLevel = studentSubjectObject.level;
                const validQualificationLevels = [
                    "GCSE",
                    "A-Level",
                    "Key Stage 3",
                    "Key Stage 2",
                    "Key Stage 1",
                    "Other Level 3 Qualification",
                    "Other Level 2 Qualification"
                ];
                let isValidLevel = false;
                for (const validQualificationLevel of validQualificationLevels) {
                    if (qualificationLevel == validQualificationLevel) {
                        isValidLevel = true;
                    }
                }
                if (isValidLevel != true) {
                    return returnError("Invalid qualification level");
                }

                const validExamBoards = [
                    "AQA",
                    "OCR",
                    "Pearson",
                    "WJEC Eduqas",
                    "N/A"
                ];   
                const examBoard = value.examBoard;
                let isValidExamBoard = false;
                for (const validExamBoard of validExamBoards) {
                    if (examBoard == validExamBoard) {
                        isValidExamBoard = true;
                    }
                }
                if (isValidExamBoard != true && examBoard != null && examBoard != "") {
                    return returnError("Invalid exam board chosen");
                }
            }
            catch (error) {
                return catchError("Student subject", error);
            }
        }

        else if (validationType == "ValidTutorSubject") {
            try {
                //Subject format: [subjectName, {subjectDetails}];

                if (value == null) {
                    return returnError("No subject provided");
                }
                if (typeof(value) != "object" || !Array.isArray(value) || value.length != 2) {
                    return returnError("Subject not in valid format");
                }

                const subjectName = value[0];
                if (subjectName == null) {
                    return returnError("No subject name given");
                }
                if (typeof(subjectName) != "string") {
                    return returnError("Subject name not string");
                }

                const validTutorSubjectList = [...validSubjectList, "Other"];
                let validTutorSubjectName = false;
                for (const validSubjectName of validTutorSubjectList) {
                    if (subjectName == validSubjectName) {
                        validTutorSubjectName = true;
                    }
                }
                if (validTutorSubjectName != true) {
                    return returnError("Invalid subject chosen: " + subjectName);
                }

                const subjectQualifications = value[1];
                if (subjectQualifications == null) {
                    return returnError("No subject qualifications given");
                }
                if (typeof(subjectQualifications) != "object") {
                    return returnError("Subject qualifications not object");
                }

                const qualificationsArray = Object.entries(subjectQualifications);
                if (qualificationsArray.length < 1) {
                    return returnError("No subject qualifications given");
                }

                for (const qualification of qualificationsArray) {
                    const checkQualification = qualification[1];
                    checkQualification.qualificationType = qualification[0];
                    checkQualification.subjectName = subjectName;
                    const validQualification = validate(checkQualification, {"type": "ValidSubjectQualification"}, extraLogging);
                    if (validQualification.hasError) {
                        return returnError("Invalid subject qualification: " + validQualification.errorMessage);
                    }
                }   
            }
            catch (error) {
                return catchError("Tutor subject", error);
            }
        }

        else if (validationType == "ValidTutorQualification") {
            try {
                //Qualification format: {subject!, qualificationType!, result!, examBoard, year, tutorEducationID};

                if (!isJSON(value)) {
                    return returnError("Qualification not in valid format");
                }

                const subjectName = value.subject;
                const validSubjectName = validate(subjectName, {"type": "ValidSubject"}, extraLogging);
                if (validSubjectName.hasError) {
                    return returnError(validSubjectName.errorMessage);
                }

                const qualificationType = value.qualificationType;
                const validQualificationType = validate(qualificationType, {"type": "ValidQualificationType"}, extraLogging);
                if (validQualificationType.hasError) {
                    return returnError(validQualificationType.errorMessage);
                }

                const qualificationResult = value.result;
                const validQualificationResult = validate(qualificationResult, {"type": "ValidQualificationResult", "qualificationType": qualificationType}, extraLogging);
                if (validQualificationResult.hasError) {
                    return returnError(validQualificationResult.errorMessage);
                }

                const examBoard = value.examBoard;
                if (examBoard != null && examBoard != "") {
                    const validExamBoard = validate(examBoard, {"type": "ValidExamBoard"}, extraLogging);
                    if (validExamBoard.hasError) {
                        return returnError(validExamBoard.errorMessage);
                    }
                }
                const year = value.year;
                if (year != null) {
                    const validYear = validate(year, {"type": "ValidYear"}, extraLogging);
                    if (validYear.hasError) {
                        return returnError(validYear.errorMessage);
                    }
                }
                const tutorEducationID = value.tutorEducationID;
                if (tutorEducationID != null) {
                    const validTutorEducationID = validate(tutorEducationID, {"type": "ValidID"}, extraLogging);
                    if (validTutorEducationID.hasError) {
                        return returnError(validTutorEducationID.errorMessage);
                    }
                }
            }
            catch (error) {
                return catchError("Tutor qualification", error);
            }
        }

        else if (validationType == "ValidTutorQualifications") {
            try {
                if (value == null) {
                    return returnError("No qualifications provided");
                }
                if (!Array.isArray(value)) {
                    return returnError("Qualifications not an array");
                }

                if (value.length < 1 && validation.required == true) {
                    return returnError("No qualifications provided");
                }
                for (const qualification of value) {
                    const validQualificationAnswer = validate(qualification, {"type": "ValidTutorQualification"}, extraLogging);
                    if (validQualificationAnswer.hasError) {
                        return returnError(validQualificationAnswer.errorMessage);
                    }
                }
            }
            catch (error) {
                return catchError("Tutor qualifications", error);
            }
        }

        else if (validationType == "ValidQualificationType") {
            try {
                if (value == null) {
                    return returnError("No qualification type provided");
                }
                if (typeof(value) != "string") {
                    return returnError("Qualification type not string");
                }
                let validResultList = validQualificationResults[value];
                if (validResultList == null) {
                    return returnError("Invalid qualification type");
                }
            }
            catch (error) {
                return catchError("Qualification type", error);
            }
        }

        else if (validationType == "ValidQualificationResult") {
            try {
                if (value == null) {
                    return returnError("No qualification result provided");
                }
                if (typeof(value) != "string") {
                    return returnError("Qualification result not string");
                }
                const qualificationType = validation.qualificationType;
                if (qualificationType == null) {
                    return returnError("No qualification type provided");
                }

                let validResultList = validQualificationResults[qualificationType];
                if (validResultList == null) {
                    return returnError("Invalid qualification type");
                }
                if (!validResultList.includes(value)) {
                    return returnError("Invalid qualification result");
                }
            }
            catch (error) {
                return catchError("Qualification result", error);
            }
        }

        else if (validationType == "ValidSubjectQualification") {
            try {
                if (value == null) {
                    return returnError("No qualification provided");
                }
                if (!isJSON(value)) {
                    return returnError("Qualification not object");
                }

                const qualificationType = value.qualificationType;
                if (qualificationType == null) {
                    return returnError("No qualification type given");
                }
                if (typeof(qualificationType) != "string") {
                    return returnError("Qualification type not string");
                }

                const subjectName = value.subjectName;
                if (subjectName == null) {
                    return returnError("No subject name given");
                }
                if (typeof(subjectName) != "string") {
                    return returnError("Subject name not string");
                }

                const validTutorSubjectList = [...validSubjectList, "Other"];
                let validTutorSubjectName = false;
                for (const validSubjectName of validTutorSubjectList) {
                    if (subjectName == validSubjectName) {
                        validTutorSubjectName = true;
                    }
                }
                if (validTutorSubjectName != true) {
                    return returnError("Invalid subject chosen: " + subjectName);
                }

                let validResultList = validQualificationResults[qualificationType];
                if (validResultList == null) {
                    return returnError("Invalid qualification type");
                }

                const qualificationResult = value.result;
                if (qualificationResult == null) {
                    return returnError("No qualification result given");
                }
                if (typeof(qualificationResult) != "string") {
                    return returnError("Qualification result not string");
                }

                let hasValidResult = false;
                for (const validResult of validResultList) {
                    if (qualificationResult == validResult) {
                        hasValidResult = true;
                    }
                }
                if (hasValidResult != true) {
                    return returnError("Invalid qualification result given");
                }
            }
            catch (error) {
                return catchError("Subject qualification", error);
            }
        }

        else if (validationType == "ValidTutorSubjects") {
            try {
                if (value == null) {
                    return returnError("No subjects provided");
                }
                if (!isJSON(value)) {
                    return returnError("Subjects not in valid format");
                }

                const subjectsArray = Object.entries(value);

                if (subjectsArray.length < 1) {
                    return returnError("No subjects provided");
                }
                for (const subject of subjectsArray) {
                    const validSubjectAnswer = validate(subject, {"type": "ValidTutorSubject"}, extraLogging);
                    if (validSubjectAnswer.hasError) {
                        return returnError(validSubjectAnswer.errorMessage);
                    }
                }
            }
            catch (error) {
                return catchError("Tutor subjects", error);
            }
        }

        else if (validationType == "ValidEducationType") {
            try {
                const educationType = value;
                if (educationType == null) {
                    return returnError("No education type given");
                }
                if (typeof(educationType) != "string") {
                    return returnError("Education type not string");
                }
                if (educationType == "") {
                    return returnError("Education type can't be blank string");
                }
                if (!validEducationTypes.includes(educationType)) {
                    return returnError("Invalid education type");
                }
            }
            catch (error) {
                return catchError("Education types", error);
            }
        }

        else if (validationType == "ValidCurrentEducationYear") {
            try {
                const currentEducationYear = value;
                if (currentEducationYear == null) {
                    return returnError("No current education year given");
                }
                if (typeof(currentEducationYear) != "string") {
                    return returnError("Current education year not string");
                }
                if (currentEducationYear == "") {
                    return returnError("Current education year can't be blank string");
                }

                const educationType = validation.educationType;
                const validEducationType = validate(educationType, {"type": "ValidEducationType"}, extraLogging);
                if (validEducationType.hasError) {
                    return returnError("Invalid education type given for current education year check: " + validEducationType.errorMessage);
                }
                
                let validEducationYears = [];
                if (educationType == "Secondary School" || educationType == "Sixth Form/College" || educationType == "Home Education") {
                    validEducationYears = ["Year 12", "Year 13"];
                }
                else if (educationType == "University") {
                    validEducationYears = ["1st Year", "2nd Year", "3rd Year", "4th Year", "5th Year", "6th Year"];
                }
                else {
                    for (let age = 16; age <= 80; age = age + 1) {
						validEducationYears.push("Age " + age);
					}
                }

                if (!validEducationYears.includes(currentEducationYear)) {
                    return returnError("Invalid current education year for education type");
                }
            }
            catch (error) {
                return catchError("Education types", error);
            }
        }

        // else if (validationType == "ValidTutorEducation") {
        //     try {
        //         const education = value;
        //         if (education == null) {
        //             return returnError("No education given");
        //         }
        //         if (!isJSON(education)) {
        //             return returnError("Education not in valid format");
        //         }

        //         const school = education.school;
        //         if (school != null && typeof(school) != "string") {
        //             return returnError("School not string");
        //         }
        //         if (school == "") {
        //             return returnError("School can't be blank string");
        //         }
        //         const university = education.university;
        //         if (university != null && typeof(university) != "string") {
        //             return returnError("University not string");
        //         }
        //         if (university == "") {
        //             return returnError("University can't be blank string");
        //         }

        //         if (school == null && university == null) {
        //             return returnError("No school or university given");
        //         }

        //         const schoolYear = education.schoolYear;
        //         if (schoolYear != null && typeof(schoolYear) != "string") {
        //             return returnError("School year not string");
        //         }
        //         const universityYear = education.universityYear;
        //         if (universityYear != null && typeof(universityYear) != "string") {
        //             return returnError("University year not string");
        //         }

        //         if (school != null && schoolYear == null && university == null) {
        //             return returnError("School year not given");
        //         }
        //         if (university != null && universityYear == null) {
        //             return returnError("University year not given");
        //         }
                
        //         if (schoolYear != null) {
        //             const validSchoolYear = validate(schoolYear, {"type": "ValidALevelYear"}, extraLogging);
        //             if (validSchoolYear.hasError) {
        //                 return returnError("Invalid school year");
        //             }
        //         }
        //         if (universityYear != null) {
        //             const validUniversityYear = validate(universityYear, {"type": "ValidUniversityYear"}, extraLogging);
        //             if (validUniversityYear.hasError) {
        //                 return returnError("Invalid university year");
        //             }
        //         }
        //     }
        //     catch (error) {
        //         return catchError("Tutor education", error);
        //     }
        // }

        else if (validationType == "ValidTutorEducation") {
            try {
                const education = value;
                if (education == null) {
                    return returnError("No education given");
                }
                if (!isJSON(education)) {
                    return returnError("Education not in valid format");
                }

                const type = education.type;
                if (type == null) {
                    return returnError("No education type given");
                }
                if (typeof(type) != "string") {
                    return returnError("Education type not string");
                }
                if (type == "") {
                    return returnError("Education type can't be blank string");
                }
                const validEducationType = validate(type, {"type": "ValidEducationType"}, extraLogging);
                if (validEducationType.hasError) {
                    return returnError("Error with education type: " + validEducationType.errorMessage);
                }
                const institution = education.institution;
                if (institution == null ) {
                    return returnError("Institution not given");
                }
                if (typeof(institution) != "string") {
                    return returnError("Institution not string");
                }
                if (institution == "") {
                    return returnError("Institution can't be blank string");
                }
                const graduated = education.graduated;
                if (graduated !== false && graduated == null) {
                    console.log("Graduated: " + graduated);
                    return returnError("No graduation status given");
                }
                if (typeof(graduated) != "boolean") {
                    return returnError("Graduation status not boolean");
                }
                if (graduated == true) {
                    if (education.currentYear != null) {
                        return returnError("Graduated education with current year");
                    }
                    const graduationYear = education.graduationYear;
                    if (graduationYear == null) {
                        return returnError("No graduation year given");
                    }
                    if (typeof(graduationYear) != "string") {
                        return returnError("Graduation year not string");
                    }
                    const validGraduationYear = validate(graduationYear, {type: "ValidYear"}, extraLogging);
                    if (validGraduationYear.hasError) {
                        return returnError("Error with graduation year: " + validGraduationYear.errorMessage);
                    }
                }
                else {
                    if (education.graduationYear != null) {
                        return returnError("Current education with graduation year");
                    }
                    const currentYear = education.currentYear;
                    if (currentYear == null) {
                        return returnError("No current year given");
                    }
                    if (typeof(currentYear) != "string") {
                        return returnError("Current year not string");
                    }
                    const validCurrentYear = validate(currentYear, {type: "ValidCurrentEducationYear", educationType: education.type}, extraLogging);
                    if (validCurrentYear.hasError) {
                        return returnError("Error with current year: " + validCurrentYear.errorMessage);
                    }
                }
            }
            catch (error) {
                return catchError("Tutor education", error);
            }
        }

        else if (validationType == "ValidTutorEducationList") {
            try {
                const educationList = value;
                if (educationList == null) {
                    return returnError("No education list given");
                }
                if (!Array.isArray(educationList)) {
                    return returnError("Education list not array");
                }
                if (educationList.length < 1 && validation.required == true) {
                    return returnError("No education given");
                }
                let foundCurrentEducation = false;
                const institutionYears = {}
                for (const education of educationList) {
                    const validEducation = validate(education, {"type": "ValidTutorEducation"}, extraLogging);
                    if (validEducation.hasError) {
                        return returnError(validEducation.errorMessage);
                    }
                    if (education.graduated == false) {
                        if (foundCurrentEducation == true) {
                            return returnError("Multiple current education entries");
                        }
                        foundCurrentEducation = true;
                    }

                    institutionYears[education.institution] = institutionYears[education.institution] || {};
                    if (institutionYears[education.institution][education.graduationYear || education.currentYear] == true) {
                        return returnError("Duplicate education entry");
                    }
                    else {
                        institutionYears[education.institution][education.graduationYear || education.currentYear] = true;
                    }
                }
            }
            catch (error) {
                return catchError("Tutor education", error);
            }
        }

        else if (validationType == "ValidLessonType") {
            try {
                const validLessonTypes = ["Online", "In-Person"];

                let validLessonTypeGiven = false;
                for (const validLessonType of validLessonTypes) {
                    if (value == validLessonType) {
                        validLessonTypeGiven = true;
                    }
                }
                if (validLessonTypeGiven != true) {
                    return returnError("Invalid lesson type");
                }
            }
            catch (error) {
                return catchError("Lesson type", error);
            }
        }

        else if (validationType == "ValidLessonTypeRequest") {
            try {
                const validLessonTypes = ["Online", "In-Person", "Either"];

                let validLessonTypeGiven = false;
                for (const validLessonType of validLessonTypes) {
                    if (value == validLessonType) {
                        validLessonTypeGiven = true;
                    }
                }
                if (validLessonTypeGiven != true) {
                    return returnError("Invalid lesson type");
                }
            }
            catch (error) {
                return catchError("Lesson type", error);
            }
        }

        else if (validationType == "ValidLessonTimeDay") {
            try {
                const validDayList = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                if (value.lesson == null) {
                    return returnError("No lesson given");
                }
                const lesson = value.lesson;
                
                if (lesson.day == null) {
                    return returnError("No day given");
                }
                const day = lesson.day;

                let validDayName = false;
                for (const validDay of validDayList) {
                    if (day == validDay) {
                        validDayName = true;
                    }
                }
                if (validDayName != true) {
                    return returnError("Invalid day");
                }
                
                if (lesson.startTime == null) {
                    return returnError("No start time given");
                }
                const startTime = lesson.startTime;
                if (lesson.endTime == null) {
                    return returnError("No end time given");
                }
                const endTime = lesson.endTime;

                const timeRange = {
                    startTime: startTime,
                    endTime: endTime
                }

                const timeRangeValidation = validate(timeRange, {"type": "ValidTimeRange"}, extraLogging);
                if (timeRangeValidation.hasError == true) {
                    return returnError(timeRangeValidation.errorMessage);
                }
                
                if (value.validTimeRanges == null) {
                    return returnError("No valid time ranges given");
                }
                const validTimeRanges = value.validTimeRanges;

                const validationObject = {
                    innerTimeRange: timeRange,
                    outerTimeRanges: validTimeRanges
                }
                const validTimeRange = validate(validationObject, {"type": "TimeRangeWithinTimeRanges", "end": value.end}, extraLogging);
                if (validTimeRange.hasError == true) {
                    return returnError(validTimeRange.errorMessage);
                }

                if (value.sessionLength == null) {
                    return returnError("No session length given");
                }
                const sessionLength = value.sessionLength;

                const startTimeHour = parseInt(timeRange.startTime.split(":")[0]);
                const startTimeMinute = parseInt(timeRange.startTime.split(":")[1]);
                const endTimeHour = parseInt(timeRange.endTime.split(":")[0]);
                const endTimeMinute = parseInt(timeRange.endTime.split(":")[1]);

                const startTimeInt = (startTimeHour * 60) + startTimeMinute
                const endTimeInt = (endTimeHour * 60) + endTimeMinute;

                const lessonDuration = endTimeInt - startTimeInt;

                if (lessonDuration != sessionLength) {
                    return returnError("Lesson duration not equal to session length");
                }
            }
            catch (error) {
                return catchError("Lesson day/time", error);
            }
        }

        else if (validationType == "ValidLessonTimeDays") {
            try {
                if (value.lessons == null) {
                    return returnError("No lessons given");
                }
                const lessons = value.lessons;
                if (lessons.length < 1) {
                    return returnError("No lessons given");
                }
                

                if (value.validAvailability == null) {
                    return returnError("No valid availability given");
                }
                const validAvailability = value.validAvailability;

                if (value.sessionLength == null) {
                    return returnError("No sesison length given");
                }
                const sessionLength = value.sessionLength;
                const startPointDate = value.startPointDate;
                const lessonDays = {
                    "Monday": false,
                    "Tuesday": false,
                    "Wednesday": false,
                    "Thursday": false,
                    "Friday": false,
                    "Saturday": false,
                    "Sunday": false,
                }
                for (const lesson of lessons) {
                    const lessonDay = lesson.day;
                    if (lessonDays[lessonDay] == true) {
                        return returnError("Only allowed one lesson per day");
                    }
                    else {
                        lessonDays[lessonDay] = true;
                    }
                    const validationObject = {
                        lesson: {...lesson},
                        validTimeRanges: validAvailability[lesson.day],
                        sessionLength: sessionLength,
                        startPointDate: startPointDate
                    }
                    const validLesson = validate(validationObject, {"type": "ValidLessonTimeDay"}, extraLogging);
                    if (validLesson.hasError == true) {
                        return returnError(validLesson.errorMessage);
                    }
                    const validStartDate = validate(validationObject, {"type": "ValidStartDate"}, extraLogging);
                    if (validStartDate.hasError == true) {
                        return returnError(validStartDate.errorMessage);
                    }
                }
                const validNoOverlaps = validate(lessons, {"type": "NoTimeOverlaps"}, extraLogging);
                if (validNoOverlaps.hasError == true) {
                    return returnError("Lessons cannot overlap");
                }
                const enforceNumSessions = value.enforceNumSessions;
                const numSessions = value.numSessions;
                if (enforceNumSessions == true) {
                    if (lessons.length < numSessions) {
                        if (lessons.length == numSessions - 1) {
                            return returnError("Please add another lesson");
                        }
                        else {
                            return returnError("Please add more lessons");
                        }
                    }
                    else if (lessons.length > numSessions) {
                        return returnError("Too many lessons added");
                    }
                }
            }
            catch (error) {
                return catchError("Lesson days", error);
            }
        }

        else if (validationType == "ValidYear") {
            try {
                if (typeof(value) != "number" && typeof(value) != "string") {
                    return returnError("Year not a string or number");
                }

                if (typeof(value) == "string") {
                    if (value.length != 4) {
                        return returnError("Year not 4 characters long");
                    }
                    value = parseInt(value);
                }

                const currentYear = new Date().getFullYear();
                if (value < 1970 || value > currentYear + 10) {
                    return returnError("Year not within valid range");
                }
            }
            catch (error) {
                return catchError("Year", error);
            }
        }

        else if (validationType == "ValidYearGroup") {
            try {
                const validYearGroupOptions = ["Year 1", "Year 2", "Year 3", "Year 4", "Year 5", "Year 6", "Year 7", "Year 8", "Year 9", "Year 10", "Year 11", "Year 12", "Year 13", "Reception"]

                let foundValidOption = false;
                for (const validOption of validYearGroupOptions) {
                    if (value == validOption) {
                        foundValidOption = true;
                    }
                }

                if (foundValidOption == false) {
                    return returnError("Invalid year group option");
                }
            }
            catch (error) {
                return catchError("Year group", error);
            }
        }

        else if (validationType == "ValidYearGroupStage") {
            try {
                const validYearGroupOptions = ["KS1", "KS2", "KS3", "KS4", "KS5", "University"]

                let foundValidOption = false;
                for (const validOption of validYearGroupOptions) {
                    if (value == validOption) {
                        foundValidOption = true;
                    }
                }

                if (foundValidOption == false) {
                    return returnError("Invalid year group stage");
                }
            }
            catch (error) {
                return catchError("Year group stage", error);
            }
        }

        else if (validationType == "ValidALevelYear") {
            try {
                const validYearGroupOptions = ["Year 12", "Year 13"]

                let foundValidOption = false;
                for (const validOption of validYearGroupOptions) {
                    if (value == validOption) {
                        foundValidOption = true;
                    }
                }

                if (foundValidOption == false) {
                    return returnError("Invalid A Level year group option");
                }
            }
            catch (error) {
                return catchError("A Level year group", error);
            }
        }

        else if (validationType == "ValidUniversityYear") {
            try {
                const validYearGroupOptions = ["1st Year", "2nd Year", "3rd Year", "4th Year", "5th+ Year"]

                let foundValidOption = false;
                for (const validOption of validYearGroupOptions) {
                    if (value == validOption) {
                        foundValidOption = true;
                    }
                }

                if (foundValidOption == false) {
                    return returnError("Invalid University year group option");
                }
            }
            catch (error) {
                return catchError("University year group", error);
            }
        }

        else if (validationType == "ValidStudentSelected") {
            try {
                const studentOptions = validation.options;
                if (studentOptions == null) {
                    return returnError("No options given");
                }

                let foundValidOption = false;
                for (const validOption of studentOptions) {
                    if (value.id == validOption.id) {
                        foundValidOption = true;
                    }
                }

                if (foundValidOption == false) {
                    return returnError("Invalid student selected");
                }
            }
            catch (error) {
                return catchError("Selected student", error);
            }
        }

        else if (validationType == "ValidTutorLevel") {
            try {
                const validLevelOptions = ["Currently an A-Level student", "Currently a university student", "Currently in a gap year after A-Levels"];

                let foundValidOption = false;
                for (const validOption of validLevelOptions) {
                    if (value == validOption) {
                        foundValidOption = true;
                    }
                }

                if (foundValidOption == false) {
                    return returnError("Invalid tutor level option");
                }
            }
            catch (error) {
                return catchError("Tutor level", error);
            }
        }

        else if (validationType == "ValidTutorLevelShort") {
            try {
                if (value == null) {
                    return returnError("No tutor level given");
                }
                if (typeof(value) != "string") {
                    return returnError("Tutor level not string");
                }

                const validLevelOptions = ["A-Level", "University", "Teacher"];
                
                let foundValidOption = false;
                for (const validOption of validLevelOptions) {
                    if (value == validOption) {
                        foundValidOption = true;
                    }
                }

                if (foundValidOption == false) {
                    return returnError("Invalid tutor level option");
                }
            }
            catch (error) {
                return catchError("Tutor level", error);
            }
        }

        else if (validationType == "ValidTutorLevelValue") {
            try {
                if (typeof(value) != "number") {
                    return returnError("Tutor level value not number");
                }

                if (value < 0) {
                    return returnError("Tutor level value should be positive");
                }
                if (value > 9) {
                    return returnError("Tutor level value should be less than or equal to 9");
                }
            }
            catch (error) {
                return catchError("Tutor level", error);
            }
        }

        else if (validationType == "ValidNumSessions") {
            try {
                if (typeof(value) != "number") {
                    return ({
                        hasError: true,
                        errorMessage: "Number of sessions should be a number: " + typeof(value)
                    });
                }
                if (value < 1) {
                    return ({
                        hasError: true,
                        errorMessage: "Number of sessions should be positive"
                    });
                }
                if (value > 7) {
                    return ({
                        hasError: true,
                        errorMessage: "Number of sessions should be less than or equal to 7"
                    });
                }
            }
            catch (error) {
                return ({
                    hasError: true,
                    errorMessage: "Number of sessions in invalid format (caused error)"
                });
            }
        }

        else if (validationType == "ValidAddress") {
            try {
                if (!isJSON(value)) {
                    return returnError("Address not in valid format");
                }

                let postcodeOnly = false;
                if (validation.postcodeOnly != null && typeof(validation.postcodeOnly) == "boolean") {
                    postcodeOnly = validation.postcodeOnly;
                }

                const postcodeValidation = validate(value.postcode, {type:"ValidPostcode"}, extraLogging);
                if (postcodeValidation.hasError != false) {
                    return returnError(postcodeValidation.errorMessage);
                }
                if (!postcodeOnly) {
                    const address1stLineValidation = validate(value.address1stLine, {type:"ValidAddress1stLine"}, extraLogging);
                    if (address1stLineValidation.hasError != false) {
                        return returnError(address1stLineValidation.errorMessage);
                    }
                }
                
                const addressObjectArray = Object.entries(value);
                if (addressObjectArray.length != 2) {
                    if (value.label != null && addressObjectArray.length != 3) {
                        if (value.geocode != null && addressObjectArray.length != 4) {
                            return returnError("Extra attributes in address");
                        }
                    }
                }

                const standardAddress = {
                    postcode: postcodeValidation.standardPostcode
                };
                if (!postcodeOnly) {
                    standardAddress.address1stLine = value.address1stLine;
                }

                const geocodeRequirement = validation.geocode;
                if (geocodeRequirement == true) {
                    if (value.geocode == null) {
                        if (validationEnd == "frontend") {
                            return returnError("No geocode given");
                        }
                        try {
                            value.geocode = geocodeAddress(standardAddress);
                        }
                        catch (error) {
                            let errorMessage = "Invalid address";
                            if (typeof(error) == "string" && error.includes("No address found")) {
                                if (postcodeOnly) {
                                    errorMessage = "Postcode not found"
                                }
                                else {
                                    errorMessage = "Address not found"
                                }
                            }
                            else {
                                throw error;
                            }
                            return returnError(errorMessage);
                        }
                    }
                    standardAddress.geocode = value.geocode;
                }

                return ({
                    hasError: false,
                    errorMessage: "",
                    standardAddress: standardAddress
                });
            }
            catch (error) {
                return catchError("Address", error);
            }
        }

        else if (validationType == "ValidAddressList") {
            try {
                if (!Array.isArray(value)) {
                    return returnError("Addresses should be array");
                }
            
                for (const address of value) {
                    const addressValidation = validate(address, {"type":"ValidAddress", "end": value.end}, extraLogging);
                    if (addressValidation.hasError != false) {
                        return returnError(addressValidation.errorMessage);
                    }
                }
            }
            catch (error) {
                return catchError("Address list", error);
            }
        }

        else if (validationType == "ValidDay") {
            try {
                const validDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

                if (value == null) {
                    return returnError("No day given");
                }
                if (typeof(value) != "string") {
                    return returnError("Day not string");
                }
                if (!validDays.includes(value)) {
                    return returnError("Invalid day chosen");
                }
            }
            catch (error) {
                return catchError("Day", error);
            }
        }

        else if (validationType == "ValidDays") {
            const days = Object.entries(value);
            try {
                for (const day of days) {
                    const dayValidation = validate(day[0], {type:"ValidDay", "end": value.end}, extraLogging);
                    if (dayValidation.hasError != false) {
                        return returnError(dayValidation.errorMessage);
                    }
                    if (day[1] != false && day[1] != true) {
                        return returnError("Day not boolean");
                    }
                }
            }
            catch (error) {
                return catchError("Days", error);
            }
        }

        else if (validationType == "ValidDate") {
            try {
                if (value == null) {
                    return returnError("No date given");
                }
                try {
                    const date = new Date(value)
                }
                catch {
                    return returnError("Invalid date");
                }

                if (validation.future == true) {
                    const currentDate = new Date();
                    if (new Date(value) < currentDate) {
                        return returnError("Date must be in the future");
                    }
                }
                else if (validation.past == true) {
                    const currentDate = new Date();
                    if (new Date(value) > currentDate) {
                        return returnError("Date must be in the past");
                    }
                }
            }
            catch (error) {
                return catchError("Date", error);
            }
        }

        else if (validationType == "ValidSessionLength") {
            try {
                if (value == null) {
                    return returnError("No session length given");
                }
                try {
                    const sessionLengthMins = getSessionLengthMins(value);
                    if (sessionLengthMins <= 0 || sessionLengthMins > 1440) {
                        return returnError("Invalid session length");
                    }
                }
                catch {
                    return returnError("Invalid session length");
                }
            }
            catch (error) {
                return catchError("Session length", error);
            }
        }

        else if (validationType == "ValidAddressObject") {
            try {
                if (!isJSON(value)) {
                    return returnError("Address not JSON object");
                }
                const address1stLine = value.address1stLine;
                const postcode = value.postcode;
                const label = value.label;
                
                if (label != null && label != "") {
                    const addressValidation = validate(address1stLine, {type:"ValidAddress1stLine"}, extraLogging);
                    if (addressValidation.hasError == true) {
                        return returnError(addressValidation.errorMessage);
                    }
                    const postcodeValidation = validate(postcode, {type:"ValidPostcode"}, extraLogging);
                    if (postcodeValidation.hasError == true) {
                        return returnError(postcodeValidation.errorMessage);
                    }
                }
            }
            catch (error) {
                return catchError("Address object", error);
            }
        }

        else if (validationType == "ValidLessonAmendment") {
            try {
                const amendment = {...value};
                const amendmentType = amendment.type;
                if (amendmentType == null) {
                    return returnError("No amendment type given");
                }

                let newAmendment = value.newAmendment || false;

                const validAmendmentTypes = ["cancelSingle", "addOneOff", "cancelAll", "changeType", "changeDayTime", "changeLocation", "setLastDate", "addScheduled"];
                let foundValid = false;
                for (const validAmendmentType of validAmendmentTypes) {
                    if (amendmentType == validAmendmentType) {
                        foundValid = true;
                        break;
                    }
                }
                if (foundValid == false) {
                    return returnError("Invalid amendment type");
                }

                const confirmed = amendment.confirmed;
                if (confirmed != true && confirmed != false) {
                    return returnError("Amendment confirmed not boolean");
                }

                const lessonProps = validation.lessonProps;

                if (amendmentType == "cancelSingle") {
                    try {
                        const date = amendment.date;
                        let validDate = validate(date, {type:"ValidDate"}, extraLogging);
                        if (validDate.hasError) {
                            throw validDate.errorMessage;
                        }
                        const startTime = amendment.startTime;
                        let validStartTime = validate(startTime, {type:"ValidTime"}, extraLogging);
                        if (validStartTime.hasError) {
                            throw validStartTime.errorMessage;
                        }
                        const endTime = amendment.endTime;
                        let validEndTime = validate(endTime, {type:"ValidTime"}, extraLogging);
                        if (validEndTime.hasError) {
                            throw validEndTime.errorMessage;
                        }
                    }
                    catch (error) {
                        return returnError("Invalid amendment to cancel single lesson: " + error);
                    }
                }

                else if (amendmentType == "addOneOff") {
                    try {
                        const date = amendment.date;
                        let validDate = validate(date, {type:"ValidDate"}, extraLogging);
                        if (validDate.hasError) {
                            throw validDate.errorMessage;
                        }
                        if (amendment.confirmed == true) {
                            const startTime = amendment.startTime;
                            let validStartTime = validate(startTime, {type:"ValidTime"}, extraLogging);
                            if (validStartTime.hasError) {
                                throw validStartTime.errorMessage;
                            }
                        }
                        else if (amendment.confirmed == false) {
                            const sessionLength = amendment.sessionLength;
                            let validSessionLength = validate(sessionLength, {type:"ValidSessionLength"}, extraLogging);
                            if (validSessionLength.hasError) {
                                throw validSessionLength.errorMessage;
                            }
                            const dayAvailability = amendment.dayAvailability;
                            let validDayAvailability = validate(dayAvailability, {type:"ValidDayAvailability"}, extraLogging);
                            if (validDayAvailability.hasError) {
                                throw validDayAvailability.errorMessage;
                            }
                        }
                        const lessonType = amendment.lessonType;
                        let validLessonType = validate(lessonType, {type:"ValidLessonType"}, extraLogging);
                        if (validLessonType.hasError) {
                            throw validLessonType.errorMessage;
                        }
                    }
                    catch (error) {
                        return returnError("Invalid amendment to add extra one-off lesson: " + error);
                    }
                }

                else if (amendmentType == "addScheduled") {
                    try {
                        const sessionLength = amendment.sessionLength;
                        let validSessionLength = validate(sessionLength, {type:"ValidSessionLength"}, extraLogging);
                        if (validSessionLength.hasError) {
                            throw validSessionLength.errorMessage;
                        }
                        const lessonType = amendment.lessonType;
                        let validLessonType = validate(lessonType, {type:"ValidLessonType"}, extraLogging);
                        if (validLessonType.hasError) {
                            throw validLessonType.errorMessage;
                        }
                        const weekAvailability = amendment.weekAvailability;
                        let validWeekAvailability = validate(weekAvailability, {type:"ValidWeekAvailability"}, extraLogging);
                        if (validWeekAvailability.hasError) {
                            throw validWeekAvailability.errorMessage;
                        }
                        if (lessonType == "In-Person") {
                            const address = amendment.address;
                            const validAddress = validate(address, {type:"ValidAddress"}, extraLogging);
                            if (validAddress.hasError) {
                                throw validAddress.errorMessage;
                            }
                        }
                    }
                    catch (error) {
                        return returnError("Invalid amendment to add extra scheduled lesson: " + error);
                    }
                }

                else if (amendmentType == "setLastDate") {
                    try {
                        const date = amendment.date;
                        let validDate = validate(date, {type:"ValidDate"}, extraLogging);
                        if (validDate.hasError) {
                            throw validDate.errorMessage;
                        }
                        const startTime = amendment.startTime;
                        let validStartTime = validate(startTime, {type:"ValidTime"}, extraLogging);
                        if (validStartTime.hasError) {
                            throw validStartTime.errorMessage;
                        }
                        const endTime = amendment.endTime;
                        let validEndTime = validate(endTime, {type:"ValidTime"}, extraLogging);
                        if (validEndTime.hasError) {
                            throw validEndTime.errorMessage;
                        }
                    }
                    catch (error) {
                        return returnError("Invalid amendment to set last lesson date: " + error);
                    }
                }

                else if (amendmentType == "changeType") {
                    try {
                        const lessonType = amendment.lessonType;
                        let validLessonType = validate(lessonType, {type:"ValidLessonType"}, extraLogging);
                        if (validLessonType.hasError) {
                            throw validLessonType.errorMessage;
                        }
                        const currentLessonType = lessonProps.lessonType;
                        if (lessonType == currentLessonType) {
                            throw "No change to lesson type";
                        }
                    }
                    catch (error) {
                        return returnError("Invalid amendment to change lesson type: " + error);
                    }
                }

                else if (amendmentType == "changeLocation") {
                    try {
                        const address = amendment.address;
                        let validAddress = validate(address, {type:"ValidAddress"}, extraLogging);
                        if (validAddress.hasError) {
                            throw validAddress.errorMessage;
                        }
                        const currentAddress = lessonProps.address || {};
                        const currentAddressObject = {
                            address1stLine: currentAddress.address1stLine,
                            postcode: currentAddress.postcode
                        };
                        const newAddressObject = {
                            address1stLine: address.address1stLine,
                            postcode: address.postcode
                        };
                        if (compareObjects(newAddressObject, currentAddressObject)) {
                            throw "No change to address";
                        }
                    }
                    catch (error) {
                        return returnError("Invalid amendment to change lesson location: " + error);
                    }
                }

                else if (amendmentType == "changeDayTime") {
                    try {
                        const weekAvailability = amendment.weekAvailability;
                        const lessonLength = getSessionLength(lessonProps.startTime, lessonProps.endTime);
                        let validWeekAvailability = validate(weekAvailability, {type:"ValidWeekAvailability", minSessionLength: lessonLength}, extraLogging);
                        if (validWeekAvailability.hasError) {
                            throw validWeekAvailability.errorMessage
                        }
                    }
                    catch (error) {
                        return returnError("Invalid amendment to change lesson day/time: " + error);
                    }
                }
            }
            catch (error) {
                return catchError("Lesson amendment", error);
            }

            
        }

        else if (validationType == "ValidLessonAmendments") {
            try {
                const checkCompatibility = validation.checkCompatibility || false;

                const lessonProps = validation.lessonProps;
                if (lessonProps == null) {
                    return returnError("No lesson props given");
                }
                
                if (!Array.isArray(value)) {
                    return returnError("Lesson amendments should be array");
                }

                let foundCancelAll = false;
                const cancelSingles = [];
                const addOneOffs = [];
                const changeLocations = [];
                const changeLessonTypes = [];
                const changeDayTimes = [];
                for (const amendment of value) {
                    if (amendment.confirmed == false) {
                        const validAmendment = validate(amendment, {type: "ValidLessonAmendment", lessonProps: lessonProps}, extraLogging);
                        if (validAmendment.hasError == true) {
                            return returnError(validAmendment.errorMessage);
                        }
                    }
                    if (amendment.type == "cancelAll") {
                        if (foundCancelAll == true) {
                            return returnError("Invalid lesson amendment: Cancel all lessons amendment already exists");
                        }
                        foundCancelAll = true;
                    }
                    else if (amendment.type == "cancelSingle") {
                        cancelSingles.push(amendment);
                    }
                    else if (amendment.type == "addOneOff") {
                        addOneOffs.push(amendment);
                    }
                    else if (amendment.type == "changeLocation") {
                        changeLocations.push(amendment);
                    }
                    else if (amendment.type == "changeType") {
                        changeLessonTypes.push(amendment);
                    }
                    else if (amendment.type == "changeDayTime") {
                        changeDayTimes.push(amendment);
                    }
                }
                
                if (checkCompatibility) {
                    for (let cancelSingleIndex = 0; cancelSingleIndex < cancelSingles.length - 1; cancelSingleIndex = cancelSingleIndex + 1) {
                        const amendment1 = cancelSingles[cancelSingleIndex];
                        for (let cancelSingleIndex2 = cancelSingleIndex + 1; cancelSingleIndex2 < cancelSingles.length; cancelSingleIndex2 = cancelSingleIndex2 + 1) {
                            const amendment2 = cancelSingles[cancelSingleIndex2];
                            if (amendment1.date == amendment2.date && amendment1.startTime == amendment2.startTime && amendment1.endTime == amendment2.endTime) {
                                return returnError("Invalid lesson amendment: Can't cancel the same lesson twice");
                            }
                        }
                    }
                    let foundUnconfirmedLocationChange = false;
                    for (const amendment of changeLocations) {
                        if (amendment.confirmed == false && amendment.rejectedBy == null) {
                            if (foundUnconfirmedLocationChange == true) {
                                return returnError("Invalid lesson amendment: Already outstanding location change");
                            }
                            else {
                                foundUnconfirmedLocationChange = true;
                            }
                        }
                    }
                    let foundUnconfirmedTypeChange = false;
                    for (const amendment of changeLessonTypes) {
                        if (amendment.confirmed == false && amendment.rejectedBy == null) {
                            if (foundUnconfirmedTypeChange == true) {
                                return returnError("Invalid lesson amendment: Already outstanding lesson type change");
                            }
                            else {
                                foundUnconfirmedTypeChange = true;
                            }
                        }
                    }
                    let foundUnconfirmedDayTimeChange = false;
                    for (const amendment of changeDayTimes) {
                        if (amendment.confirmed == false && amendment.rejectedBy == null) {
                            if (foundUnconfirmedDayTimeChange == true) {
                                return returnError("Invalid lesson amendment: Already outstanding lesson day/time change");
                            }
                            else {
                                foundUnconfirmedDayTimeChange = true;
                            }
                        }
                    }
                    // for (let addOneOffIndex = 0; addOneOffIndex < addOneOffs.length - 1; addOneOffIndex = addOneOffIndex + 1) {
                    //     const amendment1 = addOneOffs[addOneOffIndex];
                    //     for (let addOneOffIndex2 = addOneOffIndex + 1; addOneOffIndex2 < addOneOffs.length; addOneOffIndex2 = addOneOffIndex2 + 1) {
                    //         const amendment2 = addOneOffs[addOneOffIndex2];
                    //         if (compareObjects(amendment1, amendment2)) {
                    //             return ({
                    //                 hasError: true,
                    //                 errorMessage: "Invalid lesson amendment: Can't add the same lesson twice"
                    //             });
                    //         }
                    //     }
                    // }
                }
            }
            catch (error) {
                return catchError("Lesson amendments", error);
            }

        }

        else if (validationType == "AutoComplete" || validationType == "ValidAutoComplete") {
            try {
                const currentAnswer = value.id;
                const optionsObjectIDArray = value.options;
                
                if (currentAnswer == null) {
                    return returnError("No valid answer given");
                }

                if (optionsObjectIDArray == null) {
                    return ({
                        hasError: false,
                        errorMessage: ""
                    });
                }
                
                let foundMatch = false;
                for (const optionObject of optionsObjectIDArray) {
                    if (currentAnswer == optionObject && optionObject != null) {
                        foundMatch = true;
                    }
                }
                if (foundMatch == false) {
                    return returnError("Answer not in list");
                }
            }
            catch (error) {
                return catchError("AutoComplete", error);
            }
        }

        else if (validationType == "AllChecked") {
            try {
                const answers = Object.values(value);
                for (const answer of answers) {
                    if (answer != true) {
                        return returnError("All boxes must be ticked to continue");
                    }
                }
            }
            catch (error) {
                return catchError("All checked", error);
            }
        }

        else if (validationType == "Letters" || validationType == "ValidLetters") {
            try {
                for (let characterIndex = 0; characterIndex < value.length; characterIndex = characterIndex + 1) {
                    let charCode = value.charCodeAt(characterIndex);
                    let charType = getCharCodeType(charCode);
                    if (charType !== "letter") {
                        return returnError("Must only contain letters");
                    }
                }
            }
            catch (error) {
                return catchError("Letters", error);
            }
        }

        else if (validationType == "Name" || validationType == "ValidName") {
            try {
                if (value == null) {
                    return returnError("No name given");
                }
                //Check the name is a string
                if (typeof(value) != "string") {
                    return returnError("Invalid data type for name");
                }
                if (value.length == 0) {
                    return returnError("Name cannot be empty");
                }
                //Check the name is not too long
                if (value.length > 160) {
                    return returnError("Name must be less than 160 characters");
                }
                //Check that all charaters used are valid characters for a name
                for (let characterIndex = 0; characterIndex < value.length; characterIndex = characterIndex + 1) {
                    let charCode = value.charCodeAt(characterIndex);
                    let charType = getCharCodeType(charCode);
                    if (charType !== "letter" && charType !== "hyphen" && charType!== "space") {
                        return returnError("Must only contain letter, spaces and hyphens");
                    }
                }
                //Remove all whitespace from the name
                let validatedName = value;
                //Remove any spaces at the start of name
                while (validatedName[0] == " ") {
                    validatedName = validatedName.substring(1, validatedName.length);
                }
                //Remove any spaces at the end of name
                while (validatedName[validatedName.length - 1] == " ") {
                    validatedName = validatedName.substring(0, validatedName.length - 1);
                }
                //Remove any double spaces in name
                for (let charIndex = 0; charIndex < (validatedName.length - 1); charIndex = charIndex + 1) {
                    const char1 = validatedName[charIndex];
                    const char2 = validatedName[charIndex + 1];
                    //If 2 consecutive characters are both a space, remove one space
                    if (char1 == " " && char2 == " ") {
                        validatedName = validatedName.substring(0, charIndex) + validatedName.substring(charIndex + 1, validatedName.length);
                        charIndex = charIndex - 1;
                    }
                }
                return ({
                    hasError: false,
                    errorMessage: "",
                    standardName: validatedName
                });
            }
            catch (error) {
                return catchError("Name", error);
            }
        }

        else if (validationType == "ValidParagraph") {
            try {
                if (validation.required == true && (value == null || value == "")) {
                    return returnError("No paragraph given");
                }

                //Check the name is a string
                if (value != null && typeof(value) != "string") {
                    return returnError("Invalid data type for paragraph: " + typeof(value));
                }

                let maxLength = 250;
                if (validation.maxLength != null && typeof(validation.maxLength) == "number") {
                    maxLength = validation.maxLength;
                }

                //Check the name is not too long
                if (value == null || value.length > maxLength) {
                    return returnError("Paragraph must be less than " + maxLength + " characters");
                }
            }
            catch (error) {
                return catchError("Paragraph", error);
            }
        }

        else if (validationType == "NumbersString") {
            for (let characterIndex = 0; characterIndex < value.length; characterIndex = characterIndex + 1) {
                let charCode = value.charCodeAt(characterIndex);
                let charType = getCharCodeType(charCode);
                if (charType !== "number") {
                    return returnError("Must only contain numbers");
                }
            }
        }

        else if (validationType == "String") {
            try {
                if (value == null) {
                    return returnError("No string given");
                }
                if (typeof(value) != "string") {
                    return returnError("Invalid data type for string: " + typeof(value));
                }
                if (validation.minLength != null && typeof(validation.minLength) == "number") {
                    if (value.length < validation.minLength) {
                        return returnError("String must be at least " + validation.minLength + " characters");
                    }
                }
                if (validation.maxLength != null && typeof(validation.maxLength) == "number") {
                    if (value.length > validation.maxLength) {
                        return returnError("String must be less than " + validation.maxLength + " characters");
                    }
                }
                if (validation.length != null && typeof(validation.length) == "number") {
                    if (value.length != validation.length) {
                        return returnError("String must be " + validation.length + " characters");
                    }
                }
            }
            catch (error) {
                return catchError("String", error);
            }
        }

        else if (validationType == "ValidIDs") {
            try {
                if (value == null) {
                    return returnError("No IDs given");
                }
                if (!Array.isArray(value)) {
                    return returnError("IDs not an array");
                }

                for (const ID of value) {
                    const idValidation = validate(ID, {type:"ValidID"}, extraLogging);
                    if (idValidation.hasError != false) {
                        return returnError(idValidation.errorMessage);
                    }
                }
            }
            catch (error) {
                return catchError("IDs", error);
            }
        }

        else if (validationType == "ValidID") {
            try {
                if (value == null) {
                    return returnError("No ID given");
                }
                if (typeof(value) != "string") {
                    return returnError("ID not string");
                }
                if (value.length != 36 && value.length != 28) {
                    return returnError("Invalid ID length");
                }

                if (value.substring(0, 7) == "google_") {
                    if (value.length != 28) {
                        return returnError("Invalid Google ID length");
                    }
                    const googleID = value.substring(7, value.length);
                    const validGoogleID = validate(googleID, {type:"NumbersString"}, extraLogging);
                    if (validGoogleID.hasError == true) {
                        return returnError("Invalid Google ID format");
                    }
                }
                else if (value.length == 36) {
                    const idSections = value.split("-");
                    if (idSections.length != 5) {
                        return returnError("Invalid ID format");
                    }
                    for (let sectionIndex = 0; sectionIndex < idSections.length; sectionIndex = sectionIndex + 1) {
                        const section = idSections[sectionIndex];
                        if (sectionIndex == 0 && section.length != 8) {
                            return returnError("Invalid ID format");
                        }
                        else if ((sectionIndex == 1 || sectionIndex == 2 || sectionIndex == 3) && section.length != 4) {
                            return returnError("Invalid ID format");

                        }
                        else if (sectionIndex == 4 && section.length != 12) {
                            return returnError("Invalid ID format");
                        }
                    }
                }
            }
            catch (error) {
                return catchError("ID", error);
            }
        }

        else if (validationType == "Number" || validationType == "ValidNumber") {
            try {
                if (value == null) {
                    return returnError("No number given");
                }
                if (typeof(value) != "number" && (validation.allowString == false || typeof(value) != "string")) {
                    return returnError("Must be a number");
                }
                const standardNumber = Number(value);

                if (validation.greaterThan != null) {
                    const greaterThan = validation.greaterThan;
                    if (typeof(greaterThan) != "number") {
                        return returnError("greaterThan isn't number");
                    }
                    if (standardNumber <= greaterThan) {
                        return returnError("Must be greater than " + greaterThan);
                    }
                }
                if (validation.lessThan != null) {
                    const lessThan = validation.lessThan;
                    if (typeof(lessThan) != "number") {
                        return returnError("lessThan isn't number");
                    }
                    if (standardNumber >= lessThan) {
                        return returnError("Must be less than " + lessThan);
                    }
                }
                return {
                    hasError: false,
                    errorMessage: "",
                    standardNumber: standardNumber
                };
            }
            catch (error) {
                return catchError("Number", error);
            }
        }

        else if (validationType == "Integer" || validationType == "ValidInteger") {
            const numberValidation = validate(value, {...validation, type:"Number"});
            if (numberValidation.hasError == true) {
                return (numberValidation);
            }
            const standardNumber = numberValidation.standardNumber;
            if (Number.isInteger(standardNumber) == false) {
                return returnError("Must be an integer");
            }
        }

        else if (validationType == "JSON" || validationType == "ValidJSON") {
            let isInvalidJSON = false;
            try {
                JSON.parse(value);
            } catch {
                isInvalidJSON = true;
            }
            
            if (isInvalidJSON == true) {
                return returnError("Invalid JSON format");
            }
            if (!isJSON(value)) {
                return returnError("Invalid JSON format");
            }
        }

        else if (validationType == "Boolean" || validationType == "ValidBoolean") {
            if (value != true && value != false) {
                return returnError("Value must be a boolean");
            }
        }

        else if (validationType == "URL" || validationType == "ValidURL") {
            let isInvalidUrl = false;

            try {
                new URL(value);
            } 
            catch {
                isInvalidUrl = true;
            }

            if (isInvalidUrl == true) {
                return returnError("Invalid URL format");
            }
        }

        else if (validationType == "ValidLessonAdminOverrideValidations") {
            const overrideArray = Object.entries(value);

            const passiveOverrides = [
                "Don't notify parent/student",
                "Don't notify tutor",
                "Don't delete lesson request"
            ];

            for (const override of overrideArray) {
                const overrideName = override[0];
                const overrideValue = override[1];
                let isPassiveOverride = false;
                for (const passiveOverride of passiveOverrides) {
                    if (overrideName == passiveOverride) {
                        isPassiveOverride = true;
                    }
                }
                if (isPassiveOverride == false) {
                    if (overrideValue == false) {
                        return returnError("Warnings must be overriden to submit");
                    }
                }
            }
        }
        
        else {
            console.log("Validation type: '" + validationType + "' not found");
            console.log("Validation value: " + JSON.stringify(value));
            return ({
                hasError: false,
                errorMessage: "Validation type: '" + validationType + "' not found"
            });
        }

        //If not returned error, return no error
        return ({
            hasError: false,
            errorMessage: ""
        });
    }
    catch (error) {
        return ({
            hasError: true,
            errorMessage: "Caused error in validation script: " + error
        });
    }
}

function getCharCodeType(charCode) {
    try {
        if (typeof(charCode) != "number") {
            return null;
        }

        let currentCharType = null;
        if ((charCode >= 97 && charCode <= 122) || (charCode >= 65 && charCode <= 90)) {
            currentCharType = "letter";
        }
        else if (charCode >= 48 && charCode <= 57) {
            currentCharType = "number";
        }
        else if (charCode === 95) {
            currentCharType = "underscore";
        }
        else if (charCode === 45) {
            currentCharType = "hyphen";
        }
        else if (charCode === 46) {
            currentCharType = "period";
        }
        else if (charCode === 32) {
            currentCharType = "space";
        }
        else if (charCode === 43) {
            currentCharType = "plus";
        }
        else if (charCode === 35) {
            currentCharType = "hashtag";
        }
        else if (charCode === 47) {
            currentCharType = "forwardslash";
        }
        else if (charCode === 44) {
            currentCharType = "comma";
        }
        else if (charCode === 39) {
            currentCharType = "apostrophe";
        }
        else if (charCode >= 32 && charCode <= 126) {
            currentCharType = "otherASCIISymbol";
        }
        else {
            currentCharType = "notStandardASCIIChar"
        }
        return currentCharType;
    }
    catch (error) {
        throw "Error getting ASCII code of char: " + error;
    }
}

const geocodeAddress = function () {
    throw "Can't geocode address in frontend";
}