import { Flex, Text } from '@aws-amplify/ui-react';
import React, { useState } from 'react';
import { AppNavButton, FormTemplate } from '../../custom-ui-components';
import { Navigate, useLocation } from 'react-router-dom';
import { banks, compareObjects } from '../../App';
import { LoadingSpinnerPage } from '../LoadingPage';
import { ErrorLoadingPage } from '../ErrorLoadingPage';

const TutorChangePayment = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const userModel = props.userModel;
    const APIFunctions = props.APIFunctions;

    const location = useLocation();
    const state = location.state;

    const [redirect, setRedirect] = useState(null);

    if (userModel == null) {
        return <LoadingSpinnerPage />
    }
    if (userModel == "error") {
        return <ErrorLoadingPage />
    }

    const existingBankingDetails = userModel.bankDetails || {};

    let titleText = <Text {...titleTextProps}>Change Bank Details for Payouts</Text> 
    if (existingBankingDetails.accountNumber == null) {
        titleText = <Text {...titleTextProps}>Add Bank Details for Payouts</Text> 
    }
    
    let defaultName = existingBankingDetails.name;
    if (defaultName == null) {
        defaultName = userModel.firstNames + " " + userModel.lastName;
    }
    const questions = [];
    questions.push({
        id: "name", 
        label: "Name:",
        type: "text", 
        required: true, 
        validations: [{type: "Name"}], 
        defaultValue: defaultName, 
    });

    const friendlyBankNames = Object.keys(banks);

    let defaultBankName = null;
    const bankArray = Object.entries(banks);
    for (const bank of bankArray) {
        if (bank[1] == existingBankingDetails.bankName) {
            defaultBankName = bank[0];
            break;
        }
    }

    questions.push({
        id: "bankName", 
        label: "Bank:",
        type: "autocomplete", 
        required: true,
        options: friendlyBankNames,
        defaultValue: defaultBankName,
    });

    questions.push({
        id: "accountNumber", 
        label: "Account Number:",
        type: "text", 
        required: true, 
        validations: [{type: "ValidAccountNumber"}], 
        defaultValue: existingBankingDetails.accountNumber, 
        extraProps: {
            noLetters: true
        }
    });

    questions.push({
        id: "sortCode", 
        label: "Sort Code:",
        type: "text", 
        required: true, 
        validations: [{type: "ValidSortCode"}], 
        defaultValue: existingBankingDetails.sortCode,
        extraProps: {
            noLetters: true
        }
    });

    const defaultAddress = existingBankingDetails.address || {};
    questions.push({
        id: "address1stLine",
        description: "(Address associated with bank account)",
        label: "1st Line of Address:",
        type: "text", 
        required: true, 
        validations: [{type: "ValidAddress1stLine"}], 
        defaultValue: defaultAddress.address1stLine,
    });

    questions.push({
        id: "postcode", 
        label: "Postcode:",
        description: "(Postcode associated with bank account)",
        type: "text", 
        required: true, 
        validations: [{type: "ValidPostcode"}], 
        defaultValue: defaultAddress.postcode,
    });

    
    const exitForm = function () {
        let redirectLink = "/Tutor";
        if (state != null && state.back != null) {
            redirectLink = state.back;
        }
        setRedirect(<Navigate to={redirectLink} />);
    };

    const submitAnswers = async function(returnedAnswers) {
        try {
            if (returnedAnswers.bankName == null || returnedAnswers.bankName.label == null) {
                throw "No bank given";
            }
            const actualBankName = banks[returnedAnswers.bankName.label];
            
            const address = {
                address1stLine: returnedAnswers.address1stLine,
                postcode: returnedAnswers.postcode
            };

            const newBankingDetails = {
                name: returnedAnswers.name,
                firstName: userModel.firstNames,
                lastName: userModel.lastName,
                accountNumber: returnedAnswers.accountNumber,
                sortCode: returnedAnswers.sortCode,
                bankName: actualBankName,
                address: address
            };

            const anyChange = !compareObjects(newBankingDetails, existingBankingDetails);
            if (anyChange == false) {
                throw "No changes made";
            }
            const newTutorModel = await APIFunctions.updateTutorModel({bankDetails: newBankingDetails});
            console.log(newTutorModel);
        }
        catch (error) {
            if (error == "No changes made") {
                throw "No change to bank details";
            }
            else if (error == "Error updating tutor data model: Invalid postcode") {
                throw {
                    questionErrors: [{
                        questionID: "postcode",
                        errorMessage: "Invalid postcode",
                        useErrorMessage: true
                    }]
                }
            }
            else {
                throw error;
            }
        }
    };

    const availabilityForm = <FormTemplate {...props} submitAnswers={submitAnswers} questions={questions} okButtonFunction={exitForm} submitPopUpText={"Successfully updated availability"} />

    const backButton = <AppNavButton text={"Back"} onClickFunction={exitForm} width={props.width} />

    const payInfo = <Text {...standardTextProps}>You will be paid every Sunday for the credit you have in your account (credit is added to your account 2 days after teaching a lesson).</Text>

    return (
        <div className='TutorChangeAvailabilityPage'>
            <Flex direction={"column"} alignItems={"center"}>
                {redirect}
                {titleText}
                {/* {descriptionText} */}
                <Flex>
                    {backButton}
                </Flex>
                {payInfo}
                <Flex maxWidth={"500px"}>
                    {availabilityForm}
                </Flex>
            </Flex>
        </div>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default TutorChangePayment;