import React from 'react';
import { Flex, Button, Text } from "@aws-amplify/ui-react";
import FormTemplate from './form-components/FormTemplate';

const EmailCodePopUp = function(props) {
  const text1 = <Text color="#08007d" fontWeight={"medium"} fontSize={"2vh"}>
    You will have received a new email verification code in the inbox of the new email address.
  </Text>
  const text2 = <Text color="#08007d" fontWeight={"medium"} fontSize={"2vh"}>
    Please enter the code below to verify your new email.
  </Text>
  const text3 = <Text color="#08007d" fontWeight={"medium"} fontSize={"2vh"}>
    If you fail to verify the new email, you account will remain using your old email.
  </Text>

  const newEmail = props.newEmail;

  const submitForm = async function (answers) {
    const verificationCode = answers.verificationCode;
      await props.APIFunctions.updateUserAttributes({
        verificationCode: verificationCode, 
        email: newEmail
      });
  }

  const questions = [];
  questions.push({
    id: "verificationCode",
    label: "Verification Code:",
    type: "text",
    validations: [{"type": "Numbers"}]
  })

  const closeForm = function () {
    props.setPopUpVisibility(null);
  }

  const emailCodeForm = <FormTemplate {...props} questions={questions} submitAnswers={submitForm} okButtonFunction={closeForm} submitPopUpText={"Successfully verified new email"}/>

  const xButton = <Flex
    key={"XButton"}
    direction={"row"}
    style={{
      cursor:"pointer",
      background:"#ff0202"
    }}
    width="3vh"
    height="3vh"
    textAlign="center"
    borderRadius="50%"
    fontSize="2vh"
    justifyContent={"center"}
    onClick={() => {
      props.setPopUpVisibility(null)
    }}
    >X</Flex>
  
  const optionalButtons = [];
  optionalButtons.push(xButton);
  
  return (
    <Flex 
      direction={"column"}
      position={"fixed"}
      maxWidth="90vw"
      maxHeight="80vh"
      background="#00000050"
      minWidth="250px"
      style={{
        borderRadius:"1vh",
        background:"#cbefff",
        border:"0.3vh solid #999",
      }}
      top={"10vh"}
    >
      <Flex
        position={"absolute"}
        justifyContent={"flex-end"}
        right="-1.5vh"
        top="-1.5vh"
      >
        {optionalButtons}
      </Flex>
      <Flex direction={"column"} alignItems={"center"} gap={"3vh"} textAlign="center" marginTop={"3vh"} marginLeft={"3vw"} marginRight={"3vw"}>
        {text1}
        {text2}
        {text3}
        {emailCodeForm}
      </Flex>
    </Flex>
  );
}

export default EmailCodePopUp;