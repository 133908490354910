import * as React from "react";
import { Text, Flex, Button, Badge, Grid, Icon, SelectField } from "@aws-amplify/ui-react";
import { useState, useEffect, useCallback } from 'react';
import { validateField } from "./FormValidation";
import { compareObjects, educationTypeQualifications, examBoards, isJSON, qualificationResults, qualificationTypeOrder, subjects, throwError } from "../../App";
import FieldTemplate from "../FieldTemplate";
import { RiArrowGoBackFill } from "react-icons/ri";
import { MdAdd } from "react-icons/md";
import PopUpTemplate from "./PopUpTemplate";
import FormTemplate from "./FormTemplate";

export const TutorSubjectsTemplate = function(props) {
	try {
		//Set question variables from passed properties
		const questionID = props.id;
		const defaultValue = props.defaultValue;
		const questionValidations = props.validations || [];
		const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;
		const qualificationAnswerValidations = [{"type":"ValidTutorQualification"}]

		let qualificationsValidationExists = false;
		for (let validation of questionValidations) {
			if (validation.type === "ValidTutorQualifications") {
				qualificationsValidationExists = true;
			}
		}
		if (qualificationsValidationExists === false) {
			questionValidations.push ({"type":"ValidTutorQualifications"});
		}

		const initialQualificationTypes = Object.keys(qualificationResults);

		//Add an extra validation that will be in all day availability questions
		//This will ensure that the time range(s) given is always valid i.e. start time is before end time

		//Define a blank qualification for resetting to
		const blankQualification = {
			subject: null,
			qualificationType: null,
			result: null,
			examBoard: null,
			educationID: null
		}

		//Set state variables used to store the current state and any errors
		const [tutorEducation, setTutorEducation] = useState([]);
		const [qualificationAnswers, setQualificationAnswers] = useState([]);
		const [existingQualificationAnswers, setExistingQualificationAnswers] = useState([]);
		const [deletedQualificationAnswers, setDeletedQualificationAnswers] = useState([]);
		const [newQualificationAnswers, setNewQualificationAnswers] = useState([]);
		const [updatedQualificationAnswers, setUpdatedQualificationAnswers] = useState([]);
		const [currentQualificationAnswer, setCurrentQualificationAnswer] = useState(blankQualification);
		const [resultOptions, setResultOptions] = useState([]);
		const [educationOptions, setEducationOptions] = useState([]);
		const [cancelButton, setCancelButton] = useState(null);
		const [addButton, setAddButton] = useState(null);
		const [addNewItemButton, setAddNewItemButton] = useState(null);
		const [badgeUIs, setBadgeUIs] = useState([]);
		const [errors, setErrors] = useState({questionHasError:false, questionError:"", currentQualificationError:true});
		const [qualificationTypes, setQualificationTypes] = useState(initialQualificationTypes);
		const [buttonOrSubject, setButtonOrSubject] = useState("button");
		const [subjectNameUI, setSubjectNameUI] = useState(null);
		const [qualificationTypeUI, setQualificationTypeUI] = useState(null);
		const [resultUI, setResultUI] = useState(null);
		const [relevantEducationUI, setRelevantEducationUI] = useState(null);
		const [examBoardUI, setExamBoardUI] = useState(null);
		const [showUpdateEducationUI, setShowUpdateEducationUI] = useState(false);
		const [updateEducationUI, setUpdateEducationUI] = useState(null);

		const sortQualifications = useCallback(function (qualifications) {
			const sortedQualifications = qualifications.sort((a, b) => {
				const aType = a.qualificationType;
				const aTypeIndex = qualificationTypeOrder.indexOf(aType);
				const bType = b.qualificationType;
				const bTypeIndex = qualificationTypeOrder.indexOf(bType);
				if (aTypeIndex < bTypeIndex) {
					return -1;
				}
				if (aTypeIndex > bTypeIndex) {
					return 1;
				}
				const aSubject = a.subject;
				const bSubject = b.subject;
				if (aSubject < bSubject) {
					return -1;
				}
				if (aSubject > bSubject) {
					return 1;
				}
				const aResult = a.result;
				const aResultIndex = qualificationResults[aType].indexOf(aResult);
				const bResult = b.result;
				const bResultIndex = qualificationResults[bType].indexOf(bResult);
				if (aResultIndex < bResultIndex) {
					return -1;
				}
				if (aResultIndex > bResultIndex) {
					return 1;
				}
				return 0;
			});
			return sortedQualifications;
		}, []);

		const submitEducationUpdate = useCallback(async function(returnedAnswers) {
			const newEducation = returnedAnswers.education;
			const anyChange = !compareObjects(newEducation.answers, tutorEducation || []);
			if (anyChange == false) {
				throwError("No change to education", null, 204);
			}
			await props.APIFunctions.updateTutorModel({
				updateEducation: newEducation
			});
		}, [tutorEducation]);

		//Set the default value of the qualification answers
		useEffect(() => {
			if (defaultValue == null) {
				setExistingQualificationAnswers([]);
				return;
			}
			setNewQualificationAnswers([]);
			setUpdatedQualificationAnswers([]);
			setDeletedQualificationAnswers([]);

			const validationResult = validateField(defaultValue, questionValidations);
			const questionHasError = validationResult.hasError;
			if (questionHasError == true) {
				console.log("Error with given default tutor qualifications: " + validationResult.errorMessage);
				setExistingQualificationAnswers([]);
			}
			else {
				const newExistingQualifications = [];
				for (const qualification of defaultValue) {
					const newQualification = {
						...qualification,
						unedited: qualification
					}
					newExistingQualifications.push(newQualification);
				}
				setExistingQualificationAnswers(sortQualifications(newExistingQualifications));
			}
		}, [defaultValue]);

		useEffect(() => {
			if (Array.isArray(props.tutorEducation)) {
				setTutorEducation(props.tutorEducation);
			}
			else {
				setTutorEducation([]);
			}
		}, [props.tutorEducation]);

		//Validate the qualification answers
		useEffect(() => {
			const answersToValidate = [...existingQualificationAnswers, ...newQualificationAnswers, ...updatedQualificationAnswers];
			setQualificationAnswers(answersToValidate);
			const validationResult = validateField(answersToValidate, questionValidations);
			const questionHasError = validationResult.hasError;
			const errorMessage = validationResult.errorMessage;
			setErrors((prev) => {
				return {
					...prev,
					questionHasError: questionHasError, 
					questionError: errorMessage
				};
			});

			const returnAnswers = {
				add: newQualificationAnswers,
				update: updatedQualificationAnswers,
				delete: deletedQualificationAnswers,
				answers: answersToValidate.map((answer) => {
					const newAnswer = {...answer};
					delete newAnswer.unedited;
					return newAnswer;
				})
			}

			props.onChange(returnAnswers, validationResult);
		}, [existingQualificationAnswers, deletedQualificationAnswers, newQualificationAnswers, updatedQualificationAnswers]);

		//Validate the current qualification answer
		useEffect(() => {
			const currentValidationResult = validateField(currentQualificationAnswer, qualificationAnswerValidations);
			const currentQuestionHasError = currentValidationResult.hasError;
			setErrors((prev) => {
				return {
					...prev,
					currentQualificationError: currentQuestionHasError
				};
			});
		}, [currentQualificationAnswer]);

		//Define the add new item button
		useEffect(() => {
			let newItemButtonText = "Add qualification";
			if (Array.isArray(qualificationAnswers) && qualificationAnswers.length > 0) {
				newItemButtonText = "Add another qualification";
			}
			setAddNewItemButton(<Button
				//When clicked, all this does is change the mode to the adding new time range item mode
				disabled={answersSuccessfullySubmitted}
				onClick={() => {
					setButtonOrSubject("subject");
				}}
			>
				{newItemButtonText}
			</Button>);
		}, [qualificationAnswers, answersSuccessfullySubmitted]);

		const checkQualificationEdited = useCallback(function (qualification) {
			if (qualification == null || !isJSON(qualification)) {
				return null;
			}
			if (qualification.unedited == null) {
				return null;
			}
			if (qualification.modelTypeID == null) {
				return null;
			}

			const testQualification = {...qualification};
			delete testQualification.unedited;
			return !compareObjects(testQualification, qualification.unedited);
		}, []);

		//Define the cancel item button
		useEffect(() => {
			setCancelButton(<Button
				isDisabled={answersSuccessfullySubmitted}
				onClick={() => {
					if (answersSuccessfullySubmitted == true) {
						return;
					}

					const editStatus = checkQualificationEdited(currentQualificationAnswer);
					//Edit status is true it it has been edited from an existing qualification
					if (editStatus === true) {
						setExistingQualificationAnswers((prev) => {
							const newQualificationAnswer = {...currentQualificationAnswer.unedited};
							newQualificationAnswer.unedited = currentQualificationAnswer.unedited;
							return sortQualifications([...prev, newQualificationAnswer]);
						});
					}
					//Edit status is false if it is an existing qualification but not edited
					else if (editStatus === false) {
						setExistingQualificationAnswers((prev) => {
							return sortQualifications([...prev, currentQualificationAnswer]);
						});
					}
					else if (editStatus === null && currentQualificationAnswer.unedited != null) {
						setNewQualificationAnswers((prev) => {
							const newQualificationAnswer = {...currentQualificationAnswer.unedited};
							newQualificationAnswer.unedited = currentQualificationAnswer.unedited;
							return sortQualifications([...prev, newQualificationAnswer]);
						});
					}

					setButtonOrSubject("button");
					setCurrentQualificationAnswer(blankQualification);
					setResultOptions([]);
					
					const validationResult = validateField(existingQualificationAnswers, questionValidations);

					setErrors((prev) => {
						return {
							...prev, 
							currentQualificationError: true,
							questionError: validationResult.errorMessage
						};
					});
				}}
			>
				Cancel
			</Button>);
		}, [answersSuccessfullySubmitted, existingQualificationAnswers, currentQualificationAnswer]);

		//Now create the array of badges with the correct properties so that to appear in the correct location
		//The row and column they're placed in depends on their index in the array
		useEffect(() => {
			const badgeArray = [];
			let badgeIndex = 0;
			const qualificationTitleProps = {
				fontSize: "16px",
				fontWeight: "bold",
				color: "#000000",
				marginTop: "15px",
				marginBottom: "-5px"
			}
			const getQualificationString = function (qualificationAnswer) {
				let answerString = qualificationAnswer.qualificationType + ": " + qualificationAnswer.subject + " - Result: " + qualificationAnswer.result;
				if (qualificationAnswer.examBoard != null) {
					answerString = qualificationAnswer.qualificationType + " (" + qualificationAnswer.examBoard + "): " + qualificationAnswer.subject + " - Result: " + qualificationAnswer.result;
				}
				return answerString;
			}
			const getQualificationKey = function (qualificationAnswer) {
				let answerKey = qualificationAnswer.qualificationType + "-" + qualificationAnswer.subject + "-" + qualificationAnswer.result;
				if (qualificationAnswer.modelTypeID != null) {
					answerKey = qualificationAnswer.modelTypeID;
				}
				return answerKey;
			}

			const QualificationBadge = function (props) {
				const qualificationAnswer = props.qualificationAnswer;
				const answerString = getQualificationString(qualificationAnswer);
				let clickFunction = props.clickFunction;
				let deleteFunction = props.deleteFunction;
				let restoreFunction = props.restoreFunction;
				if (answersSuccessfullySubmitted == true) {
					clickFunction = null;
					deleteFunction = null;
					restoreFunction = null;
				}

				let deleteButton = null;
				if (deleteFunction != null) {
					deleteButton = <Icon
						//This is a cross icon that when clicked removes the time range answer
						style={{
							cursor: "pointer",
							paddingLeft: 3,
							width: 20,
							height: 20,
						}}
						viewBox={{ width: 20, height: 20 }}
						paths={[
							{
								d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
								stroke: "white",
							},
						]}
						ariaLabel="button"
						onClick={deleteFunction}
					/>
				}

				let restoreButton = null;
				if (restoreFunction != null) {
					restoreButton = <Icon
					key={"recoverIcon"}
					width={"20px"}
					height={"20px"}
					style={{
						cursor: "pointer",
						paddingLeft: 3,
						width: 20,
						height: 20,
					}}
					as={RiArrowGoBackFill}
					onClick={restoreFunction}
				/>
				}

				let noButtonsIcon = null;
				if (deleteButton == null && restoreButton == null) {
					noButtonsIcon = <Icon 
						width={"20px"}
						height={"20px"}
					/>
				}
				
				return <Badge
					style={{
						alignItems: "center",
						marginRight: 10,
						marginTop: 10,
						backgroundColor: props.backgroundColor || props.backgroundColour || "#4c96e0",
						color:"#fff",
						cursor: clickFunction != null && "pointer"
					}}
					size="small"
					columnStart={(badgeIndex % 2) + 1}
					rowStart={Math.floor(badgeIndex / 2) + 1}
					onClick={clickFunction}
				>
					{answerString}
					{restoreButton}
					{deleteButton}
					{noButtonsIcon}
				</Badge>
			};

			//New answers
			if (Array.isArray(newQualificationAnswers) && newQualificationAnswers.length > 0) {
				badgeArray.push(<Text {...qualificationTitleProps} key={"NewQualificationsText"}>New Qualifications:</Text>);
			}
			for (let answerIndex = 0; answerIndex < newQualificationAnswers.length; answerIndex = answerIndex + 1) {
				const qualificationAnswer = newQualificationAnswers[answerIndex];
				badgeArray.push(<QualificationBadge
					key={getQualificationKey(qualificationAnswer)}
					qualificationAnswer={qualificationAnswer}
					clickFunction={() => {
						setButtonOrSubject("subject");
						setNewQualificationAnswers((prev) => {
							const newQualifications = [...prev];
							newQualifications.splice(answerIndex, 1);
							return sortQualifications(newQualifications);
						});
						setCurrentQualificationAnswer(qualificationAnswer);
					}}
					deleteFunction={(event) => {
						event.stopPropagation();
						setNewQualificationAnswers((prev) => {
							const newQualifications = [...prev];
							newQualifications.splice(answerIndex, 1);
							return sortQualifications(newQualifications);
						});
					}}
				/>);
				badgeIndex = badgeIndex + 1;
			}
			//Updated answers
			if (Array.isArray(updatedQualificationAnswers) && updatedQualificationAnswers.length > 0) {
				badgeArray.push(<Text {...qualificationTitleProps} key={"UpdatedQualificationsText"}>Updated Qualifications:</Text>);
			}
			for (let answerIndex = 0; answerIndex < updatedQualificationAnswers.length; answerIndex = answerIndex + 1) {
				const qualificationAnswer = updatedQualificationAnswers[answerIndex];
				badgeArray.push(<QualificationBadge
					key={getQualificationKey(qualificationAnswer)}
					qualificationAnswer={qualificationAnswer}
					backgroundColor={"#a88d39"}
					clickFunction={() => {
						setButtonOrSubject("subject");
						setUpdatedQualificationAnswers((prev) => {
							const newQualifications = [...prev];
							newQualifications.splice(answerIndex, 1);
							return sortQualifications(newQualifications);
						});
						setCurrentQualificationAnswer(qualificationAnswer);
					}}
					restoreFunction={(event) => {
						event.stopPropagation();
						setExistingQualificationAnswers((prev) => {
							const originalQualification = {...qualificationAnswer.unedited};
							originalQualification.unedited = qualificationAnswer.unedited;
							return sortQualifications([...prev, originalQualification]);
						});
						setUpdatedQualificationAnswers((prev) => {
							const newQualifications = [...prev];
							newQualifications.splice(answerIndex, 1);
							return sortQualifications(newQualifications);
						});
					}}
					deleteFunction={(event) => {
						event.stopPropagation();
						setUpdatedQualificationAnswers((prev) => {
							const newQualifications = [...prev];
							newQualifications.splice(answerIndex, 1);
							return sortQualifications(newQualifications);
						});
						setDeletedQualificationAnswers((prev) => {
							return sortQualifications([...prev, qualificationAnswer]);
						});
					}}
				/>);
				badgeIndex = badgeIndex + 1;
			}
			//Deleted answers
			if (Array.isArray(deletedQualificationAnswers) && deletedQualificationAnswers.length > 0) {
				badgeArray.push(<Text {...qualificationTitleProps} key={"DeletedQualificationsText"}>Deleted Qualifications:</Text>);
			}
			for (let answerIndex = 0; answerIndex < deletedQualificationAnswers.length; answerIndex = answerIndex + 1) {
				const qualificationAnswer = deletedQualificationAnswers[answerIndex];
				badgeArray.push(<QualificationBadge
					key={getQualificationKey(qualificationAnswer)}
					qualificationAnswer={qualificationAnswer}
					backgroundColor={"#c90808"}
					strikeThrough={true}
					restoreFunction={(event) => {
						event.stopPropagation();
						setDeletedQualificationAnswers((prev) => {
							const newQualifications = [...prev];
							newQualifications.splice(answerIndex, 1);
							return sortQualifications(newQualifications);
						});
						const editStatus = checkQualificationEdited(qualificationAnswer);
						if (editStatus === true) {
							setUpdatedQualificationAnswers((prev) => {
								return sortQualifications([...prev, qualificationAnswer]);
							});
						}
						else if (editStatus === false) {
							setExistingQualificationAnswers((prev) => {
								return sortQualifications([...prev, qualificationAnswer]);
							});
						}
					}}
				/>);
				badgeIndex = badgeIndex + 1;
			}
			//Existing answers
			if (Array.isArray(existingQualificationAnswers) && existingQualificationAnswers.length > 0) {
				badgeArray.push(<Text {...qualificationTitleProps} key={"ExistingQualificationsText"}>Existing Qualifications:</Text>);
			}
			for (let answerIndex = 0; answerIndex < existingQualificationAnswers.length; answerIndex = answerIndex + 1) {
				const qualificationAnswer = existingQualificationAnswers[answerIndex];
				badgeArray.push(<QualificationBadge
					key={getQualificationKey(qualificationAnswer)}
					qualificationAnswer={qualificationAnswer}
					backgroundColor={"#0e418f"}
					clickFunction={() => {
						setButtonOrSubject("subject");
						setExistingQualificationAnswers((prev) => {
							const newQualifications = [...prev];
							newQualifications.splice(answerIndex, 1);
							return sortQualifications(newQualifications);
						});
						setCurrentQualificationAnswer(qualificationAnswer);
					}}
					deleteFunction={(event) => {
						event.stopPropagation();
						setExistingQualificationAnswers((prev) => {
							const newQualifications = [...prev];
							newQualifications.splice(answerIndex, 1);
							return sortQualifications(newQualifications);
						});
						setDeletedQualificationAnswers((prev) => {
							return sortQualifications([...prev, qualificationAnswer]);
						});
					}}
				/>);
				badgeIndex = badgeIndex + 1;
			}
			setBadgeUIs(badgeArray);
		}, [answersSuccessfullySubmitted, existingQualificationAnswers, newQualificationAnswers, updatedQualificationAnswers, deletedQualificationAnswers]);

		//Define the add button that submits a new answer to the subject answers
		useEffect(() => {
			let addButtonColour = "";
			let addButtonDisabled = true;
			if (errors.currentQualificationError !== true && answersSuccessfullySubmitted === false) {
				addButtonColour = "#82f768";
				addButtonDisabled = false;
			}

			setAddButton(<Button
				backgroundColor={addButtonColour}
				key={"AddButton"}
				isDisabled={addButtonDisabled}
				onClick={() => {
					//When clicked, run the necessary validations first
					//Rerun the basic validation
					const validationResultForAnswer = validateField(currentQualificationAnswer, qualificationAnswerValidations);
					if (validationResultForAnswer.hasError != true) {
						//Create a temperary array of answers that can be used by the validation function to ensure they are valid, even with the new item
						const newQualificationAnswers = [...qualificationAnswers, currentQualificationAnswer];
						//Validate that none of the subject answers (including the new one) are for the same subject
						const validationResultForAnswers = validateField(newQualificationAnswers, questionValidations);
						
						if (validationResultForAnswers.hasError != true) {
							const editStatus = checkQualificationEdited(currentQualificationAnswer);
							if (editStatus === true) {
								setUpdatedQualificationAnswers((prev) => {
									return sortQualifications([...prev, currentQualificationAnswer]);
								});
							}
							else if (editStatus === false) {
								setExistingQualificationAnswers((prev) => {
									return sortQualifications([...prev, currentQualificationAnswer]);
								});
							}
							else if (editStatus === null) {
								setNewQualificationAnswers((prev) => {
									const newQualificationAnswer = {...currentQualificationAnswer};
									newQualificationAnswer.unedited = currentQualificationAnswer;
									return sortQualifications([...prev, newQualificationAnswer]);
								});
							}
							//Reset the state back to 'button' for a new time range to potentially be added
							setButtonOrSubject("button");
							//Reset the current subject answer to be blank for inputting a new subject
							setCurrentQualificationAnswer(blankQualification);
							setResultOptions([]);
							//Reset all errors
							setErrors((prev) => {
								return {
									...prev,
									currentQualificationError: true,
								};
							});
						}
						else {
							//If the validation was unsuccessful, flag an error to tell the user
							setErrors((prev) => {
								return {
									...prev,
									currentQualificationError: true,
									questionError: validationResultForAnswers.errorMessage,
								};
							});
						}
					}
					else {
						//If the validation was unsuccessful, flag an error to tell the user
						setErrors((prev) => {
							return {
								...prev,
								currentQualificationError: true,
								questionError: validationResultForAnswer.errorMessage,
							};
						});
					}
				}}
			>
			Add
			</Button>);
		}, [currentQualificationAnswer, qualificationAnswers, answersSuccessfullySubmitted, errors.currentQualificationError]);
		
		//Set the qualification types based on the existing qualifications and current subject (don't allow the same qualification type and subject to be added twice)
		useEffect(() => {
			//console.log(currentQualificationAnswer.subject);
			if (currentQualificationAnswer.subject == null) {
				setQualificationTypes(initialQualificationTypes);
			}
			const currentSubjectName = currentQualificationAnswer.subject;
			const newQualificationTypes = [];
			for (const qualificationType of initialQualificationTypes) {
				let qualificationTypeExists = false;
				for (const qualificationAnswer of [...existingQualificationAnswers, ...newQualificationAnswers, ...updatedQualificationAnswers]) {
					const answerSubjectName = qualificationAnswer.subject;
					const answerQualificationType = qualificationAnswer.qualificationType;
					if (answerSubjectName == currentSubjectName && answerQualificationType == qualificationType) {
						qualificationTypeExists = true;
					}
				}
				if (qualificationTypeExists === false) {
					newQualificationTypes.push(qualificationType);
				}
			}
			setQualificationTypes(newQualificationTypes);
		}, [existingQualificationAnswers, newQualificationAnswers, updatedQualificationAnswers, currentQualificationAnswer.subject]);

		//Set the result options based on the current qualification type
		useEffect(() => {
			const currentQualificationType = currentQualificationAnswer.qualificationType;
			const resultOptionsArray = qualificationResults[currentQualificationType];
			setResultOptions(resultOptionsArray);
		}, [currentQualificationAnswer.qualificationType]);

		//Set the education options based on the current qualification type
		useEffect(() => {
			const newEducationOptions = [];
			for (const tutorEducationItem of tutorEducation) {
				const educationType = tutorEducationItem.type;
				const validQualificationTypes = educationTypeQualifications[educationType];
				if (currentQualificationAnswer.qualificationType == null || validQualificationTypes.includes(currentQualificationAnswer.qualificationType)) {
					let educationID = null;
					if (tutorEducationItem.modelTypeID != null) {
						educationID = tutorEducationItem.modelTypeID.split("#")[1];
					}
					else {
						educationID = tutorEducationItem.tempID;
					}
					newEducationOptions.push({
						id: educationID,
						label: tutorEducationItem.institution
					});
				}
			}
			setEducationOptions(newEducationOptions);
			if (newEducationOptions.length == 0) {
				setCurrentQualificationAnswer((prev) => {
					return {...prev, educationID: null};
				});
			}
			else {
				setCurrentQualificationAnswer((prev) => {
					if (prev.educationID == null) {
						return {...prev, educationID: newEducationOptions[0].id};
					}
					return prev;
				});
			}
		}, [currentQualificationAnswer.qualificationType, tutorEducation]);

		//Subject Name UI
		useEffect(() => {
			const subjectOptions = [...subjects, "Other"];
			setSubjectNameUI(<FieldTemplate
				id={"Subject"}
				fieldType={"dropdown"}
				label={"Subject name"}
				value={currentQualificationAnswer.subject}
				placeholder={"-Select a subject-"}
				disabled={answersSuccessfullySubmitted}
				options={subjectOptions}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentQualificationAnswer((prev) => {
						if (prev.subject != newValue) {
							const newQualificationAnswer = {...prev, subject: newValue};
							return newQualificationAnswer;
						}
						return prev;
					});
				}}
			/>);
		} , [currentQualificationAnswer.subject, answersSuccessfullySubmitted]);

		//Qualification Type UI
		useEffect(() => {
			setQualificationTypeUI(<FieldTemplate
				id={"QualificationType"}
				fieldType={"dropdown"} 
				label={"Qualification type:"}
				placeholder={"-Select a qualification-"}
				value={currentQualificationAnswer.qualificationType}
				disabled={answersSuccessfullySubmitted}
				options={qualificationTypes}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentQualificationAnswer((prev) => {
						if (prev.qualificationType != newValue) {
							const newQualificationAnswer = {...prev, qualificationType: newValue};
							newQualificationAnswer.result = null;
							return newQualificationAnswer;
						}
						return prev;
					});
				}}
			/>);
		} , [currentQualificationAnswer.qualificationType, qualificationTypes, answersSuccessfullySubmitted]);

		//Result UI
		useEffect(() => {
			setResultUI(<FieldTemplate
				id={"Result"}
				fieldType={"dropdown"} 
				label={"Result:"}
				placeholder={"-Select a result-"}
				value={currentQualificationAnswer.result}
				disabled={answersSuccessfullySubmitted}
				hidden={currentQualificationAnswer.qualificationType == null || currentQualificationAnswer.qualificationType == ""}
				options={resultOptions}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentQualificationAnswer((prev) => {
						if (prev.result != newValue) {
							const newQualificationAnswer = {...prev, result: newValue};
							return newQualificationAnswer;
						}
						return prev;
					});
				}}
			/>);
		} , [currentQualificationAnswer.result, currentQualificationAnswer.qualificationType, resultOptions, answersSuccessfullySubmitted]);

		//Relevant Education UI
		useEffect(() => {
			const addEducationButton = <Button
				className="HoverBackground"
				children={<Icon 
					as={MdAdd}
				/>}
				position={"absolute"}
				marginTop={"30px"}
				left={"-50px"}
				width={"30px"}
				height={"30px"}
				onClick={() => {
					if (props.educationQuestionID != null) {
						if (props.questionRefs == null) {
							console.log("Question refs not set");
							return;
						}
						const educationRefs = props.questionRefs[props.educationQuestionID];
						if (educationRefs != null && educationRefs.ref != null && educationRefs.ref.current != null) {
							educationRefs.ref.current.scrollIntoView({
								behavior: "smooth",
								block: "center",
							});
							educationRefs.setHighlightLabel(true);
						}
					}
					else {
						setShowUpdateEducationUI(true);
					}
				}}
			/>
			setRelevantEducationUI(<Flex
				alignItems={"center"}
				position={"relative"}
			>
				<FieldTemplate
					id={"Education"}
					fieldType={"dropdown"} 
					label={"Relevant Education:"}
					placeholder={"N/A"}
					value={currentQualificationAnswer.educationID}
					disabled={answersSuccessfullySubmitted}
					options={educationOptions}

					//Runs when it detects a change in the input field
					onChange={(newValue) => {
						setCurrentQualificationAnswer((prev) => {
							if (prev.educationID != newValue) {
								return {...prev, educationID: newValue};
							}
							return prev;
						});
					}}
				/>
				{addEducationButton}
			</Flex>);
		} , [currentQualificationAnswer.educationID, educationOptions, answersSuccessfullySubmitted, props.educationQuestionID]);

		//Exam board UI
		useEffect(() => {
			setExamBoardUI(<FieldTemplate
				id={"ExamBoard"}
				fieldType={"dropdown"}
				label={"Exam Board:"}
				placeholder={"-Select an exam board-"}
				value={currentQualificationAnswer.examBoard}
				disabled={answersSuccessfullySubmitted}
				hidden={currentQualificationAnswer.qualificationType != "A-Level" && currentQualificationAnswer.qualificationType != "GCSE" }
				options={examBoards}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentQualificationAnswer((prev) => {
						if (prev.examBoard != newValue) {
							const newQualificationAnswer = {...prev, examBoard: newValue};
							return newQualificationAnswer;
						}
						return prev;
					});
				}}
			/>);
		}, [currentQualificationAnswer.qualificationType, currentQualificationAnswer.examBoard, answersSuccessfullySubmitted]);

		//Update education UI
		useEffect(() => {
			if (showUpdateEducationUI != true) {
				setUpdateEducationUI(null);
				return;
			}

			const educationQuestions = [{
				id: "education", 
				label: "Your education:",
				type: "tutoreducation", 
				required: false,
				defaultValue: tutorEducation || [],
				questionLabelProps: {
					...props.questionLabelProps,
					fontSize: "22px",
				}
			}];

			const updateTutorEducationForm = <Flex
				marginTop={"-40px"}
				marginBottom={"-40px"}
			>
				<FormTemplate 
					{...props} 
					submitAnswers={submitEducationUpdate} 
					existingAnswers={{
						education: tutorEducation || []
					}} 
					questions={educationQuestions} 
					//okButtonFunction={() => {setUpdateEducationUI(null)}}
					okButtonFunction={"reset"}
				/>
			</Flex>

			setUpdateEducationUI(<PopUpTemplate
				setPopUpVisibility={setShowUpdateEducationUI}
				showXButton={true}
				form={updateTutorEducationForm}
			/>);

		}, [showUpdateEducationUI, tutorEducation, submitEducationUpdate]);

			
		//Depending on the current state, return the correct components
		let formItem = null
		if (buttonOrSubject == "button") {
			//If the current state is 'button', return the add new item button
			formItem = addNewItemButton;
		}
		else if (buttonOrSubject == "subject") {
			//If the current state is 'time' (meaning a new time range is being inputted), return the time fields as well as errors and relevant buttons
			formItem = <Flex direction={"column"} gap={"2vh"}>
				<Flex direction={"column"} gap={"2vh"}>
					<Flex direction={"column"}>
						{subjectNameUI}
						{qualificationTypeUI}
						{resultUI}
						{relevantEducationUI}
						{examBoardUI}
					</Flex>
					<Flex justifyContent={"right"}>
						{cancelButton}
						{addButton}
					</Flex>
				</Flex>
			</Flex>
		}

		//Return text field component to calling function (Most likely a Form Template)
		return (
			<Flex direction="column" alignItems={"center"} alignSelf={"center"}>
				{updateEducationUI}
				{formItem}
				<Flex direction={"column"} alignItems={"center"} gap={"0vh"}>
					{badgeUIs}
				</Flex>  
			</Flex>
		);
	}
	catch (error) {
		throw "TutorSubjectsTemplate Error: " + error;
	}
}

export default TutorSubjectsTemplate;