/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const loadUser = /* GraphQL */ `query LoadUser($onlyLoad: String) {
  loadUser(onlyLoad: $onlyLoad)
}
` as GeneratedQuery<APITypes.LoadUserQueryVariables, APITypes.LoadUserQuery>;
export const getTutorRequest = /* GraphQL */ `query GetTutorRequest($id: ID!) {
  getTutorRequest(id: $id) {
    id
    tutorID
    lessonRequestID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTutorRequestQueryVariables,
  APITypes.GetTutorRequestQuery
>;
export const listTutorRequests = /* GraphQL */ `query ListTutorRequests(
  $filter: ModelTutorRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  listTutorRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tutorID
      lessonRequestID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTutorRequestsQueryVariables,
  APITypes.ListTutorRequestsQuery
>;
export const getParent = /* GraphQL */ `query GetParent($id: ID!) {
  getParent(id: $id) {
    id
    name
    phoneNumber
    students
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetParentQueryVariables, APITypes.GetParentQuery>;
export const listParents = /* GraphQL */ `query ListParents(
  $filter: ModelParentFilterInput
  $limit: Int
  $nextToken: String
) {
  listParents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      phoneNumber
      students
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListParentsQueryVariables,
  APITypes.ListParentsQuery
>;
export const getLessonRequest = /* GraphQL */ `query GetLessonRequest($id: ID!) {
  getLessonRequest(id: $id) {
    id
    studentID
    parentID
    subject
    sessionLength
    numSessions
    address
    availability
    lessonType
    schoolSet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetLessonRequestQueryVariables,
  APITypes.GetLessonRequestQuery
>;
export const listLessonRequests = /* GraphQL */ `query ListLessonRequests(
  $filter: ModelLessonRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  listLessonRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      studentID
      parentID
      subject
      sessionLength
      numSessions
      address
      availability
      lessonType
      schoolSet
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLessonRequestsQueryVariables,
  APITypes.ListLessonRequestsQuery
>;
export const getStudent = /* GraphQL */ `query GetStudent($id: ID!) {
  getStudent(id: $id) {
    id
    studentName
    parentID
    yearGroup
    postcode
    address
    defaultAvailability
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetStudentQueryVariables,
  APITypes.GetStudentQuery
>;
export const listStudents = /* GraphQL */ `query ListStudents(
  $filter: ModelStudentFilterInput
  $limit: Int
  $nextToken: String
) {
  listStudents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      studentName
      parentID
      yearGroup
      postcode
      address
      defaultAvailability
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListStudentsQueryVariables,
  APITypes.ListStudentsQuery
>;
export const getLesson = /* GraphQL */ `query GetLesson($id: ID!) {
  getLesson(id: $id) {
    id
    tutorID
    studentID
    parentID
    tutorName
    studentName
    subject
    day
    startTime
    endTime
    lessonType
    lessonLocation
    firstLessonDate
    lessonDates
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetLessonQueryVariables, APITypes.GetLessonQuery>;
export const listLessons = /* GraphQL */ `query ListLessons(
  $filter: ModelLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  listLessons(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tutorID
      studentID
      parentID
      tutorName
      studentName
      subject
      day
      startTime
      endTime
      lessonType
      lessonLocation
      firstLessonDate
      lessonDates
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListLessonsQueryVariables,
  APITypes.ListLessonsQuery
>;
export const getTutor = /* GraphQL */ `query GetTutor($id: ID!) {
  getTutor(id: $id) {
    id
    name
    email
    phoneNumber
    availability
    subjects
    referralCode
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTutorQueryVariables, APITypes.GetTutorQuery>;
export const listTutors = /* GraphQL */ `query ListTutors(
  $filter: ModelTutorFilterInput
  $limit: Int
  $nextToken: String
) {
  listTutors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      email
      phoneNumber
      availability
      subjects
      referralCode
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTutorsQueryVariables,
  APITypes.ListTutorsQuery
>;
export const getIssues = /* GraphQL */ `query GetIssues($id: ID!) {
  getIssues(id: $id) {
    id
    title
    details
    submittedBy
    due
    priority
    progress
    assignedTo
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetIssuesQueryVariables, APITypes.GetIssuesQuery>;
export const listIssues = /* GraphQL */ `query ListIssues(
  $id: ID
  $filter: ModelIssuesFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listIssues(
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      title
      details
      submittedBy
      due
      priority
      progress
      assignedTo
      type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIssuesQueryVariables,
  APITypes.ListIssuesQuery
>;
export const getUserActivity = /* GraphQL */ `query GetUserActivity(
  $userID: ID!
  $sessionStart: AWSDateTime!
  $time: AWSDateTime!
) {
  getUserActivity(userID: $userID, sessionStart: $sessionStart, time: $time) {
    userID
    time
    activity
    sessionStart
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserActivityQueryVariables,
  APITypes.GetUserActivityQuery
>;
export const listUserActivities = /* GraphQL */ `query ListUserActivities(
  $userID: ID
  $sessionStartTime: ModelUserActivityPrimaryCompositeKeyConditionInput
  $filter: ModelUserActivityFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserActivities(
    userID: $userID
    sessionStartTime: $sessionStartTime
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userID
      time
      activity
      sessionStart
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserActivitiesQueryVariables,
  APITypes.ListUserActivitiesQuery
>;
export const getUserInfo = /* GraphQL */ `query GetUserInfo($userID: ID!) {
  getUserInfo(userID: $userID) {
    userID
    firstNames
    lastName
    lastOnline
    currentSessionStartTime
    lastSessionDuration
    active
    pageVisibility
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserInfoQueryVariables,
  APITypes.GetUserInfoQuery
>;
export const listUserInfos = /* GraphQL */ `query ListUserInfos(
  $userID: ID
  $filter: ModelUserInfoFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserInfos(
    userID: $userID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      userID
      firstNames
      lastName
      lastOnline
      currentSessionStartTime
      lastSessionDuration
      active
      pageVisibility
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserInfosQueryVariables,
  APITypes.ListUserInfosQuery
>;
export const getWebsocketConnections = /* GraphQL */ `query GetWebsocketConnections($connectionID: ID!) {
  getWebsocketConnections(connectionID: $connectionID) {
    connectionID
    userID
    startTime
    active
    pageVisibility
    userAgent
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWebsocketConnectionsQueryVariables,
  APITypes.GetWebsocketConnectionsQuery
>;
export const listWebsocketConnections = /* GraphQL */ `query ListWebsocketConnections(
  $connectionID: ID
  $filter: ModelWebsocketConnectionsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listWebsocketConnections(
    connectionID: $connectionID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      connectionID
      userID
      startTime
      active
      pageVisibility
      userAgent
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWebsocketConnectionsQueryVariables,
  APITypes.ListWebsocketConnectionsQuery
>;
export const getTestLesson = /* GraphQL */ `query GetTestLesson($id: ID!, $modelType: LessonType!, $typeID: ID!) {
  getTestLesson(id: $id, modelType: $modelType, typeID: $typeID) {
    id
    tutorID
    studentOwnerID
    modelType
    sortKey
    typeID
    createdAt
    updatedAt
    version
    studentIDs
    subject
    lessonType
    sessionLength
    address {
      address1stLine
      postcode
      geocode
      __typename
    }
    day
    startTime
    endTime
    lessonStartDate
    lessonEndDate
    status
    confirmed
    type
    cancelType
    date
    lessonCost
    lessonDate
    disputed
    paidTutor
    lessonWage
    paidForLateCancellation
    qualificationScore
    firstNames
    lastNames
    phoneNumber
    yearGroup
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTestLessonQueryVariables,
  APITypes.GetTestLessonQuery
>;
export const listTestLessons = /* GraphQL */ `query ListTestLessons(
  $id: ID
  $modelTypeTypeID: ModelTestLessonPrimaryCompositeKeyConditionInput
  $filter: ModelTestLessonFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listTestLessons(
    id: $id
    modelTypeTypeID: $modelTypeTypeID
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      id
      tutorID
      studentOwnerID
      modelType
      sortKey
      typeID
      createdAt
      updatedAt
      version
      studentIDs
      subject
      lessonType
      sessionLength
      day
      startTime
      endTime
      lessonStartDate
      lessonEndDate
      status
      confirmed
      type
      cancelType
      date
      lessonCost
      lessonDate
      disputed
      paidTutor
      lessonWage
      paidForLateCancellation
      qualificationScore
      firstNames
      lastNames
      phoneNumber
      yearGroup
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTestLessonsQueryVariables,
  APITypes.ListTestLessonsQuery
>;
export const tutorRequestsByTutorID = /* GraphQL */ `query TutorRequestsByTutorID(
  $tutorID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTutorRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  tutorRequestsByTutorID(
    tutorID: $tutorID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      lessonRequestID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TutorRequestsByTutorIDQueryVariables,
  APITypes.TutorRequestsByTutorIDQuery
>;
export const tutorRequestsByLessonRequestID = /* GraphQL */ `query TutorRequestsByLessonRequestID(
  $lessonRequestID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTutorRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  tutorRequestsByLessonRequestID(
    lessonRequestID: $lessonRequestID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      lessonRequestID
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TutorRequestsByLessonRequestIDQueryVariables,
  APITypes.TutorRequestsByLessonRequestIDQuery
>;
export const lessonRequestsByStudentID = /* GraphQL */ `query LessonRequestsByStudentID(
  $studentID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelLessonRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  lessonRequestsByStudentID(
    studentID: $studentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      studentID
      parentID
      subject
      sessionLength
      numSessions
      address
      availability
      lessonType
      schoolSet
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LessonRequestsByStudentIDQueryVariables,
  APITypes.LessonRequestsByStudentIDQuery
>;
export const lessonRequestsByParentID = /* GraphQL */ `query LessonRequestsByParentID(
  $parentID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelLessonRequestFilterInput
  $limit: Int
  $nextToken: String
) {
  lessonRequestsByParentID(
    parentID: $parentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      studentID
      parentID
      subject
      sessionLength
      numSessions
      address
      availability
      lessonType
      schoolSet
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LessonRequestsByParentIDQueryVariables,
  APITypes.LessonRequestsByParentIDQuery
>;
export const studentsByParentID = /* GraphQL */ `query StudentsByParentID(
  $parentID: String!
  $sortDirection: ModelSortDirection
  $filter: ModelStudentFilterInput
  $limit: Int
  $nextToken: String
) {
  studentsByParentID(
    parentID: $parentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      studentName
      parentID
      yearGroup
      postcode
      address
      defaultAvailability
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.StudentsByParentIDQueryVariables,
  APITypes.StudentsByParentIDQuery
>;
export const lessonsByTutorID = /* GraphQL */ `query LessonsByTutorID(
  $tutorID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  lessonsByTutorID(
    tutorID: $tutorID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentID
      parentID
      tutorName
      studentName
      subject
      day
      startTime
      endTime
      lessonType
      lessonLocation
      firstLessonDate
      lessonDates
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LessonsByTutorIDQueryVariables,
  APITypes.LessonsByTutorIDQuery
>;
export const lessonsByStudentID = /* GraphQL */ `query LessonsByStudentID(
  $studentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  lessonsByStudentID(
    studentID: $studentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentID
      parentID
      tutorName
      studentName
      subject
      day
      startTime
      endTime
      lessonType
      lessonLocation
      firstLessonDate
      lessonDates
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LessonsByStudentIDQueryVariables,
  APITypes.LessonsByStudentIDQuery
>;
export const lessonsByParentID = /* GraphQL */ `query LessonsByParentID(
  $parentID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  lessonsByParentID(
    parentID: $parentID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentID
      parentID
      tutorName
      studentName
      subject
      day
      startTime
      endTime
      lessonType
      lessonLocation
      firstLessonDate
      lessonDates
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.LessonsByParentIDQueryVariables,
  APITypes.LessonsByParentIDQuery
>;
export const tutorsByReferralCode = /* GraphQL */ `query TutorsByReferralCode(
  $referralCode: String!
  $sortDirection: ModelSortDirection
  $filter: ModelTutorFilterInput
  $limit: Int
  $nextToken: String
) {
  tutorsByReferralCode(
    referralCode: $referralCode
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      email
      phoneNumber
      availability
      subjects
      referralCode
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TutorsByReferralCodeQueryVariables,
  APITypes.TutorsByReferralCodeQuery
>;
export const issuesByAssignedToAndDueAndPriority = /* GraphQL */ `query IssuesByAssignedToAndDueAndPriority(
  $assignedTo: String!
  $duePriority: ModelIssuesAssignedToDueCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIssuesFilterInput
  $limit: Int
  $nextToken: String
) {
  issuesByAssignedToAndDueAndPriority(
    assignedTo: $assignedTo
    duePriority: $duePriority
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      details
      submittedBy
      due
      priority
      progress
      assignedTo
      type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IssuesByAssignedToAndDueAndPriorityQueryVariables,
  APITypes.IssuesByAssignedToAndDueAndPriorityQuery
>;
export const issuesByAssignedToAndPriorityAndDue = /* GraphQL */ `query IssuesByAssignedToAndPriorityAndDue(
  $assignedTo: String!
  $priorityDue: ModelIssuesAssignedToPriorityCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelIssuesFilterInput
  $limit: Int
  $nextToken: String
) {
  issuesByAssignedToAndPriorityAndDue(
    assignedTo: $assignedTo
    priorityDue: $priorityDue
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      title
      details
      submittedBy
      due
      priority
      progress
      assignedTo
      type
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IssuesByAssignedToAndPriorityAndDueQueryVariables,
  APITypes.IssuesByAssignedToAndPriorityAndDueQuery
>;
export const userActivitiesByUserIDAndActivityAndTime = /* GraphQL */ `query UserActivitiesByUserIDAndActivityAndTime(
  $userID: ID!
  $activityTime: ModelUserActivityUserActivityCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelUserActivityFilterInput
  $limit: Int
  $nextToken: String
) {
  userActivitiesByUserIDAndActivityAndTime(
    userID: $userID
    activityTime: $activityTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      userID
      time
      activity
      sessionStart
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.UserActivitiesByUserIDAndActivityAndTimeQueryVariables,
  APITypes.UserActivitiesByUserIDAndActivityAndTimeQuery
>;
export const websocketConnectionsByUserIDAndStartTime = /* GraphQL */ `query WebsocketConnectionsByUserIDAndStartTime(
  $userID: ID!
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWebsocketConnectionsFilterInput
  $limit: Int
  $nextToken: String
) {
  websocketConnectionsByUserIDAndStartTime(
    userID: $userID
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      connectionID
      userID
      startTime
      active
      pageVisibility
      userAgent
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.WebsocketConnectionsByUserIDAndStartTimeQueryVariables,
  APITypes.WebsocketConnectionsByUserIDAndStartTimeQuery
>;
export const testLessonsByTutorIDAndModelType = /* GraphQL */ `query TestLessonsByTutorIDAndModelType(
  $tutorID: ID!
  $modelType: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTestLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  testLessonsByTutorIDAndModelType(
    tutorID: $tutorID
    modelType: $modelType
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentOwnerID
      modelType
      sortKey
      typeID
      createdAt
      updatedAt
      version
      studentIDs
      subject
      lessonType
      sessionLength
      day
      startTime
      endTime
      lessonStartDate
      lessonEndDate
      status
      confirmed
      type
      cancelType
      date
      lessonCost
      lessonDate
      disputed
      paidTutor
      lessonWage
      paidForLateCancellation
      qualificationScore
      firstNames
      lastNames
      phoneNumber
      yearGroup
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TestLessonsByTutorIDAndModelTypeQueryVariables,
  APITypes.TestLessonsByTutorIDAndModelTypeQuery
>;
export const testLessonsByStudentOwnerIDAndModelType = /* GraphQL */ `query TestLessonsByStudentOwnerIDAndModelType(
  $studentOwnerID: ID!
  $modelType: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTestLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  testLessonsByStudentOwnerIDAndModelType(
    studentOwnerID: $studentOwnerID
    modelType: $modelType
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentOwnerID
      modelType
      sortKey
      typeID
      createdAt
      updatedAt
      version
      studentIDs
      subject
      lessonType
      sessionLength
      day
      startTime
      endTime
      lessonStartDate
      lessonEndDate
      status
      confirmed
      type
      cancelType
      date
      lessonCost
      lessonDate
      disputed
      paidTutor
      lessonWage
      paidForLateCancellation
      qualificationScore
      firstNames
      lastNames
      phoneNumber
      yearGroup
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TestLessonsByStudentOwnerIDAndModelTypeQueryVariables,
  APITypes.TestLessonsByStudentOwnerIDAndModelTypeQuery
>;
export const testLessonsByModelTypeAndSubjectAndDayAndStartTimeAndEndTimeAndLessonType = /* GraphQL */ `query TestLessonsByModelTypeAndSubjectAndDayAndStartTimeAndEndTimeAndLessonType(
  $modelType: LessonType!
  $subjectDayStartTimeEndTimeLessonType: ModelTestLessonLessonSubjectCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTestLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  testLessonsByModelTypeAndSubjectAndDayAndStartTimeAndEndTimeAndLessonType(
    modelType: $modelType
    subjectDayStartTimeEndTimeLessonType: $subjectDayStartTimeEndTimeLessonType
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentOwnerID
      modelType
      sortKey
      typeID
      createdAt
      updatedAt
      version
      studentIDs
      subject
      lessonType
      sessionLength
      day
      startTime
      endTime
      lessonStartDate
      lessonEndDate
      status
      confirmed
      type
      cancelType
      date
      lessonCost
      lessonDate
      disputed
      paidTutor
      lessonWage
      paidForLateCancellation
      qualificationScore
      firstNames
      lastNames
      phoneNumber
      yearGroup
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TestLessonsByModelTypeAndSubjectAndDayAndStartTimeAndEndTimeAndLessonTypeQueryVariables,
  APITypes.TestLessonsByModelTypeAndSubjectAndDayAndStartTimeAndEndTimeAndLessonTypeQuery
>;
export const testLessonsByModelTypeAndDayAndStartTimeAndEndTimeAndLessonType = /* GraphQL */ `query TestLessonsByModelTypeAndDayAndStartTimeAndEndTimeAndLessonType(
  $modelType: LessonType!
  $dayStartTimeEndTimeLessonType: ModelTestLessonLessonDayCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTestLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  testLessonsByModelTypeAndDayAndStartTimeAndEndTimeAndLessonType(
    modelType: $modelType
    dayStartTimeEndTimeLessonType: $dayStartTimeEndTimeLessonType
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentOwnerID
      modelType
      sortKey
      typeID
      createdAt
      updatedAt
      version
      studentIDs
      subject
      lessonType
      sessionLength
      day
      startTime
      endTime
      lessonStartDate
      lessonEndDate
      status
      confirmed
      type
      cancelType
      date
      lessonCost
      lessonDate
      disputed
      paidTutor
      lessonWage
      paidForLateCancellation
      qualificationScore
      firstNames
      lastNames
      phoneNumber
      yearGroup
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TestLessonsByModelTypeAndDayAndStartTimeAndEndTimeAndLessonTypeQueryVariables,
  APITypes.TestLessonsByModelTypeAndDayAndStartTimeAndEndTimeAndLessonTypeQuery
>;
export const testLessonsByModelTypeAndLessonTypeAndSubjectAndDayAndStartTimeAndEndTime = /* GraphQL */ `query TestLessonsByModelTypeAndLessonTypeAndSubjectAndDayAndStartTimeAndEndTime(
  $modelType: LessonType!
  $lessonTypeSubjectDayStartTimeEndTime: ModelTestLessonLessonTypeCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTestLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  testLessonsByModelTypeAndLessonTypeAndSubjectAndDayAndStartTimeAndEndTime(
    modelType: $modelType
    lessonTypeSubjectDayStartTimeEndTime: $lessonTypeSubjectDayStartTimeEndTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentOwnerID
      modelType
      sortKey
      typeID
      createdAt
      updatedAt
      version
      studentIDs
      subject
      lessonType
      sessionLength
      day
      startTime
      endTime
      lessonStartDate
      lessonEndDate
      status
      confirmed
      type
      cancelType
      date
      lessonCost
      lessonDate
      disputed
      paidTutor
      lessonWage
      paidForLateCancellation
      qualificationScore
      firstNames
      lastNames
      phoneNumber
      yearGroup
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TestLessonsByModelTypeAndLessonTypeAndSubjectAndDayAndStartTimeAndEndTimeQueryVariables,
  APITypes.TestLessonsByModelTypeAndLessonTypeAndSubjectAndDayAndStartTimeAndEndTimeQuery
>;
export const testLessonsByModelTypeAndSubjectAndQualificationScore = /* GraphQL */ `query TestLessonsByModelTypeAndSubjectAndQualificationScore(
  $modelType: LessonType!
  $subjectQualificationScore: ModelTestLessonTutorSubjectCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTestLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  testLessonsByModelTypeAndSubjectAndQualificationScore(
    modelType: $modelType
    subjectQualificationScore: $subjectQualificationScore
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentOwnerID
      modelType
      sortKey
      typeID
      createdAt
      updatedAt
      version
      studentIDs
      subject
      lessonType
      sessionLength
      day
      startTime
      endTime
      lessonStartDate
      lessonEndDate
      status
      confirmed
      type
      cancelType
      date
      lessonCost
      lessonDate
      disputed
      paidTutor
      lessonWage
      paidForLateCancellation
      qualificationScore
      firstNames
      lastNames
      phoneNumber
      yearGroup
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TestLessonsByModelTypeAndSubjectAndQualificationScoreQueryVariables,
  APITypes.TestLessonsByModelTypeAndSubjectAndQualificationScoreQuery
>;
export const testLessonsByModelTypeAndFirstNamesAndLastNames = /* GraphQL */ `query TestLessonsByModelTypeAndFirstNamesAndLastNames(
  $modelType: LessonType!
  $firstNamesLastNames: ModelTestLessonFirstNamesCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTestLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  testLessonsByModelTypeAndFirstNamesAndLastNames(
    modelType: $modelType
    firstNamesLastNames: $firstNamesLastNames
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentOwnerID
      modelType
      sortKey
      typeID
      createdAt
      updatedAt
      version
      studentIDs
      subject
      lessonType
      sessionLength
      day
      startTime
      endTime
      lessonStartDate
      lessonEndDate
      status
      confirmed
      type
      cancelType
      date
      lessonCost
      lessonDate
      disputed
      paidTutor
      lessonWage
      paidForLateCancellation
      qualificationScore
      firstNames
      lastNames
      phoneNumber
      yearGroup
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TestLessonsByModelTypeAndFirstNamesAndLastNamesQueryVariables,
  APITypes.TestLessonsByModelTypeAndFirstNamesAndLastNamesQuery
>;
export const testLessonsByModelTypeAndLastNamesAndYearGroup = /* GraphQL */ `query TestLessonsByModelTypeAndLastNamesAndYearGroup(
  $modelType: LessonType!
  $lastNamesYearGroup: ModelTestLessonLastNameCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelTestLessonFilterInput
  $limit: Int
  $nextToken: String
) {
  testLessonsByModelTypeAndLastNamesAndYearGroup(
    modelType: $modelType
    lastNamesYearGroup: $lastNamesYearGroup
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tutorID
      studentOwnerID
      modelType
      sortKey
      typeID
      createdAt
      updatedAt
      version
      studentIDs
      subject
      lessonType
      sessionLength
      day
      startTime
      endTime
      lessonStartDate
      lessonEndDate
      status
      confirmed
      type
      cancelType
      date
      lessonCost
      lessonDate
      disputed
      paidTutor
      lessonWage
      paidForLateCancellation
      qualificationScore
      firstNames
      lastNames
      phoneNumber
      yearGroup
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TestLessonsByModelTypeAndLastNamesAndYearGroupQueryVariables,
  APITypes.TestLessonsByModelTypeAndLastNamesAndYearGroupQuery
>;
