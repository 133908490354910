import {React, useEffect, useState } from 'react';
import { Button, Flex, Text } from '@aws-amplify/ui-react';
import { Link, Navigate } from 'react-router-dom';
import { AppNavButton, FormTemplate } from '../../custom-ui-components';
import { isJSON, posterLocations, throwError, tutorLeafletLocations, whereFromOptions } from '../../App';


const RegisterAsTutor = function(props) {
    try {
        const titleTextProps = props.titleTextProps;
        const standardTextProps = props.standardTextProps;
        const APIFunctions = props.APIFunctions;
        const user = props.user;
        const userIsTutor = props.userIsTutor;
        const width = props.width;

        const [questionAnswers, setQuestionAnswers] = useState({});
        const [redirect, setRedirect] = useState(null);
        const [tutorEducationAnswers, setTutorEducationAnswers] = useState([]);

        useEffect(() => {
            if (userIsTutor) {
                setRedirect(<Navigate to={"/"}/>);
            }
        }, []);
    
        const submitAnswers = async function(returnedAnswers) { 
            try {
                setQuestionAnswers(returnedAnswers);
                const tutorAttributes = {...returnedAnswers};
                if (tutorAttributes.gender == "Other" && tutorAttributes.pronouns != null && tutorAttributes.pronouns != "") {
                    tutorAttributes.gender = tutorAttributes.pronouns;
                }

                tutorAttributes.address = {
                    postcode: tutorAttributes.address
                }

                tutorAttributes.weeklyHoursWanted = parseInt(tutorAttributes.weeklyHoursWanted, 10);

                tutorAttributes.email = user.attributes.email;

                const tutorQualifications = tutorAttributes.qualifications.add;
                tutorAttributes.qualifications = tutorQualifications;
                const tutorEducation = tutorAttributes.education.add;
                tutorAttributes.education = tutorEducation;

                console.log(tutorAttributes);
                const newTutorModel = await APIFunctions.createTutorModel(tutorAttributes);
                console.log(newTutorModel);
                console.log("Successfully registered as a tutor");
            }
            catch (error) {
                throwError(null, error);
                // if (error.response != null) {
                //     if (error.response.data != null) {
                //         error = error.response.data;
                //     }
                // }
                // if (error.questionErrors != null) {
                //     throw error;
                // }
                // if (error == "Error validating tutor email: Verification code does not match" || error == "Error validating tutor email: Email not found in whitelist database: " + user.attributes.email) {
                //     //throw "Invalid user email and verification code";
                //     throw {questionErrors: [{questionID: "verificationCode", errorMessage: "Invalid user email and verification code", useErrorMessage: true}]}
                // }
                // else if (error == "Error creating tutor model: Error invoking geocodeAddress function. Given error: Error geocoding address: \"Invalid address\"") {
                //     throw {questionErrors: [{questionID: "postcode", errorMessage: "Postcode not found", useErrorMessage: true}]};
                // }
                // else if (error == "Error creating tutor model: No tutor with given referral code found") {
                //     throw {questionErrors: [{questionID: "referral", errorMessage: "Invalid referral code", useErrorMessage: true}]};
                // }
                // else if (error == "Error creating tutor model: Tutor has already been referred") {
                //     throw {questionErrors: [{questionID: "referral", errorMessage: "Your account has already been referred, cannot use a referral code", useErrorMessage: true}]};
                // }
                // else {
                //     console.log(error);
                //     throw {
                //         message: "Error registering as a tutor. Invalid form submission",
                //         error: error
                //     };
                // }
            }
        }

        const exitForm = function () {
            let fromLocation = props.fromLocation;
            if (fromLocation == null) {
                const queryParams = new URLSearchParams(window.location.search);
                fromLocation = queryParams.get("from");
            }

            if (fromLocation != null) {
                const state = {
                    from: fromLocation,
                    userType: "tutor"
                };
                setRedirect(<Navigate to={"/Tutor/StartGuide?from=" + fromLocation} state={state} />);
                //setRedirect(<Navigate to={"/"} />);
            }
            else {
                setRedirect(<Navigate to={"/Tutor/StartGuide"} />);
            }
        }

        const handleFormChange = function (questionID, newAnswer, hasError) {
            if (questionID == "education" && hasError == false) {
                setTutorEducationAnswers(newAnswer);
            }
        }

        const titleText = <Text {...titleTextProps}  >
            Register to be a tutor
        </Text>
        
        const registerAsTutorForm = function () {
            const questions = [];
            //First name
            questions.push({
                id: "firstNames", 
                label: "First Name(s):", 
                type: "text", 
                required: true, 
                validations: [{type: "Name"}], 
                defaultValue: null, 
                placeholder: "Enter name"
            });
            //Last name
            questions.push({
                id: "lastName", 
                label: "Surname:", 
                type: "text", 
                required: true, 
                validations: [{type: "Name"}], 
                defaultValue: null, 
                placeholder: "Enter name"
            });
            //Phone number
            questions.push({
                id: "phoneNumber", 
                label: "Contact Number:", 
                type: "text", 
                required: true, 
                validations: [{type: "UKMobPhone"}], 
                defaultValue: null, 
                placeholder: "Enter phone number"
            });
            //Gender
            questions.push({
                id: "gender", 
                label: "Gender:",
                type: "radio",
                options: ["Male", "Female", "Non-Binary", "Other", "Prefer not to say"],
                required: true, 
                defaultValue: null, 
            });
            //Pronouns
            questions.push({
                id: "pronouns", 
                label: "Pronouns:",
                type: "text",
                required: false,
                onlyShow: {id: "gender", answers: ["Other"]},
                defaultValue: null, 
            });
            //Education
            questions.push({
                id: "education", 
                label: "Your education:",
                description: "(You can add or remove any in the future)",
                type: "tutoreducation", 
                required: false, 
                defaultValue: null
            });
            //Qualifications
            questions.push({
                id: "qualifications", 
                label: "Subject(s) you wish to teach:",
                description: "(You can add or remove any in the future)",
                type: "tutorqualifications", 
                required: false, 
                gradeAt: "A-Level",
                defaultValue: null,
                tutorEducation: (isJSON(tutorEducationAnswers) && tutorEducationAnswers.add != null && tutorEducationAnswers.add),
                educationQuestionID: "education"
            }); 
            //Address
            questions.push({
                id: "address", 
                label: "Postcode:", 
                type: "text", 
                required: true, 
                validations: [{type: "ValidPostcode"}], 
                defaultValue: null, 
                placeholder: "Enter postcode",
                description: "This is the postcode for the address you will commute from. It will be used in calculating the distance to potential lessons."
            });
            //Weekly hours wanted
            questions.push({
                id: "weeklyHoursWanted", 
                label: "How many hours would you ideally like to be tutoring per week?",
                description: "(You can change this later)",
                type: "text",
                required: true, 
                validations: [{type: "Integer", allowString: true, greaterThan: -1, lessThan: 100}], 
                defaultValue: null,
                extraProps: {returnNumber: true} 
            });
            //Availability
            questions.push({
                id: "availability", 
                label: "Weekly availability:",
                description: "(You will be able to update this at any point)",
                type: "weekavailability", 
                required: true, 
                validations: [{type: "ValidWeekAvailability"}], 
                defaultValue: null, 
            });
            //Previous experience
            questions.push({
                id: "previousExperience", 
                label: "Do you have any previous tutoring experience?", 
                type: "radiothendetails", 
                options: ["Yes", "No"],
                required: true, 
                validations: [], 
                defaultValue: null, 
                placeholder: "Tell us about your experience"
            });
            //Other jobs
            questions.push({
                id: "otherJobs", 
                label: "Do you have any other part time job(s)?",
                type: "radiothendetails", 
                required: true, 
                validations: [], 
                defaultValue: null, 
            });
            //Referral code
            questions.push({
                id: "referral", 
                label: "Were you referred by an existing tutor?",
                description: "Ask them for their referral code and enter it here",
                type: "text",
                required: false,
                validations: [],
                defaultValue: null,
            });
            //Where did you hear about us
            questions.push({
                id: "whereFrom", 
                label: "Where did you hear about us?", 
                type: "dropdown", 
                required: true, 
                defaultValue: null, 
                options: whereFromOptions
            });
            //Poster location
            questions.push({
                id: "whereFromPoster", 
                label: "Where was the poster located?", 
                type: "autocomplete", 
                required: true, 
                defaultValue: null, 
                options: posterLocations,
                onlyShow: {id: "whereFrom", answers: ["Our posters"]}
            });
            //Leaflet location
            questions.push({
                id: "whereFromLeaflet", 
                label: "Where was the leaflet from?", 
                type: "autocomplete", 
                required: true, 
                defaultValue: null, 
                options: tutorLeafletLocations,
                onlyShow: {id: "whereFrom", answers: ["Our leaflets"]}
            });
            //Other
            questions.push({
                id: "whereFromOther", 
                label: "More details on how you heard about us:",
                type: "text", 
                required: false, 
                defaultValue: null, 
                onlyShow: {id: "whereFrom", answers: ["Other"]}
            });
            //Checks
            questions.push({
                id: "checks",
                label: "Terms of use",
                type: "checkbox", 
                required: true, 
                defaultValue: null, 
                options: [
                    "I confirm I am aged 13 or older",
                    "I have read and accept the Next Door Tutor Terms of Service and Privacy Policy",
                    "I agree to only schedule sessions and/or complete financial transactions with my tutors via Next Door Tutor Ltd"
                ],
                optionLinks: {
                    1: [
                        {
                            startCharIndex: 43,
                            endCharIndex: 58,
                            link: "https://nextdoortutor.co.uk/TermsOfService"
                        },
                        {
                            startCharIndex: 64,
                            endCharIndex: 77,
                            link: "https://nextdoortutor.co.uk/PrivacyPolicy"
                        }
                    ]
                },
                validations: [{type: "AllChecked"}], 
            });
        
            const registerAsTutorForm = <FormTemplate 
                {...props} 
                submitAnswers={submitAnswers} 
                existingAnswers={questionAnswers} 
                questions={questions} 
                okButtonFunction={exitForm}
                returnAnswersEarly={{
                    handleChange: handleFormChange,
                    questions: ["education"]
                }}
            />
        
            return registerAsTutorForm;
        }

        return (
            <div className='HomePage'>
                <Flex 
                    direction={"column"}
                    alignItems={"center"}
                >   
                    {titleText}
                    {registerAsTutorForm()}
                    {redirect}
                </Flex>
            </div>    
        );
    }
    catch (error) {
      throw "RegisterAsTutor Error: " + error;
    }
}

export default RegisterAsTutor;
