import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Icon, Text } from '@aws-amplify/ui-react';
import { FormTemplate } from '../../custom-ui-components';
import { compareObjects, isJSON, sleep, throwError } from '../../App';
import { LoadingSpinnerPage } from '../LoadingPage';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import { Navigate } from 'react-router-dom';
import {MdSettings} from 'react-icons/md';
import EmailCodePopUp from '../../custom-ui-components/EmailCodePopUp';


const ProfilePage = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const userAttributes = user.attributes;
    const userModel = props.userModel;
    const userEmail = userAttributes.email;
    let userAddressList = userAttributes["custom:addressList"];
    try {
        userAddressList = JSON.parse(userAddressList);
    } catch {}

    const APIFunctions = props.APIFunctions;

    const [emailCodePopUp, setEmailCodePopUp] = useState(null);
    const [formKey, setFormKey] = useState(0);
    const [redirect, setRedirect] = useState(null);

    const [existingAnswers, setExistingAnswers] = useState({});
    const [formUI, setFormUI] = useState(null);
    const [userIDText, setUserIDText] = useState(null);

    //Set existing answers
    useEffect(() => {
        if (!isJSON(userModel)) {
            return;
        }
        const newExistingAnswers = {
            firstNames: userModel.firstNames,
            lastName: userModel.lastName,
            phoneNumber: userModel.phoneNumber,
            gender: userModel.gender,
            pronouns: userModel.pronouns,
            email: userEmail
        };
        setExistingAnswers(newExistingAnswers)
    }, [userModel, userEmail]);

    //UserID text
    useEffect(() => {
        if (user.username == null) {
            setUserIDText(null);
            return;
        }

        setUserIDText(<Text 
            {...standardTextProps}
            fontSize={"12px"}
        >
            UserID: {user.username}
        </Text>);
    }, [user.username]);


    const submitForm = useCallback(async function(answers) {
        if (compareObjects(existingAnswers, answers)) {
            throwError("No changes made", null, 204);
        }
        
        const existingAnswersNoEmail = {...existingAnswers};
        delete existingAnswersNoEmail.email;
        const answersNoEmail = {...answers};
        delete answersNoEmail.email;
        if (!compareObjects(existingAnswersNoEmail, answersNoEmail)) {
            await APIFunctions.updateUser(answers);
        }
        
        if (answers.email.toLowerCase() != existingAnswers.email.toLowerCase()) {
            const newUserAttributes = [
                {
                    Name: "email", 
                    Value: answers.email.toLowerCase()
                }
            ];
            await props.APIFunctions.updateUserAttributes(newUserAttributes);
            setEmailCodePopUp(<EmailCodePopUp {...props} setPopUpVisibility={setEmailCodePopUp} newEmail={answers.email.toLowerCase()} />);
        }
    }, [APIFunctions, existingAnswers]);

    //Form UI
    useEffect(() => {
        const questions = [];
        //Email
        questions.push({
            id: "email", 
            label: "Email:", 
            type: "text", 
            required: true, 
            validations: [{type: "Email"}], 
            defaultValue: existingAnswers.email, 
            placeholder: "Enter email",
        });
        //First name
        questions.push({
            id: "firstNames",
            label: "First Name(s):",
            type: "text",
            validations: [{type: "Name"}],
            placeholder: "Enter first name(s)",
            required: true,
            defaultValue: existingAnswers.firstNames
        });
        //Last name
        questions.push({
            id: "lastName",
            label: "Surname:",
            type: "text",
            validations: [{type: "Name"}],
            placeholder: "Enter surname",
            required: true,
            defaultValue: existingAnswers.lastName
        });
        //Phone number
        questions.push({
            id: "phoneNumber", 
            label: "Contact Number:", 
            type: "text", 
            required: true, 
            validations: [{type: "UKMobPhone"}], 
            defaultValue: existingAnswers.phoneNumber, 
            placeholder: "Enter phone number"
        });
        let genderDefaultValue = existingAnswers.gender || null;
        // let defaultPronounsValue = null;
        // if (genderDefaultValue != "Male" && genderDefaultValue != "Female" && genderDefaultValue != "Non-Binary" && genderDefaultValue != "Other" && genderDefaultValue != "Prefer not to say") {
        //     genderDefaultValue = "Other";
        //     defaultPronounsValue = existingAnswers.gender;
        // }
        //Gender
        questions.push({
            id: "gender", 
            label: "Gender:",
            type: "radio",
            options: ["Male", "Female", "Non-Binary", "Other", "Prefer not to say"],
            required: false, 
            defaultValue: existingAnswers.gender,
        });
        //Pronouns
        questions.push({
            id: "pronouns", 
            label: "Pronouns:",
            type: "text",
            required: false,
            onlyShow: {id: "gender", answers: ["Other"]},
            defaultValue: existingAnswers.pronouns, 
        });

        // questions.push({
        //     id: "addresses", 
        //     label: "Account Addresses:", 
        //     type: "existingornewaddress", 
        //     required: false, 
        //     validations: [{type: "ValidAddressList"}], 
        //     options: userAddressList, 
        //     defaultValue: null, 
        // });

        const formTitle = <Text {...titleTextProps}>Edit User Profile</Text>
        const userForm = <FormTemplate 
            key={"form:" + formKey} 
            {...props} 
            questions={questions} 
            submitAnswers={submitForm} 
            okButtonFunction={"reset"} 
            submitPopUpText={"Successfully updated user details"}
            existingAnswers={existingAnswers}
        />
        setFormUI(<Flex
            direction={"column"}
            gap={"1vh"}
        >
            {formTitle}
            {userForm}
        </Flex>);
    }, [existingAnswers, submitForm]);

    if (!isJSON(userModel)) {
        return <LoadingSpinnerPage />
    }
    

    const openSettings = function () {
        setRedirect(<Navigate to={"/User/Settings"} />);
    }

    const iconSize = 50;
    const settingsButton = <Flex
        style={{cursor:"pointer"}} 
        onClick={openSettings}
        backgroundColor={"#f02b4c"} 
        borderRadius={iconSize/8 + "px"}
        width={iconSize + "px"}
        height={iconSize + "px"}
        justifyContent={"center"}
        alignItems={"center"}
    ><Icon
        key={"settings"}
        width={iconSize + "px"}
        height={iconSize + "px"}
        as={MdSettings}
        color={"#d9d9d9"}
    /></Flex>
    const settingsText = <Text {...standardTextProps}>Edit More Settings:</Text>

    return (
        <Flex direction={"column"}>
            <Flex direction={"column"} alignItems={"center"} gap={"1vh"} >
                <Flex alignItems={"center"} justifyContent={"center"}>
                    {settingsText}
                    {settingsButton}
                </Flex>
                {formUI}
                {redirect}
                {userIDText}
            </Flex>
            <Flex direction={"column"} alignItems="center">
                {emailCodePopUp}
            </Flex>
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default ProfilePage;