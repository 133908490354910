import TextFieldTemplate from "./TextFieldTemplate";
import DropDownQuestionTemplate from "./DropDownQuestionTemplate";
import RadioButtonsTemplate from "./RadioButtonsTemplate";
import CheckBoxesTemplate from "./CheckBoxesTemplate";
import TimeFieldTemplate from "./TimeFieldTemplate";
import TimeRangeTemplate from "./TimeRangeTemplate";
import { WeekAvailabilityTemplate } from "./WeekAvailabilityTemplate";
import { Flex, Button, Divider, Text} from "@aws-amplify/ui-react";
import { useState, useRef, useEffect, createRef, useCallback } from 'react';
import DayAvailabilityTemplate from "./DayAvailabilityTemplate";
import AutoCompleteTemplate from "./AutoCompleteTemplate";
import PopUpTemplate from "./PopUpTemplate";
import TutorSubjectsTemplate from "./TutorQualificationsTemplate";
import RadioThenDetailsTemplate from "./RadioThenDetailsTemplate";
import ExistingOrNewAddressTemplate from "./ExistingOrNewAddressTemplate";
import CreateLessonTimeTemplate from "./CreateLessonTimeTemplate";
import { compareObjects, getErrorStatusCode, isJSON, sleep } from "../../App";
import SliderTemplate from "./SliderTemplate";
import StartDateTemplate from "./StartDateTemplate";
import StudentSubjectsTemplate from "./StudentSubjectsTemplate";
import DateTemplate from "./DateTemplate";
import TutorEducationTemplate from "./TutorEducationTemplate";
import QuestionTemplate from "../QuestionTemplate";
import { fieldTypes } from "../FieldTemplate";


const FormTemplate = function(props) {
    let okButtonFunction = props.okButtonFunction || function () {};
    if (okButtonFunction == "reset") {
        okButtonFunction = function () {
            resetForm();
        }
    }

    let showSubmitPopUp = props.submitPopPup || true;
    if (showSubmitPopUp != true && showSubmitPopUp != false) {
        showSubmitPopUp = true;
    }

    const softSubmitChecks = props.softSubmitChecks || [];

    const marginTop = props.marginTop || "5vh";

    //Set the state variable for all the question answers, this is returned to the callilng function on form submit
    
    const [validationErrors, setValidationErrors] = useState({});
    const [clearPopUp, setClearPopUp] = useState(false);
    const [submitPopUp, setSubmitPopUp] = useState(false);
    const [preSubmitPopUp, setPreSubmitPopUp] = useState({show:false, text:""});
    const [formSubmissionErrorPopUp, setFormSubmissionErrorPopUp] = useState({show:false, message:""});
    const [areYouSurePopUp, setAreYouSurePopUp] = useState(false);

    const [staticQuestionProps, setStaticQuestionProps] = useState(null);
    const [questionUIs, setQuestionUIs] = useState(null);
    const [questionIDs, setQuestionIDs] = useState(null);
    const [questionAnswers, setQuestionAnswers] = useState({});
    const [existingAnswers, setExistingAnswers] = useState({});
    const [questionErrors, setQuestionErrors] = useState({});
    const [submitButton, setSubmitButton] = useState(null);
    const [resetButton, setResetButton] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [answersSubmitted, setAnswersSubmitted] = useState(0);
    const [resetQuestionAnswers, setResetQuestionAnswers] = useState(0);
    const [currentAnswerIteration, setCurrentAnswerIteration] = useState(1);
    const [changesMade, setChangesMade] = useState(false);
    const questionRefs = useRef({});
    
    //Set existing answers
    useEffect(() => {
        if (isJSON(props.existingAnswers)) {   
            setExistingAnswers(props.existingAnswers);
        }
    }, [props.existingAnswers]);

    //Set changes made
    useEffect(() => {
        let updatedAnswers = false;
        setQuestionAnswers((prev) => {
            const newAnswers = {...prev};
            for (const [key, value] of Object.entries(existingAnswers)) {
                if (!questionAnswers.hasOwnProperty(key)) {
                    newAnswers[key] = undefined;
                    updatedAnswers = true;
                }
                if (newAnswers[key] === undefined && value === null) {
                    newAnswers[key] = null;
                    updatedAnswers = true;
                }
                else if (newAnswers[key] === null && value === undefined) {
                    newAnswers[key] = undefined;
                    updatedAnswers = true;
                }
            }
            if (updatedAnswers) {
                return newAnswers;
            }
            return prev;
        });
        if (updatedAnswers) {
            return;
        }
        if (compareObjects(questionAnswers, existingAnswers) == false) {
            setChangesMade(true);
        }
        else {
            setChangesMade(false);
        }
    }, [questionAnswers, existingAnswers]);

    //Define the function to handle any input changes in the form
    const handleChange = useCallback(function(questionID, newFieldAnswer, questionHasError) {
        //Set the state of the question answers based on the new input
        setQuestionAnswers((prev) => {
            const newQuestionAnswers = {
                ...prev,
                [questionID]: newFieldAnswer
            };

            setQuestionErrors((prev) => {
                const newQuestionErrors = {
                    ...prev,
                    [questionID]: questionHasError
                };

                if (props.subForm == true) {
                    let anyErrors = false;
                    for (const error of Object.values(questionErrors)) {
                        if (error == true) {
                            anyErrors = true;
                            break;
                        }
                    }
                    props.handleChange(questionID, newQuestionAnswers, anyErrors);
                }

                return newQuestionErrors;
            });
            return newQuestionAnswers;
        });
        
        if (props.returnAnswersEarly != null) {
            const earlyReturnQuestionIDs = props.returnAnswersEarly.questions;
            for (const earlyReturnQuestionID of earlyReturnQuestionIDs) {
                if (earlyReturnQuestionID == questionID) {
                    props.returnAnswersEarly.handleChange(questionID, newFieldAnswer, questionHasError);
                }
            }
        }
    }, [props.subForm, props.returnAnswersEarly]);

    //Function to reset all the answers in the form
    const resetAllAnswers = function() {
        setResetQuestionAnswers((prev) => {
            return prev + 1;
        });
    };

    const preSubmitPopUpStages = async function() {
        setPreSubmitPopUp({
            show: true,
            text: "Verifying answers..."
        });
        const randomWait1 = Math.floor(Math.random() * 200) + 200;
        await sleep(randomWait1);
        setPreSubmitPopUp({
            show: true,
            text: "Submitting answers..."
        });
        const randomWait2 = Math.floor(Math.random() * 400) + 300;
        await sleep(randomWait2);
        setPreSubmitPopUp({
            show: true,
            text: "Waiting for server..."
        });
    }
    
    const submit = async function(overrides = {}) {
        try {
            console.log("Form submitted");
            console.log(questionAnswers);
            for (const submitCheck of softSubmitChecks) {
                if (questionAnswers[submitCheck.questionID] != null) {
                    if (submitCheck.possibleAnswers != null) {
                        const expectedQuestionAnswers = submitCheck.possibleAnswers;
                        const givenQuestionAnswer = questionAnswers[submitCheck.questionID];
                        let foundAnswer = false;
                        if (givenQuestionAnswer != undefined) {
                            for (const possibleAnswer of expectedQuestionAnswers) {
                                if (typeof(possibleAnswer) != "object") {
                                    if (possibleAnswer == givenQuestionAnswer) {
                                        foundAnswer = true;
                                    }
                                }
                                else if (typeof(possibleAnswer) == "object") {
                                    if (compareObjects(possibleAnswer, givenQuestionAnswer)) {
                                        foundAnswer = true;
                                    }
                                }
                            }
                        }
                        else {
                            foundAnswer = true;
                        }
                        if (foundAnswer == false && overrides[submitCheck.questionID] != true) {
                            const popUpObject = {
                                text: submitCheck.message,
                                yesButtonProps: {...overrides}
                            }
                            popUpObject.yesButtonProps[submitCheck.questionID] = true
                            setAreYouSurePopUp(popUpObject);
                            return;
                        }
                    }
                    else if (submitCheck.length != null) {
                        const expectedLength = submitCheck.length;
                        let givenLength = questionAnswers[submitCheck.questionID].length;
                        if (submitCheck.answerObjectChild != null) {
                            givenLength = questionAnswers[submitCheck.questionID][submitCheck.answerObjectChild].length;
                        }
                        if (expectedLength != givenLength && overrides[submitCheck.questionID + "length"] != true) {
                            const popUpObject = {
                                text: submitCheck.message,
                                yesButtonProps: {...overrides}
                            }
                            popUpObject.yesButtonProps[submitCheck.questionID + "length"] = true
                            setAreYouSurePopUp(popUpObject);
                            return;
                        }
                    }
                }
            }
            if (showSubmitPopUp) {
                setAnswersSubmitted(currentAnswerIteration);
                preSubmitPopUpStages();
                setFormSubmitted(true);
            }

            // Validation commented out for now
            // const validationValues = [];
            // for (const question of questionComponents) {
            //     const questionProps = question.props;
            //     const questionID = questionProps.id;
            //     const currentAnswer = questionAnswers[questionID];
            //     const currentValidations = questionProps.validations;

            //     let answerToCheck = currentAnswer;
            //     if (typeof(currentAnswer) == "object") {
            //         answerToCheck = JSON.stringify(currentAnswer);
            //     }
            //     validationValues.push({
            //         fieldValue: answerToCheck,
            //         validationTypes: currentValidations,
            //         questionID: questionID
            //     });
            //     //The backend validates all the answers before submitting
                
            // }
            // const fieldValidations = await backendFieldValidations(validationValues);
            // if (fieldValidations.length > 0) {
            //     console.log("Invalid form submission, please try again.");
            //     console.log("API return validation:");
            //     console.log(fieldValidations);
            //     const errors = {};
            //     console.log("Questions with error:");
            //     for (const error of fieldValidations) {
            //         console.log(error.questionID);
            //         errors[error.questionID] = error.errorMessage;
            //     }
            //     setValidationErrors(errors);
            //     throw "Invalid answer given";
            // }
            
            
            //if (fieldValidations.length == 0) {
            if (true) {
                setValidationErrors({});
                console.log("Valid Submission");
                //Once it's validated the answers, it calls the parent submit function so that the main page with the form can handle the answers in any way
                try {
                    await props.submitAnswers(questionAnswers);
                    setCurrentAnswerIteration(currentAnswerIteration + 1);
                    if (showSubmitPopUp) {
                        setSubmitPopUp(true);
                    }
                    setPreSubmitPopUp({show: false, text: ""});
                }
                catch (error) {
                    console.log(error);
                    const errorStatusCode = getErrorStatusCode(error);
                    if (errorStatusCode != 204) {
                        props.APIFunctions.logFormError(error, questionAnswers);
                    }
                    let errorMessage = "Unknown error";
                    if (error.questionErrors != null && Array.isArray(error.questionErrors)) {
                        const questionErrors = error.questionErrors;
                        if (questionErrors.length == 1) {
                            errorMessage = "";
                        }
                        if (questionErrors.length > 1) {
                            errorMessage = "Question errors: ";
                        }
                        const errors = {};
                        for (let errorIndex = 0; errorIndex < questionErrors.length; errorIndex = errorIndex + 1) {
                            const questionError = questionErrors[errorIndex];
                            errorMessage = errorMessage + questionError.errorMessage;
                            if (errorIndex < questionErrors.length - 1) {
                                errorMessage = errorMessage + ", ";
                            }
                            errors[questionError.questionID] = questionError.errorMessage;
                            if (questionError.useErrorMessage == true) {
                                errorMessage = questionError.errorMessage;
                            }
                        }
                        setValidationErrors(errors);
                    }
                    else if (error.message != null) {
                        errorMessage = error.message;
                    }
                    else {
                        if (typeof(error) == "string") {
                            errorMessage = error;
                        }
                    }
                    setCurrentAnswerIteration(currentAnswerIteration + 1);
                    setFormSubmissionErrorPopUp({show:true, message: errorMessage});
                    setPreSubmitPopUp({show: false, text: ""});
                }
                
            }
        }
        catch (error) {
            console.log(error);
            setCurrentAnswerIteration(currentAnswerIteration + 1);
            setFormSubmissionErrorPopUp({show:true, message:error});
            setPreSubmitPopUp({show: false, text: ""});
        }
    }

    //Static question props
    useEffect(() => {
        const questionLabelProps = {
            ...props.standardTextProps,
            ...props.questionLabelProps,
            fontSize: "22px",
            textAlign: "center",
            alignSelf: "center",
            maxWidth: "400px"
        };
        const questionErrorProps = {
            ...props.standardTextProps,
            fontSize: "14px",
            position: "absolute",
            color: "#610000",
            maxWidth: "400px"
        };

        setStaticQuestionProps({
            handleChange: handleChange,
            questionRefs: questionRefs,
            answersSuccessfullySubmitted: formSubmitted,
            validationErrorColour: "#ff0000",
            questionErrorProps: questionErrorProps,
            questionLabelProps: questionLabelProps
        });
    }, [formSubmitted, props.standardTextProps, handleChange]);

    //Questions
    useEffect(() => {
        const questions = props.questions;
        if (questions == null || !Array.isArray(questions) || staticQuestionProps == null) {
            return;
        }
        if (questions.length == 0) {
            setQuestionUIs(<Text>No questions</Text>)
        }

        const newQuestionComponents = [];
        const newQuestionIDs = [];
        for (let questionIndex = 0; questionIndex < questions.length; questionIndex = questionIndex + 1) {
            const question = questions[questionIndex];
            if (question == null) {
                continue;
            }
            const fieldType = question.type;
            if (fieldType == null) {
                console.error("No field type given for question: " + question.id);
                continue;
            }

            //Set question props
            const questionKey = (question.key || question.id) + resetQuestionAnswers;
            const questionProps = {
                ...staticQuestionProps,
                passedKey: questionKey,
                validations: question.validations || [],
                validationError: validationErrors[question.id],
                defaultValue: existingAnswers[question.id],
                ...question
            };
            
            // Decide whether to show the question based on the onlyShow property
            const onlyShow = question.onlyShow;
            if (onlyShow != null) {
                const onlyShowDependentQuestionID = onlyShow.id;
                let onlyShowDependentAnswers = onlyShow.answers;
                if (typeof(onlyShowDependentAnswers) == "string") {
                    onlyShowDependentAnswers = [onlyShowDependentAnswers];
                }
                if (!Array.isArray(onlyShowDependentAnswers)) {
                    console.error("Invalid onlyShowDependentAnswers for question: " + question.id);
                }
                else {
                    if (onlyShowDependentAnswers.includes(questionAnswers[onlyShowDependentQuestionID])) {
                        questionProps[onlyShow.id] = questionAnswers[onlyShowDependentQuestionID];
                    }
                    else {
                        setQuestionAnswers((prev) => {
                            if (prev[question.id] == null) {
                                return prev;
                            }
                            return {
                                ...prev,
                                [question.id]: null
                            }
                        });
                        if ((questionAnswers[question.id] != null && questionAnswers[question.id] != false) || (questionErrors[question.id] != null && questionErrors[question.id] != false)) {
                            handleChange(question.id, false, false);
                        }
                        continue;
                    }
                }
            }
            
            //Add a required validation if required is true
            if (question.required === true) {
                //First check the 'required' validation has not already been added
                let requiredValidationExists = false;
                for (const validation of questionProps.validations) {
                    if (validation.type === "Required") {
                        requiredValidationExists = true;
                    }
                }
                if (requiredValidationExists === false) {
                    //Use unshift to add required validation to start of array
                    //This means it will be checked for being blank first
                    questionProps.validations.unshift ({
                        "type":"Required",
                        "questionType": fieldType
                    });
                }
            }

            const getQuestionComponent = function() {
                let questionComponent = null;
                if (fieldType === "time") {
                    questionComponent = <TimeFieldTemplate {...questionProps} key={questionKey}/>
                }
                else if (fieldType === "timerange") {
                    questionComponent = <TimeRangeTemplate {...questionProps} key={questionKey}/>
                }
                else if (fieldType === "dayavailability") {
                    questionComponent = <DayAvailabilityTemplate {...questionProps} key={questionKey}/>
                }
                else if (fieldType === "weekavailability") {
                    questionComponent = <WeekAvailabilityTemplate {...questionProps} key={questionKey}/>
                }
                // else if (fieldType === "tutorqualifications") {
                //     questionComponent = <TutorSubjectsTemplate {...questionProps} key={questionKey}/>
                // }
                // else if (fieldType === "tutoreducation") {
                //     questionComponent = <TutorEducationTemplate {...questionProps} key={questionKey}/>
                // }
                else if (fieldType === "studentsubject") {
                    questionComponent = <StudentSubjectsTemplate {...questionProps} key={questionKey}/>
                }
                else if (fieldType === "radiothendetails") {
                    questionComponent = <RadioThenDetailsTemplate {...questionProps} key={questionKey}/>
                }
                else if (fieldType === "existingornewaddress") {
                    questionComponent = <ExistingOrNewAddressTemplate {...questionProps} key={questionKey}/>
                }
                else if (fieldType === "date") {
                    questionComponent = <DateTemplate {...questionProps} key={questionKey}/>
                }
                else if (fieldType === "startdate") {
                    questionComponent = <StartDateTemplate {...questionProps} key={questionKey}/>
                }
                else if (fieldType === "createlessontime") {
                    questionComponent = <CreateLessonTimeTemplate {...questionProps} key={questionKey}/>
                }
                else if (fieldType === "subform") {
                    questionComponent = <FormTemplate {...questionProps} subForm={true} handleChange={handleChange} formID={question.id} questions={question.questions} key={questionKey}/>
                }
                // else if (fieldType === "radio") {
                //     questionComponent = <RadioButtonsTemplate {...questionProps} key={questionKey}/>
                // }
                else if (fieldType === "checkbox") {
                    questionComponent = <CheckBoxesTemplate {...questionProps} key={questionKey} />
                }
                else if (fieldType === "slider") {
                    questionProps.minValue = question.minValue;
                    questionProps.maxValue = question.maxValue;
                    questionProps.minValueText = question.minValueText;
                    questionProps.maxValueText = question.maxValueText;
                    questionProps.step = question.step;
                    questionProps.textValues = question.textValues;
                    questionComponent = <SliderTemplate {...questionProps} key={questionKey}/>
                }
                else if (fieldType === "autocomplete") {
                    questionComponent = <AutoCompleteTemplate {...questionProps} key={questionKey} />
                }
                else {
                    questionComponent = <QuestionTemplate 
                        fieldType={fieldType}
                        {...questionProps}
                        key={questionKey}
                    />
                }
                return questionComponent;
            }
            const questionComponent = getQuestionComponent();
    
            if (questionComponent == null) {
                continue;
            }
            newQuestionComponents.push(<Flex
                key={"questionFlex-" + questionIndex}
                id={question.id}
                direction={"column"}
                gap={"10px"}
            >
                {questionComponent}
                <Divider orientation="horizontal" key={"divider" + questionIndex} />
            </Flex>);
            //Add the question ID to an array of all the question IDs (This is used for checking states of questions)
            newQuestionIDs.push(question.id);
        }
        setQuestionUIs(newQuestionComponents);
        setQuestionIDs(newQuestionIDs);
    }, [props.questions, staticQuestionProps, validationErrors, resetQuestionAnswers, questionAnswers, handleChange, questionErrors, props.softSubmitChecks, existingAnswers]);
    
    //Remove unused quesions from questionErrors
    useEffect(() => {
        setQuestionErrors((prev) => {
            for (const questionID of Object.keys(prev)) {
                if (!questionIDs.includes(questionID)) {
                    delete prev[questionID];
                }
            }
            return prev;
        });
    }, [questionIDs]);

    //Submit button
    useEffect(() => {
        if (props.subForm == true) {
            console.log("Subform");
            setSubmitButton(null);
            return;
        }
        let submitButtonDisabled = !changesMade;
        let anyErrors = false;
        for (const error of Object.values(questionErrors)) {
            if (error == true) {
                anyErrors = true;
                break;
            }
        }
        if (anyErrors !== false || formSubmitted || props.submitDisabled == true) {
            submitButtonDisabled = true;
        }

        setSubmitButton(<Button
            children="Submit"
            type="button"
            variation="primary"
            isDisabled={submitButtonDisabled}
            fontSize={"2.2vh"}
            maxWidth={"16vh"}
            onClick={() => {
                submit();
            }}
        />);
    }, [formSubmitted, questionErrors, changesMade, props.submitDisabled, props.subForm]);

    //Reset answers button
    useEffect(() => {
        if (questionIDs == null) {
            return;
        }
        if (props.subForm == true || props.noClearButton == true) {
            setResetButton(null);
            return;
        }

        let resetAnswersText = "Reset Answers";
        if (questionIDs.length == 1) {
            resetAnswersText = "Reset Answer";
        }

        setResetButton(<Button
            children={resetAnswersText}
            fontSize={"2.2vh"}
            type="button"
            variation="secondary"
            disabled={formSubmitted}
            maxWidth={"24vh"}
            onClick={(clickEvent) => {
                const controlClick = clickEvent.ctrlKey
                if (controlClick != true) {
                    setClearPopUp(true);
                }
                else {
                    resetAllAnswers();
                }
                
            }}
        />);
    }, [questionIDs, props.subForm, props.noClearButton, formSubmitted]);

    const clearPopUpText = "Are you sure you want to reset your form answers?"
    const preSubmitPopUpText = props.preSubmitPopUpText || preSubmitPopUp.text;
    const submitPopUpText = props.submitPopUpText || "Successfully submitted answers";

    const resetForm = function() {
        resetAllAnswers();
        setFormSubmitted(false);
        setPreSubmitPopUp({
            show: false,
            text: ""
        });
        setSubmitPopUp(false);
        setFormSubmissionErrorPopUp({
            show: false,
            message: ""
        });
    }
    
    const submitOK = function() {
        okButtonFunction(resetForm, formSubmitted);
    };

    const invalidOKFunction = function () {
        setFormSubmitted(false);
    }

    return (
        <Flex 
            direction={"column"} 
            alignItems={"center"} 
            width={"100%"} 
            maxWidth={props.maxWidth || "700px"} 
            alignSelf={props.alignSelf}
        >
            <Flex 
                direction={"column"} 
                gap={"2vh"} 
                marginTop={marginTop}
                width={"100%"} 
            >       
                {questionUIs}
                <Flex justifyContent={"center"} gap={"5vw"} marginBottom={"5vh"}>
                    {resetButton}
                    {submitButton}
                </Flex>
            </Flex>
            {clearPopUp && <PopUpTemplate text={clearPopUpText} setPopUpVisibility={setClearPopUp} yesButtonFunction={resetAllAnswers} noButtonFunction={function(){}} showXButton={true} />}
            {(preSubmitPopUp.show && (answersSubmitted == currentAnswerIteration)) && <PopUpTemplate key={"preSubmit" + preSubmitPopUp.text} text={preSubmitPopUpText} setPopUpVisibility={setPreSubmitPopUp} showXButton={false} />}
            {submitPopUp && <PopUpTemplate text={submitPopUpText} setPopUpVisibility={setSubmitPopUp} okButtonFunction={submitOK} showXButton={false} />}
            {formSubmissionErrorPopUp.show && <PopUpTemplate text={formSubmissionErrorPopUp.message} setPopUpVisibility={setFormSubmissionErrorPopUp} okButtonFunction={invalidOKFunction} showXButton={false} reloadOnDismiss={true} />}
            {(areYouSurePopUp != false) && <PopUpTemplate text={areYouSurePopUp.text} yesButtonFunction={submit} yesButtonProps={areYouSurePopUp.yesButtonProps} noButtonFunction={function(){}} setPopUpVisibility={setAreYouSurePopUp} showXButton={true} />}
        </Flex>
    );
}

// async function backendFieldValidations(validations) {
//     for (const validation of validations) {
//         validation.end = "backend";
//     }
//     const getParameters = {
//         body: {
//             "validations": JSON.stringify(validations)
//         }
//     };

//     const formValidationAPI = "formValidation";
//     const fieldValidationAPIPath = "/validateFormField";

//     try {
//         const validationReturn = await API.put(formValidationAPI, fieldValidationAPIPath, getParameters);
//         return validationReturn.questionErrors;
//     }
//     catch (error) {
//         console.log(error);
//         try {
//             console.log(error.response.data);
//         } catch {}
//         throw "Error checking field validation, access to API denied: " + error;
//     }
// }

export default FormTemplate;