import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { TextField, Text, Flex, Button, Badge, Grid, Icon, SelectField } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import DropDownQuestionTemplate from "./DropDownQuestionTemplate";
import OptionsTemplate from "./OptionsTemplate";
import { subjects } from "../../App";

export const SubjectsTemplate = function(props) {
  try {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    let defaultValue = props.defaultValue;
    const questionValidations = props.validations || [];
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted || false;
    let gradeAt = "GCSE";
    if (props.gradeAt == "A-Level") {
      gradeAt = "A-Level";
    }

    const subjectAnswerValidations = [{"type":"ValidSubject"}]

    let subjectValidationExists = false;
    for (let validation of questionValidations) {
      if (validation.type === "ValidSubjects") {
        subjectValidationExists = true;
      }
    }
    if (subjectValidationExists === false) {
      questionValidations.push ({"type":"ValidSubjects"});
    }


    //Add an extra validation that will be in all day availability questions
    //This will ensure that the time range(s) given is always valid i.e. start time is before end time

    //Reset the default value incase it needs to be 
    if (defaultValue == null) {
      defaultValue = [];
    }

    //Set state variables used to store the current state and any errors
    const [subjectAnswers, setSubjectAnswers] = useState(defaultValue);
    const [currentSubjectAnswer, setCurrentSubjectAnswer] = useState(null);
    const [errors, setErrors] = useState({questionHasError:false, questionError:"", currentSubjectError:true});
    const [buttonOrSubject, setButtonOrSubject] = useState("button");

    const [forceUpdate, setForceUpdate] = useState(0);

    useEffect(() => {
      const validationResult = validateField(subjectAnswers, questionValidations);
      const questionHasError = validationResult.hasError;
      props.handleChange(questionID, subjectAnswers, questionHasError);
    }, []);


    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    const labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>
    
    const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
      {questionDescription}
    </Text>

    const errorText = <Text {...errorTextProps}>
      {errors.questionError}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    //Define the add new item button
    let newItemButtonText = "Add subject";
    if (subjectAnswers.length > 0) {
      newItemButtonText = "Add another subject";
    }
    const addNewItemButton = <Button
      //When clicked, all this does is change the mode to the adding new time range item mode
      disabled={answersSuccessfullySubmitted}
      onClick={() => {
        setButtonOrSubject("subject");
      }}
    >
    {newItemButtonText}
    </Button>

    //Define the cancel item button
    const cancelButton = <Button
      //When clicked, it set the mode back to button (where the button is needed to be pressed to add a new time range)
      //It also resets the currently inputted time range and errors
      isDisabled={answersSuccessfullySubmitted}
      onClick={() => {
        setButtonOrSubject("button");
        setCurrentSubjectAnswer(null);

        const validationResult = validateField(subjectAnswers, questionValidations);

        const existingErrors = errors;
        existingErrors.currentSubjectError = true;
        //existingErrors.questionError = "";
        existingErrors.questionError = validationResult.errorMessage;
        setErrors(existingErrors);
      }}
    >
      Cancel
    </Button>

    //This function is called when the cross on an existing subject answer is pressed
    const removeSubject = function (subject) {
      //Define a temperary new array for all the exising subject answers
      const newSubjects = [];
      //Add all the existing answers to the temperary array
      newSubjects.push(...subjectAnswers);

      //Find time range answer to remove and remove it
      for (let index = 0; index < newSubjects.length; index = index + 1) {
        const existingSubject = newSubjects[index];
        if (existingSubject === subject) {
          newSubjects.splice(index, 1);
          break
        }
      }
      //Update the main time range answers array with the updated array (with the item removed)
      setSubjectAnswers(newSubjects);

      const validationResult = validateField(newSubjects, questionValidations);
      const questionHasError = validationResult.hasError;
      setErrors({questionHasError:questionHasError, questionError:validationResult.errorMessage, currentSubjectError:errors.currentSubjectError})
      props.handleChange(questionID, newSubjects, questionHasError);
    } 

    //Create badges for currently made times

    //Now create the actual array of badges with the correct properties so that to appear in the correct location
    //The row and column they're placed in depends on their index in the array
    const badgeArray = [];
    for (let badgeIndex = 0; badgeIndex < subjectAnswers.length; badgeIndex = badgeIndex + 1) {
      const subjectAnswer = subjectAnswers[badgeIndex];
      const answerString = subjectAnswer;

      //Create the badge object
      const answerBadge = <Badge
        style={{
          alignItems: "center",
          marginRight: 10,
          marginTop: 10,
          backgroundColor:"#0e418f",
          color:"#e6edfa"
        }}
        key={answerString}
        size="small"
        columnStart={(badgeIndex % 2) + 1}
        rowStart={Math.floor(badgeIndex / 2) + 1}
      >
        {answerString}
        <Icon
          //This is a cross icon that when clicked removes the time range answer
          style={{
            cursor: "pointer",
            paddingLeft: 3,
            width: 20,
            height: 20,
          }}
          viewBox={{ width: 20, height: 20 }}
          paths={[
            {
              d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
              stroke: "white",
            },
          ]}
          ariaLabel="button"
          onClick={() => {
            //When clicked, call the function to remove the item
            if (answersSuccessfullySubmitted=== false) {
              removeSubject(subjectAnswer);
            }
          }}
        />
      </Badge>
      //Add the created badge to the badge array
      badgeArray.push(answerBadge)
    }

    let addButtonColour = "";
    let addButtonDisabled = true;
    if (errors.currentSubjectError !== true && answersSuccessfullySubmitted === false) {
      addButtonColour = "#82f768";
      addButtonDisabled = false;
    }

    //Define the add button that submits a new answer to the subject answers
    const addButton = <Button
      backgroundColor={addButtonColour}
      key={"AddButton"}
      isDisabled={addButtonDisabled}
      onClick={() => {
        //When clicked, run the necessary validations first
        //Rerun the basic validation
        const validationResultForAnswer = validateField(currentSubjectAnswer, subjectAnswerValidations);
        if (validationResultForAnswer.hasError != true) {
          //Create a temperary array of answers that can be used by the validation function to ensure they are valid, even with the new item
          const newSubjectAnswers = [];
          newSubjectAnswers.push(...subjectAnswers);
          newSubjectAnswers.push(currentSubjectAnswer);
          //Validate that none of the subject answers (including the new one) are for the same subject
          const validationResultForAnswers = validateField(newSubjectAnswers, questionValidations);
          if (validationResultForAnswers.hasError != true) {
            //If the validation was successful, update the actual subject answers with the new answer added
            setSubjectAnswers(newSubjectAnswers);
            //Reset the state back to 'button' for a new time range to potentially be added
            setButtonOrSubject("button");
            //Reset the current subject answer to be blank for inputting a new subject
            setCurrentSubjectAnswer(null);
            //Reset all errors
            setErrors({questionHasError:false, questionError:"", currentSubjectError:true});
            props.handleChange(questionID, newSubjectAnswers, false);
          }
          else {
            //If the validation was unsuccessful, flag an error to tell the user
            const existingErrors = errors;
            existingErrors.currentSubjectError = true;
            existingErrors.questionError = validationResultForAnswers.errorMessage;
            setErrors(existingErrors);
          }
        }
        else {
          //If the validation was unsuccessful, flag an error to tell the user
          const existingErrors = errors;
          existingErrors.currentSubjectError = true;
          existingErrors.questionError = validationResultForAnswer.errorMessage;
          setErrors(existingErrors);
        }
      }}
    >
    Add
    </Button>

    const subjectOptions = OptionsTemplate([...subjects, "Other"]);

    let gradeOptions = OptionsTemplate(["N/A","9", "8", "7", "6", "5", "4", "3", "2", "1"]);

    if (gradeAt == "A-Level") {
      gradeOptions = OptionsTemplate(["N/A","A*", "A", "B", "C", "D", "E", "F"]);
    }

    const subjectDropDown = <SelectField 
      label={"Subject Name"}
      value={currentSubjectAnswer}
      placeholder={"-Select a subject-"}
      disabled={answersSuccessfullySubmitted}

      //Runs when it detects a change in the input field
      onChange={(e) => {
        const currentValue = e.target.value;

        setCurrentSubjectAnswer(currentValue);
        setForceUpdate(forceUpdate + 1);
        const validationResult = validateField(currentValue, subjectAnswerValidations);
        const questionHasError = validationResult.hasError;
        const questionErrorMessage = validationResult.errorMessage;

        const newSubjectAnswers = [];
        newSubjectAnswers.push(...subjectAnswers);
        newSubjectAnswers.push(currentValue);
        const validationResultForAnswers = validateField(newSubjectAnswers, questionValidations);
        if (questionHasError === true) {
          const existingErrors = errors;
          existingErrors.currentSubjectError = questionHasError;
          existingErrors.questionError = questionErrorMessage;
          setErrors(existingErrors);
        }
        else {
          const existingErrors = errors;
          existingErrors.questionError = validationResultForAnswers.errorMessage;
          existingErrors.currentSubjectError = validationResultForAnswers.hasError;
          setErrors(existingErrors);
        }
      }}

    > 
      {subjectOptions}
    </SelectField>
      
    //Depending on the current state, return the correct components
    let formItem = null
    if (buttonOrSubject == "button") {
      //If the current state is 'button', return the add new item button
      formItem = addNewItemButton;
    }
    else if (buttonOrSubject == "subject") {
      //If the current state is 'time' (meaning a new time range is being inputted), return the time fields as well as errors and relevant buttons
      formItem = <Flex direction={"column"} gap={"2vh"}>
        <Flex direction={"column"} gap={"2vh"}>
          <Flex direction={"column"}>
            {subjectDropDown}
          </Flex>
          <Flex justifyContent={"right"}>
            {cancelButton}
            {addButton}
          </Flex>
        </Flex>
      </Flex>
    }

    //Return text field component to calling function (Most likely a Form Template)
    return (
      subjectAnswers,
      <Flex direction="column">
        <Flex direction="column" marginBottom={"0vh"} gap={"0px"}>
          <Flex alignItems={"center"}>
            {renderedRequiredAsterisk}
            {labelText}
            <Flex direction={"row"} alignItems={"center"} gap={"0vh"}>
              {badgeArray}
            </Flex>  
          </Flex>
          {descriptionText}
        </Flex>
        <Flex direction="column" alignItems={"center"} gap={"0px"}>
          {formItem}
          <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} gap={"0px"}>
            {errorText}
          </Flex>
        </Flex>
      </Flex>
    );
  }
  catch (error) {
    throw "TutorSubjectsTemplate Error: " + error;
  }
}

export default SubjectsTemplate;