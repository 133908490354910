import React, { useEffect, useRef, useState } from 'react';
import { LoadingSpinner, LoadingSpinnerPage } from '../LoadingPage';
import { Button, Divider, Flex, Grid, Icon, Text, TextAreaField, TextField } from '@aws-amplify/ui-react';
import {Link, Navigate, useLocation} from "react-router-dom";
import { calculateLessonCost, calculateLessonWage, compareObjects, getDatesForLesson, modelsStringBuilder, priceValuetoString } from '../../App';
import MapTemplate from '../../custom-ui-components/EmbededMapTemplate';
import AmendmentTemplate from '../../custom-ui-components/AmendmentTemplate';
import { DayPicker } from 'react-day-picker';
import { MdClose, MdDoNotDisturb, MdOutlineDeleteForever } from 'react-icons/md';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { validateField } from '../../custom-ui-components/form-components/FormValidation';
import BackButton from '../../custom-ui-components/BackButton';
import { DropDownQuestionTemplate, TextFieldTemplate } from '../../custom-ui-components';
import ExistingOrNewAddressTemplate from '../../custom-ui-components/form-components/ExistingOrNewAddressTemplate';
import RadioButtonsTemplate from '../../custom-ui-components/form-components/RadioButtonsTemplate';
import DayAvailabilityTemplate from '../../custom-ui-components/form-components/DayAvailabilityTemplate';

const ViewLesson = function(props) {
    const width = props.width;
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const questionErrorProps = {...standardTextProps, fontSize: "14px", position: "absolute", color: "#610000", maxWidth: "400px"};
    const standardFontSize = standardTextProps.fontSize.split("px")[0];
    const requestFontSize = (parseInt(standardFontSize, 10) - (!(width < 550) && 5 || 2) + "px");
    const requestTextProps = {...standardTextProps, textAlign: "left", maxWidth: null, fontSize: requestFontSize};
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const pageType = props.pageType;
    const userGroups = props.userGroups;
    const APIFunctions = props.APIFunctions;

    const [mapReload, setMapReload] = useState(0);
    const [userAddressList, setUserAddressList] = useState([]);
    const [dateObject, setDateObject] = useState(null);
    const [nextLesson, setNextLesson] = useState(null);
    const defaultShownDateTypes = {
        inPerson: false,
        online: false,
        previous: false,
        cancelled: false,
        oneOff: false
    };
    const [shownDateTypes, setShownDateTypes] = useState(defaultShownDateTypes);
    const [lessonProps, setLessonProps] = useState(null);
    const [datePickerFlex, setDatePickerFlex] = useState(null);
    const [selectedDateFlex, setSelectedDateFlex] = useState(null);
    const [blankBackground, setBlankBackground] = useState(null);

    const [newAmendment, setNewAmendment] = useState(null);
    const [userType, setUserType] = useState({
        isLessonStudent: false,
        isLessonParent: false,
        isLessonTutor: false,
        userType: null
    });
    const [noLessonModel, setNoLessonModel] = useState(null);
    const [lessonFlex, setLessonFlex] = useState(null);

    const lessonDictionary = props.lessonDictionary;
    const studentDictionary = props.studentDictionary;
    const tutorDictionary = props.tutorDictionary;

    //Set user address list
    useEffect(() => {
        const userAttributes = user.attributes;
        const userAddressString = userAttributes["custom:addressList"] || "[]";
        setUserAddressList(JSON.parse(userAddressString));
    }, [user]);

    //Set the lesson props
    useEffect(() => {
        if (lessonDictionary == null) {
            console.log("Lesson dictionary loading");
            return;
        }

        const queryParams = new URLSearchParams(window.location.search);
        const lessonID = queryParams.get("lessonID");
        if (lessonID == null) {
            console.log("No lessonID found");
            setNoLessonModel(true);
            return;
        }

        const lessonModel = lessonDictionary[lessonID];
        if (lessonModel == null) {
            if (userGroups.userIsAdmin && props.adminLessons == null) {
                return;
            }
            console.log("No lesson model found");
            setNoLessonModel(true);
            return;
        }

        if (props.userID == null) {
            return;
        }
        const studentIDs = lessonModel.studentIDs;
        const tutorID = lessonModel.tutorID;
        const parentID = lessonModel.parentID;
        let isLessonStudent = false;
        let isLessonParent = false;
        let isLessonTutor = false;
        if (studentIDs.includes(props.userID)) {
            isLessonStudent = true;
        }
        if (parentID == props.userID) {
            isLessonParent = true;
        }
        if (tutorID == props.userID) {
            isLessonTutor = true;
        }
        const userPageType = window.location.pathname.split("/")[1].toLowerCase();

        if (isLessonParent || userGroups.userIsAdmin) {
            if (studentDictionary == null) {
                return;
            }
            const studentModels = [];
            for (const studentID of studentIDs) {
                const studentModel = studentDictionary[studentID];
                if (studentModel == null) {
                    console.log("Student model not found");
                    setNoLessonModel(true);
                    return;
                }
                studentModels.push(studentModel);
            }
            const namesTemplate = [
                {type: "key", key: "firstNames"},
                {type: "string", string: " "},
                {type: "key", key: "lastName"},
            ];
            lessonModel.studentNames = modelsStringBuilder(studentModels, namesTemplate, ", ", ", ");

            const studentDetailsTemplate = [
                {type: "key", key: "firstNames"},
                {type: "string", string: " "},
                {type: "key", key: "lastName"},
                {type: "string", string: " (", }, 
                {type: "key", key: "yearGroup"}, 
                {type: "string", string: " - ", conditions: [{parameter: "key", type: "exists", key: "schoolSet"}]}, 
                {type: "key", key: "schoolSet", conditions: [{parameter: "key", type: "exists", key: "schoolSet"}]},
                {type: "string", string: ")"}
            ];
            lessonModel.yearGroups = modelsStringBuilder(studentModels, studentDetailsTemplate, ", ", ", ");
        }
        if (isLessonStudent || isLessonParent) {
            const tutorDetails = lessonModel.tutorDetails || {};
            lessonModel.tutorName = tutorDetails.name;
        }
        if (userGroups.userIsAdmin) {
            if (tutorDictionary == null) {
                return;
            }
            const tutorModel = tutorDictionary[tutorID];
            lessonModel.tutorName = tutorModel.firstNames + " " + tutorModel.lastName;
        }

        setLessonProps(lessonModel);

        setUserType({
            isLessonStudent: isLessonStudent,
            isLessonParent: isLessonParent,
            isLessonTutor: isLessonTutor,
            userType: userPageType
        });
    }, [lessonDictionary, studentDictionary, tutorDictionary, window.location, props.userID, userGroups, props.adminLessons]);

    //Get lesson dates
    useEffect(() => {
        if (lessonProps != null) {
            const newDateObject = {};
            const newShownDateTypes = {...defaultShownDateTypes};
            const lessonDates = getDatesForLesson(lessonProps, true);
            setNextLesson(lessonDates[0]);
            for (const lesson of lessonDates) {
                const lessonDate = lesson.date;
                const newDateArray = newDateObject[lessonDate] || [];
                newDateArray.push(lesson);
                newDateObject[lessonDate] = newDateArray;
                if (lesson.lessonType == "In-Person") {
                    newShownDateTypes.inPerson = true;
                }
                else if (lesson.lessonType == "Online") {
                    newShownDateTypes.online = true;
                }
                if (lesson.type == "extra") {
                    newShownDateTypes.oneOff = true;
                }
            }

            const lessonHistory = lessonProps.lessonHistory || [];
            for (const lessonEvent of lessonHistory) {
                if (lessonEvent.type == "deductStudent") {
                    const newLessonArray = newDateObject[lessonEvent.lessonDate] || [];
                    newLessonArray.push(lessonEvent);
                    newDateObject[lessonEvent.lessonDate] = newLessonArray;
                    newShownDateTypes.previous = true;
                }
            }

            const amendments = lessonProps.amendments || [];
            for (const amendment of amendments) {
                if (amendment.type == "cancelSingle" && amendment.confirmed == true) {
                    const newDateArray = newDateObject[amendment.date] || [];
                    newDateArray.push(amendment);
                    newDateObject[amendment.date] = newDateArray;
                    newShownDateTypes.cancelled = true;
                }
            }

            setDateObject(newDateObject);
            setShownDateTypes(newShownDateTypes);
        }
    }, [lessonProps]);

    //Calendar picker
    useEffect(() => {
        if (lessonProps == null || dateObject == null) {
            return;
        }

        const formatDay = (date) => {
            const dateString = date.toDateString();
            const dateNumber = date.getDate();
            const dateEvents = dateObject[dateString] || [];
            let hasOnlineLesson = false;
            let hasInPersonLesson = false;
            let hasPreviousLesson = false;
            let hasCancelledLesson = false;
            let hasOneOffLesson = false;
            let totalLessons = 0;
            let totalOneOffLessons = 0;
            for (const dateEvent of dateEvents) {
                if (dateEvent.type == "scheduled") {
                    totalLessons = totalLessons + 1;
                    if (dateEvent.lessonType == "Online") {
                        hasOnlineLesson = true;
                    }
                    else if (dateEvent.lessonType == "In-Person") {
                        hasInPersonLesson = true;
                    }
                }
                else if (dateEvent.type == "extra") {
                    hasOneOffLesson = true;
                    totalLessons = totalLessons + 1;
                    totalOneOffLessons = totalOneOffLessons + 1;
                    if (dateEvent.lessonType == "Online") {
                        hasOnlineLesson = true;
                    }
                    else if (dateEvent.lessonType == "In-Person") {
                        hasInPersonLesson = true;
                    }
                }
                else if (dateEvent.type == "deductStudent") {
                    hasPreviousLesson = true;
                }
                else if (dateEvent.type == "cancelSingle") {
                    hasCancelledLesson = true;
                }
            }
            let borderColour = "#00000000";
            let extraLessonText = null;
            if (hasOneOffLesson) {
                extraLessonText = "+";
            }
            if (extraLessonText != null) {
                extraLessonText = <Text 
                    fontSize={"14px"}
                    fontWeight={700}
                    position={"absolute"}
                    right={"3px"}
                    top={"-2px"}
                    overflow={"visible"}
                    color={"#8ef520"}
                    style={{textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}
                >
                    {extraLessonText}
                </Text>
            }
            let backgroundColor = "#eeeeee00";
            if (hasOnlineLesson) {
                borderColour = "#ddb30b";
                backgroundColor = "#ffde59";
            }
            if (hasInPersonLesson) {
                borderColour = "#153f8f";
                backgroundColor = "#8baef0";
            }
            let cancelledIcon = null;
            if (hasCancelledLesson) {
                cancelledIcon = <Icon
                    key={"cancelledLesson"}
                    width={"40px"}
                    height={"40px"}
                    as={MdDoNotDisturb}
                    color={"#ff0000"}
                    borderRadius={"50%"}
                    position={"absolute"}
                    opacity={0.5}
                />
            }
            let textColour = "#000000";
            if (hasPreviousLesson) {
                borderColour = "#bbbbbb";
                backgroundColor = "#cccccc";
                textColour = "#aaaaaa";
            }
            
            let hasNoEvent = !hasInPersonLesson && !hasOnlineLesson && !hasPreviousLesson && !hasCancelledLesson && !hasOneOffLesson;
            if (new Date(date.toDateString()) < new Date(new Date().toDateString())) {
                textColour = "#bbbbbb"
                if (hasNoEvent) {
                    textColour = "#cccccc";
                }
            } 

            return (
                <Flex 
                    width={"35px"}
                    height={"35px"}
                    borderRadius={"100%"}
                    backgroundColor={backgroundColor}
                    alignItems={"center"}
                    justifyContent={"center"}
                    border={"3px solid " + borderColour}
                >
                    {cancelledIcon}
                    <Text color={textColour}>{dateNumber}</Text>
                    {extraLessonText}
                </Flex>
            );
        };

        const fromDate = new Date(lessonProps.startDate).setDate(1);
        const datePicker = <Flex backgroundColor={"#eeeeee50"} borderRadius={"10px"}><DayPicker
            key={"dayPicker"}
            mode="single"
            formatters={{formatDay}}
            onSelect={(newDateAnswer)=> {
                createDateFlex(new Date(newDateAnswer).toDateString());
            }}
            fromDate={fromDate}
            // styles={{
            //     //caption: { color: 'blue' },
            //     //head: { color: 'red' },
            //     //button: { color: 'red' },
            //     //button: {color: 'black', backgroundColor: 'red' },  
            // }}
        /></Flex>
    
        let inPersonLessonKey = null;
        let onlineLessonKey = null;
        if (shownDateTypes.inPerson) {
            let inPersonKeyText = "= In-Person lesson";
            // if (onlineDates.length == 0) {
            //     inPersonKeyText = " = Lesson";
            // }
            inPersonLessonKey = <Flex alignItems={"center"} height={"fit-content"} gap={"5px"}>
                <Flex 
                    width={"30px"} 
                    height={"30px"}
                    borderRadius={"50%"}
                    backgroundColor={"#8baef0"}
                    border={"3px solid #153f8f"}
                />
                <Text {...standardTextProps} fontSize={"17px"}>{inPersonKeyText}</Text>
            </Flex>
        }
        if (shownDateTypes.online) {
            let onlineKeyText = "= Online lesson";
            // if (onlineDates.length == 0) {
            //     onlineKeyText = " = Lesson";
            // }
            onlineLessonKey = <Flex alignItems={"center"} height={"fit-content"} gap={"5px"}>
                <Flex 
                    width={"30px"} 
                    height={"30px"}
                    borderRadius={"50%"}
                    backgroundColor={"#ffde59"}
                    border={"3px solid #ddb30b"}
                />
                <Text {...standardTextProps} fontSize={"17px"}>{onlineKeyText}</Text>
            </Flex>
        }
        let cancelledLessonsKey = null;
        if (shownDateTypes.cancelled) {
            cancelledLessonsKey = <Flex alignItems={"center"} height={"fit-content"} gap={"5px"}>
                <Icon
                    key={"cancelledLessonKey"}
                    width={"33px"}
                    height={"33px"}
                    as={MdDoNotDisturb}
                    color={"#ff0000"}
                    borderRadius={"50%"}
                    marginLeft={"-3px"}
                />
                <Text {...standardTextProps} fontSize={"17px"}>= Cancelled lessons</Text>
            </Flex>
        }
        let previousLessonsKey = null;
        if (shownDateTypes.previous) {
            previousLessonsKey = <Flex alignItems={"center"} height={"fit-content"} gap={"5px"}>
                <Flex 
                    width={"30px"} 
                    height={"30px"}
                    borderRadius={"50%"}
                    backgroundColor={"#cccccc"}
                    border={"3px solid #bbbbbb"}
                />
                <Text {...standardTextProps} fontSize={"17px"}>= Past lessons</Text>
            </Flex>
        }
        let oneOffLessonKey = null;
        if (shownDateTypes.oneOff) {
            oneOffLessonKey = <Flex alignItems={"center"} height={"fit-content"} gap={"5px"}>
            <Text 
                fontSize={"18px"}
                fontWeight={700}
                overflow={"visible"}
                color={"#8ef520"}
                style={{textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black"}}
                marginLeft={"7px"}
            >
                +
            </Text>
            <Text {...standardTextProps} fontSize={"17px"} marginLeft={"10px"}>= Extra one off lessons</Text>
        </Flex>
        }

        let flexDirection = "row";
        if (props.width < 600) {
            flexDirection = "column";
        }
        setDatePickerFlex(<Flex direction={flexDirection} alignItems={"center"}>
            {datePicker}
            <Flex direction={"column"}>
                {inPersonLessonKey}
                {onlineLessonKey}
                {previousLessonsKey}
                {cancelledLessonsKey}
                {oneOffLessonKey}
            </Flex>
        </Flex>);
    }, [lessonProps, props.width, dateObject]);
    
    //Create selected date box
    const createDateFlex = function (selectedDate) {
        if (dateObject == null || selectedDate == null) {
            return;
        }
        let selectedEvents = dateObject[selectedDate];
        if (Array.isArray(selectedEvents)) {
            selectedEvents = selectedEvents.sort((a, b) => {
                if (a.startTime != null && b.startTime != null) {
                    const aStartHour = a.startTime.split(":")[0];
                    const aStartMin = a.startTime.split(":")[1];
                    const aStartTimeInt = (parseInt(aStartHour, 10) * 60) + parseInt(aStartMin, 10);
                    const bStartHour = b.startTime.split(":")[0];
                    const bStartMin = b.startTime.split(":")[1];
                    const bStartTimeInt = (parseInt(bStartHour, 10) * 60) + parseInt(bStartMin, 10);
                    if (aStartTimeInt < bStartTimeInt) {
                        return -1;
                    }
                    else if (aStartTimeInt > bStartTimeInt) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                }
                else if (a.startTime != null) {
                    return -1;
                }
                else if (b.startTime != null) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
        }

        const boxProps = {
            width: "fit-content",
            height: "fit-content",
            padding: "10px 2vw 10px 2vw",
            backgroundColor: "#b6c6f2",
            position: "absolute",
            top: "100px",
            //left: "50px",
            alignSelf: "center",
            borderRadius: "10px",
            border: "2px solid #153f8f",
            direction: "column",
            style: {zIndex:3},
        }

        const cross = <Flex 
            onClick={() => {
                setSelectedDateFlex(null);
                setBlankBackground(null);
            }}
            alignItems={"center"}
            position={"relative"}
            margin={"-10px"}
            style={{cursor: "pointer"}}
        >
            <Icon
                key={"closeButton"}
                width={"30px"}
                height={"30px"}
                as={MdClose}
                color={"#000000"}
                position={"absolute"}
                right={"-2vw"}
                top={"-10px"}
                backgroundColor={"#ff0202"}
                borderRadius={"50%"}
                border={"2px solid #ff0000"}
            />
        </Flex>

        if (selectedEvents == null || selectedEvents == []) {
            let nothingText = "Nothing scheduled";
            const amendmentProps = {
                datePicker: new Date(selectedDate),
                dayAvailability: "default"
            };
            const amendLessonState = {...lessonProps, amendmentProps: amendmentProps, newAmendmentType: "Add an extra one-off lesson"};
            // let addExtraLessonButton = <Link 
            //     to={"/" + pageType + "/AmendLesson?lessonID=" + lessonProps.id} 
            //     state={amendLessonState}
            // >
            //     <Button>Request extra lesson</Button>
            // </Link>;
            let addExtraLessonButton = <Button
                onClick={() => {
                    const newAmendment = {
                        type: "addOneOff",
                        date: selectedDate,
                        confirmed: false
                    };
                    setNewAmendment(newAmendment);
                }}
            >Request extra lesson</Button>;

            if (new Date(selectedDate) < new Date(new Date().toDateString())) {
                nothingText = "No event took place";
                addExtraLessonButton = null;
            }
            if (!userType.isLessonParent && !userType.isLessonStudent) {
                addExtraLessonButton = null;
            }
            setSelectedDateFlex(
                <Flex {...boxProps}>
                    {cross}
                    <Text {...standardTextProps} fontSize={"15px"}><u>{selectedDate}</u></Text>
                    <Text {...standardTextProps} fontSize={"15px"}>{nothingText}</Text>
                    {addExtraLessonButton}
                </Flex>
            );
        }
        else {
            const eventUIs = [];
            for (let eventIndex = 0; eventIndex < selectedEvents.length; eventIndex = eventIndex + 1) {
                const event = selectedEvents[eventIndex];
                const eventType = event.type;
                if (eventType == "scheduled" || eventType == "extra") {
                    let scheduleTypeText = "Regularly scheduled lesson";
                    if (event.type != "scheduled") {
                        scheduleTypeText = "One-off lesson";
                    }
                    const selectedLessons = {};
                    selectedLessons[event.date + "-" + event.startTime] = event;
                    // const amendLessonState = {...lessonProps, newAmendments: [cancelAmendment]};
                    // const cancelButton = <Link 
                    //     to={"/" + pageType + "/AmendLesson"} 
                    //     //state={amendLessonState}
                    // >
                    const cancelButton = <Flex 
                        position={"absolute"}
                        right={"0px"}
                        bottom={"0px"}
                        style={{cursor: "pointer"}}
                        onClick={async () => {
                            const cancelAmendment = {
                                type: "cancelSingle",
                                date: event.date,
                                startTime: event.startTime,
                                endTime: event.endTime,
                                confirmed: true,
                                cancelType: event.type
                            };
                            setNewAmendment(cancelAmendment);
                        }}
                    >
                        <Icon
                            key={"cancelledLesson"}
                            width={"30px"}
                            height={"30px"}
                            as={MdOutlineDeleteForever}
                            color={"#ff0000"}
                            borderRadius={"50%"}

                        />
                    </Flex>
                    const lessonUI = <Flex key={"Event" + eventIndex} direction={"column"} gap={"0px"} alignItems={"flex-start"} position={"relative"}>
                        <Text {...standardTextProps} fontSize={"15px"}>{scheduleTypeText}</Text>
                        <Text {...standardTextProps} fontSize={"15px"}>{event.startTime} - {event.endTime}</Text>
                        <Text {...standardTextProps} fontSize={"15px"}>{event.lessonType}</Text>
                        {cancelButton}
                    </Flex>
                    eventUIs.push(lessonUI);
                }
                else if (eventType == "deductStudent") {
                    let lessonType = lessonProps.lessonType;
                    let startTime = lessonProps.startTime;
                    let endTime = lessonProps.endTime;
                    if (event.lessonType != null) {
                        lessonType = event.lessonType;
                    }
                    if (event.startTime != null) {
                        startTime = event.startTime;
                    }
                    if (event.endTime != null) {
                        endTime = event.endTime;
                    }
                    const lessonUI = <Flex key={"Event" + eventIndex} direction={"column"} gap={"0px"} alignItems={"flex-start"} position={"relative"}>
                        <Text {...standardTextProps} fontSize={"15px"}>{lessonType} lesson:</Text>
                        <Text {...standardTextProps} fontSize={"15px"}>{startTime} - {endTime}</Text>
                        <Text {...standardTextProps} fontSize={"15px"}>Lesson Cost: {priceValuetoString(event.lessonCost)}</Text>
                    </Flex>
                    eventUIs.push(lessonUI);
                }
                else if (eventType == "cancelSingle") {
                    let cancelledBy = "you";
                    const requestedBy = event.requestedBy || {};
                    if (requestedBy.userID != user.username) {
                        cancelledBy = userType.userType;
                    }
                    let cancelTime = requestedBy.date || null;
                    if (cancelTime != null) {
                        const cancelDate = new Date(cancelTime);
                        const cancelDateString = cancelDate.getDate() + "/" + (cancelDate.getMonth() + 1) + "/" + cancelDate.getFullYear();
                        const cancelTimeString = cancelDate.getHours() + ":" + cancelDate.getMinutes();
                        cancelTime = <Text {...standardTextProps} fontSize={"15px"}>Cancelled on {cancelDateString} at {cancelTimeString}</Text>
                    }
                    const lessonUI = <Flex key={"Event" + eventIndex} direction={"column"} gap={"0px"} alignItems={"flex-start"}>
                        <Text {...standardTextProps} fontSize={"15px"}>{event.startTime} {event.cancelType} lesson cancelled by {cancelledBy}</Text>
                        {cancelTime}
                    </Flex>
                    eventUIs.push(lessonUI);
                }
                
            }

            setSelectedDateFlex(
                <Flex {...boxProps}>
                    {cross}
                    <Text {...standardTextProps} fontSize={"15px"}><u>{selectedDate}</u></Text>
                    {eventUIs}
                </Flex>
            );
        }
        setBlankBackground(
            <Flex 
                position={"absolute"} 
                top={"0px"} 
                left={"0px"} 
                width={"100%"} 
                height={"100%"} 
                backgroundColor={"#00000000"} 
                style={{zIndex: 2}}
                onClick={() => {
                    setSelectedDateFlex(null);
                    setBlankBackground(null);
                }}
            />
        );
    };
    
    //Amendment confirmation popup
    const [amendmentPopup, setAmendmentPopup] = useState({show: false, flex: null});
    const [amendmentSubmitStatus, setAmendmentSubmitStatus] = useState(0);

    //Theses are the answers given to the questions (blindly updated, may include irrelevant answers)
    const [questionAnswers, setQuestionAnswers] = useState({});
    //These are the submitted answers, only includes relevant question answers
    const [amendmentAnswers, setAmendmentAnswers] = useState({});
    const [shownQuestions, setShownQuestions] = useState({});
    const [anyErrors, setAnyErrors] = useState(true);

    const submitNewAmendment = async function () {
        try {
            setAmendmentSubmitStatus(1);
            const submitAmendment = {...newAmendment, ...amendmentAnswers};
            submitAmendment.requestedBy = {
                userID: props.userID,
                userType: userType.userType,
                date: new Date()
            };
            if (submitAmendment.notes != null) {
                submitAmendment.notes = {
                    [props.userID]: [submitAmendment.notes]
                };
            }

            const existingAmendments = lessonProps.amendments || [];
            existingAmendments.push(submitAmendment);
            const validAmendments = validateField(existingAmendments, [{type:"ValidLessonAmendments", checkCompatibility: true, lessonProps: lessonProps}]);
            if (validAmendments.hasError == true) {
                throw validAmendments.errorMessage;
            }

            const newLessonAmendments = [submitAmendment];

            const lessonModelUpdate = {
                id: lessonProps.id,
                amendments: {
                    add: newLessonAmendments
                }
            };
            const newLessonModel = await APIFunctions.updateLesson(lessonModelUpdate, userType.userType);
            console.log("Updated lesson amendments:");
            console.log(newLessonModel);
            setAmendmentSubmitStatus(2);
        }
        catch (error) {
            setAmendmentSubmitStatus(0);
            throw "Error updating lesson: " + error;
        }
    };

    //Update amendment answers
    useEffect(() => {
        const newAnswers = {};
        for (const [questionID, questionShown] of Object.entries(shownQuestions)) {
            if (questionShown != true) {
                continue;
            }
            newAnswers[questionID] = questionAnswers[questionID] || null;
        }
        setAmendmentAnswers(newAnswers);
    }, [questionAnswers, shownQuestions]);

    //Update shown questions
    useEffect(() => {
        if (newAmendment == null || newAmendment.type == null || lessonProps == null || userType == null) {
            if (shownQuestions != {}) {
                setShownQuestions({});
            }
            return;
        }

        const amendmentType = newAmendment.type;
        const newShownQuestions = {
            notes: true
        };

        //Lesson type question
        if (amendmentType == "addScheduled" || amendmentType == "changeType" || amendmentType == "addOneOff") {
            newShownQuestions.lessonType = true;
        }
        //Address question
        if (amendmentType == "changeType" && lessonProps.lessonType == "Online") {
            newShownQuestions.address = true;
        }    
        else if (amendmentType == "addOneOff" && questionAnswers.lessonType == "In-Person") {
            newShownQuestions.address = true;
        }
        else if (amendmentType == "addScheduled" && questionAnswers.lessonType == "In-Person") {
            newShownQuestions.address = true;
        }
        else if (amendmentType == "changeLocation") {
            newShownQuestions.address = true;
        }
        //Session length question
        if ((amendmentType == "addOneOff" || amendmentType == "addScheduled") && (questionAnswers.alreadyConfirmed == "No" || userType != "admin")) {
            newShownQuestions.sessionLength = true;
        }
        //Day availability question
        if (amendmentType == "addOneOff" && (questionAnswers.alreadyConfirmed == "No" || userType != "admin")) {
            newShownQuestions.dayAvailability = true;
        }
        

        setShownQuestions(newShownQuestions);
    }, [newAmendment, questionAnswers, lessonProps, userType]);

    const divderBelow = function (element, noDivider = false) {
        return <Flex direction={"column"} gap={"20px"} width={"100%"}>
            {element}
            {noDivider == false && <Divider width={"100%"}/>}
        </Flex>;
    }

    //Validate current answers and set any errors accordingly
    useEffect(() => {
        if (newAmendment == null || newAmendment.type == null || lessonProps == null || userType == null) {
            return;
        }
        const existingAmendments = lessonProps.amendments || [];
        const checkAmendment = {...newAmendment, ...amendmentAnswers};

        const allNewAmendments = [...existingAmendments, checkAmendment];
        const validNewAmendment = validateField(allNewAmendments, [{type: "ValidLessonAmendments", lessonProps: lessonProps, checkCompatibility: true, userType: userType}]);
        if (validNewAmendment.hasError) {
            setAnyErrors(true);
        }
        else {
            setAnyErrors(false);
        }

    }, [newAmendment, lessonProps, userType, amendmentAnswers]);

    //Create amendment popup
    useEffect(() => {
        if (newAmendment == null || newAmendment.type == null || lessonProps == null || userType == null) {
            return;
        }
        const amendmentType = newAmendment.type;

        const amendmentText = [];
        if (amendmentType == "cancelSingle") {
            amendmentText.push(
                <Text {...standardTextProps} fontSize={"20px"} key={"cancelLesson"} textAlign={"left"}>Cancel lesson:</Text>,
                <Text {...standardTextProps} fontSize={"20px"} key={"lessonInfo"} textAlign={"left"}>{newAmendment.date} {newAmendment.startTime} - {newAmendment.endTime}</Text>
            );
        }
        else if (amendmentType == "addOneOff") {
            amendmentText.push(
                <Text {...standardTextProps} fontSize={"20px"} key={"addLesson"} textAlign={"left"}>Request extra one-off lesson:</Text>,
                <Text {...standardTextProps} fontSize={"20px"} key={"lessonInfo"} textAlign={"left"}>{newAmendment.date}</Text>
            );
        }

        const amendmentFlex = <Flex
            key={"amendmentFlex"}
            direction={"column"} 
            gap={"0vh"} 
            alignItems={"flex-start"} 
            backgroundColor={"#f59642"} 
            borderRadius={"10px"}
            padding={"0.5vh 10px 0.5vh 10px"}
        >
            {amendmentText}
        </Flex>
        
        let sessionLengthPicker = null;
        if (shownQuestions.sessionLength == true) {
            sessionLengthPicker = divderBelow(<DropDownQuestionTemplate
                handleChange={handleChange}
                id={"sessionLength"}
                label={"Choose a session length"}
                options={["45 Minutes", "1 Hour", "1 Hour 30 Minutes", "2 Hours"]}
                defaultValue = {lessonProps.sessionLength || "1 Hour"}
                questionLabelProps={standardTextProps}
                questionErrorProps={questionErrorProps}
                answersSuccessfullySubmitted={amendmentSubmitStatus == 2}
            />);
        }

        let dayAvailabilityPicker = null;
        if (shownQuestions.dayAvailability == true) {
            dayAvailabilityPicker = divderBelow(<DayAvailabilityTemplate 
                id={"dayAvailability"} 
                key={"dayAvailability" + amendmentAnswers.sessionLength}
                label={"Availability"} 
                handleChange={handleChange}
                required={true}
                defaultValue={amendmentAnswers.dayAvailability || null}
                questionLabelProps={standardTextProps}
                questionErrorProps={questionErrorProps}
                answersSuccessfullySubmitted={amendmentSubmitStatus == 2}
                minSessionLength={amendmentAnswers.sessionLength || null}
                minValidRanges={1}
            />);
        }

        let lessonTypePicker = null;
        const existingLessonType = lessonProps.lessonType;
        const lessonTypeOptions = [];
        let defaultLessonType = existingLessonType;
        if (amendmentType == "changeType") {
            if (existingLessonType == "Online") {
                defaultLessonType = "In-Person";
                lessonTypeOptions.push("In-Person");
            }
            else {
                defaultLessonType = "Online";
                lessonTypeOptions.push("Online");
            }
        }
        else {
            lessonTypeOptions.push("In-Person", "Online");
        }
        if (shownQuestions.lessonType == true) {
            lessonTypePicker = divderBelow(<RadioButtonsTemplate
                id={"lessonType"}
                label={"Lesson Type:"}
                options={lessonTypeOptions}
                handleChange={handleChange}
                required={true}
                defaultValue={defaultLessonType}
                questionLabelProps={standardTextProps}
                questionErrorProps={questionErrorProps}
                key={"lessonType" + amendmentType}
                answersSuccessfullySubmitted={amendmentSubmitStatus == 2}
            />);
        }
        
        //console.log(amendmentAnswers);
        let addressPicker = null;
        if (shownQuestions.address == true) {
            addressPicker = divderBelow(<ExistingOrNewAddressTemplate
                id={"address"}
                label={"Address:"}
                handleChange={handleChange}
                required={true}
                options={[...userAddressList]}
                //defaultValue={defaultAnswers.address}
                validations={[{type:"ValidAddress"}]}
                questionLabelProps={standardTextProps}
                questionErrorProps={questionErrorProps}
                answersSuccessfullySubmitted={amendmentSubmitStatus == 2}
                //key={"address" + amendmentType + answers.lessonType + defaultAnswers.lessonType + defaultAnswers.address}
            />);
        }

        const notesBox = divderBelow(<TextFieldTemplate 
            id={"notes"}
            label={"Notes:"}
            handleChange={handleChange}
            extraProps={{
                textType: "paragraph"
            }}
            questionLabelProps={standardTextProps}
            answersSuccessfullySubmitted={amendmentSubmitStatus == 2}
        />, true);

        const hidePopup = function () {
            setAmendmentPopup({show: false, flex: null});
            setAmendmentSubmitStatus(0);
            setNewAmendment(null);
            setQuestionAnswers({});
            setAmendmentAnswers({});
            setShownQuestions({});
        }

        let submitText = "Confirm";
        if (amendmentSubmitStatus == 2) {
            submitText = "Ok";
        }

        const submitButton = <Button
            backgroundColor={"#8ef520"}
            onClick={async () => {
                try {
                    if (amendmentSubmitStatus == 0) {
                        await submitNewAmendment();
                    }
                    else {
                        hidePopup();
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }}
            disabled={amendmentSubmitStatus == 1 || anyErrors == true}
        >
            {submitText}
        </Button>

        let submitingText = null;
        if (amendmentSubmitStatus == 1) {
            submitingText = <Text {...standardTextProps} fontSize={"20px"}>Submitting...</Text>
        }
        else if (amendmentSubmitStatus == 2) {
            submitingText = <Text {...standardTextProps} fontSize={"20px"}>Submitted</Text>
        }

        const popupFlex = <Flex direction={"column"} gap={"2vh"} alignItems={"center"}>
            {amendmentFlex}
            {lessonTypePicker}
            {sessionLengthPicker}
            {dayAvailabilityPicker}
            {addressPicker}
            {notesBox}
            {submitButton}
            {submitingText}
        </Flex>
        
        let showXButton = true;
        if (amendmentSubmitStatus == 1) {
            showXButton = false;
        }
        const confirmAmendmentFlex = <PopUpTemplate form={popupFlex} showXButton={showXButton} setPopUpVisibility={hidePopup} />

        setAmendmentPopup({show: true, flex: confirmAmendmentFlex});
        setBlankBackground(null);
        setSelectedDateFlex(null);
    }, [questionAnswers, newAmendment, lessonProps, amendmentSubmitStatus, APIFunctions, shownQuestions, amendmentAnswers, anyErrors]);

    const handleChange = function (changedQuestionID, newAnswer) {
        setQuestionAnswers(previousAnswers => ({
            ...previousAnswers,
            [changedQuestionID]: newAnswer
        }));
    };

    //Lesson details
    useEffect(() => {
        if (lessonProps == null || userType == null) {
            return;
        }

        const UIs = [];
        UIs.push(<Text {...standardTextProps} key={"lessonDetailsTitle"} marginBottom={!(width < 1200) && "10px" || "0px"}>
            Lesson Details:
        </Text>);
        if (userType.isLessonTutor) {
            UIs.push(<Text {...requestTextProps} key={"nameText"}>
                Teaching {lessonProps.subject} to {lessonProps.studentNames} 
            </Text>);
        }
        else if (userType.isLessonParent) {
            UIs.push(<Text {...requestTextProps} key={"nameText"}>
                {lessonProps.tutorName} teaching {lessonProps.subject} to {lessonProps.studentNames}
            </Text>);
        }
        else if (userType.isLessonStudent) {
            UIs.push(<Text {...requestTextProps} key={"nameText"}>
                Tutor: {lessonProps.tutorName} teaching you {lessonProps.subject}
            </Text>);
        }
        UIs.push(
            <Text {...requestTextProps} key={"lessonTime"}>
                {lessonProps.lessonDay}s: {lessonProps.startTime} - {lessonProps.endTime}
            </Text>,
            <Text {...requestTextProps} key={"lessonType"}>
                Lesson type: {lessonProps.lessonType}
            </Text>
        );
        if (lessonProps.lessonType == "In-Person") {
            UIs.push(
                <Text {...requestTextProps} key={"address1stLine"}>
                    Lesson location: {lessonProps.address.address1stLine}, {lessonProps.address.postcode}
                </Text>
            );
        }
        else if (lessonProps.lessonType == "Online" && lessonProps.googleMeetCode != null) {
            UIs.push(
                <Text {...requestTextProps} key={"googleMeetCode"}>
                    Google Meet Link: <Link to={"https://meet.google.com/" + lessonProps.googleMeetCode} target='_blank'>https://meet.google.com/{lessonProps.googleMeetCode}</Link>
                </Text>
            );
        }
        let endDate = "Not set";
        if (lessonProps.endDate != null) {
            endDate = lessonProps.endDate;
        }
        UIs.push(
            <Text {...requestTextProps} key={"finalLesson"}>
                Final lesson date: {endDate}
            </Text>
        );
        if (!userType.isLessonTutor) {
            UIs.push(
                <Text {...requestTextProps} key={"tutorLevel"}>
                    Tutor level: {lessonProps.tutorLevel}
                </Text>
            );
        }
        if (!userType.isLessonStudent) {
            let yearGroupPlural = "";
            if (lessonProps.studentIDs.length > 1) {
                yearGroupPlural = "s";
            }
            UIs.push(
                <Text {...requestTextProps} key={"StudentYearGroup"}>
                    Student year group{yearGroupPlural}: {lessonProps.yearGroups}
                </Text>
            );
        }
        if (userType.isLessonTutor && lessonProps.parentName != null) {
            UIs.push(
                <Text {...requestTextProps} key={"parentName"}>
                    Parent: {lessonProps.parentName}
                </Text>
            );
        }
        if (userType.isLessonParent || userType.isLessonStudent) {
            const lessonCostText = <Text {...requestTextProps} key={"lessonCostText"}>
                Weekly cost: {calculateLessonCost(lessonProps.tutorLevel, lessonProps.startTime, lessonProps.endTime, lessonProps.lessonType, true)}
            </Text>
            UIs.push(lessonCostText);
        }
        else if (userType.isLessonTutor) {
            const lessonWageText = <Text {...requestTextProps} key={"lessonWageText"}>
                Weekly wage: {calculateLessonWage(lessonProps.tutorLevel, lessonProps.startTime, lessonProps.endTime, lessonProps.lessonType, true)}
            </Text>
            UIs.push(lessonWageText);
        }

        UIs.push(
            <Text {...requestTextProps} key={"startDate"}>
                First lesson date: {lessonProps.startDate}
            </Text>
        );

        let nextLessonString = null;
        if (nextLesson != null) {
            nextLessonString = nextLesson.date + " (" + nextLesson.startTime + "-" + nextLesson.endTime + ")";
        }
        if (nextLessonString != null) {
            UIs.push(
                <Text {...requestTextProps} key={"nextDate"}>
                    Next lesson: {nextLessonString}
                </Text>,
            );
        }
    
        setLessonFlex(<Flex direction={"column"} alignItems={"start"} gap={"0px"}>
            {UIs}
        </Flex>);
    }, [lessonProps, userType, nextLesson, width]);

    if (noLessonModel) {
        return <Navigate to={"/Parent"} />
    }

    if (lessonProps == null || lessonDictionary == null || compareObjects(lessonDictionary, {})) {
        return <LoadingSpinnerPage {...props} />
    }
    
    
    
    const titleText = <Text {...titleTextProps}>View lesson</Text>
    
    let backLink = "/Student/MyLessons";
    let amendmentLink = "/Student/AmendLesson?lessonID=" + lessonProps.id;
    let state = null;
    if (userType.userType == "parent") {
        backLink = "/Parent/MyLessons";
        amendmentLink = "/Parent/AmendLesson?lessonID=" + lessonProps.id;;
    }
    else if (userType.userType == "tutor") {
        backLink = "/Tutor/MyLessons";
        amendmentLink = "/Tutor/AmendLesson?lessonID=" + lessonProps.id;;
    }

    const backButton = <BackButton {...props} defaultBack={backLink} />

    const amendState = {...lessonProps, student: state};
    delete amendState.newAmendmentDate;
    delete amendState.newAmendmentType;
    const amendLessonButton = <Link to={amendmentLink} style={{textDecoration: 'none'}} state={amendState} ><Button>Make a further change to all lessons</Button></Link>

    let embeddedMap = null;
    const userSettings = props.settings || {};
    let mapWidth = 40*width/100
    if (lessonProps.lessonType != "Online" && userType == "tutor") {
        const tutorModel = props.tutorModel;
        if (tutorModel == null || tutorModel == "loading") {
            embeddedMap = <LoadingSpinner />
        }
        else if (tutorModel == "error") {
            embeddedMap = <Text {...standardTextProps}>Error loading map</Text>
        }
        else {
            embeddedMap = <MapTemplate
                {...props}
                type={"directions"} 
                origin={tutorModel.address.postcode} 
                destination={lessonProps.address.postcode} 
                width={mapWidth}
                height={"400px"}
                distanceUnit={userSettings.distanceUnit}
                mode={"walking"}
                key={"Map" + mapReload}
            />
        }
    }

    let existingAmendmentsTitle = null;
    let existingAmendmentsUIs = [];
    let pendingAmendmentsTitle = null;
    let pendingAmendmentsUIs = [];
    let rejectedAmendmentsTitle = null;
    let rejectedAmendmentsUIs = [];
   
    const existingAmendments = lessonProps.amendments;
    if (existingAmendments != null && existingAmendments.length > 0) {
        for (let amendmentIndex = 0; amendmentIndex < existingAmendments.length; amendmentIndex = amendmentIndex + 1) {
            const existingAmendment = existingAmendments[amendmentIndex];
            const amendmentUI = <AmendmentTemplate
                key={"ExistingAmendmentIndex:" + amendmentIndex} 
                amendment={existingAmendment} 
                standardTextProps={standardTextProps}
                lessonProps={lessonProps}
            />
            if (existingAmendment.confirmed == true) {
                existingAmendmentsUIs.push(amendmentUI);
                existingAmendmentsTitle = <Text {...standardTextProps}>Confirmed changes to lesson:</Text>;
            }
            else if (existingAmendment.confirmed == false && existingAmendment.rejectedBy != null) {
                rejectedAmendmentsTitle = <Text {...standardTextProps}>Rejected changes:</Text>;
                rejectedAmendmentsUIs.push(amendmentUI);
            }
            else {
                pendingAmendmentsTitle = <Text {...standardTextProps}>Pending changes:</Text>;
                pendingAmendmentsUIs.push(amendmentUI);
            }
        }
    }    

    let mapShown = embeddedMap != null;
    let calendarColumn = 2;
    let calendarRow = 1;
    let amendmentsRow = 2
    let mapColumn = 2;
    let mapRow = 2;
    let columnGap = "10vw";
    if (width < 1200) {
        mapWidth = 80*width/100;
        calendarColumn = 1;
        calendarRow = 2
        amendmentsRow = 3;
        if (mapShown) {
            mapColumn = 1;
            mapRow = 3;
            amendmentsRow = 4;
        }
    }
    let calendarAlign = null;
    let calendarTextMargin = "35px";
    if (width < 600) {
        calendarAlign = "center";
        calendarTextMargin = "0px";
    }

    return (
        <Flex direction={"column"} gap={"4vh"} alignItems={"center"}>
            {blankBackground}
            {amendmentPopup.show && amendmentPopup.flex}
            <Flex direction={"column"} gap={"4vh"} alignItems={"center"} >
                {titleText}
                <Flex gap={"4vw"}>
                    {backButton}
                </Flex>
            </Flex>
            <Grid alignItems={"flex-start"} columnGap={columnGap} rowGap={"2vh"}>
                <Flex direction={"column"} gap={"4vh"} alignItems={"flex-start"} width={"fit-content"} column={1} row={1}>
                    {lessonFlex}
                </Flex>
                <Flex position={"relative"} direction={"column"} column={1} row={amendmentsRow}>
                    <Flex direction={"column"} gap={"2vh"} alignItems={"flex-start"}>
                        {pendingAmendmentsTitle}
                        {pendingAmendmentsUIs}
                    </Flex>
                    <Flex direction={"column"} gap={"2vh"} alignItems={"flex-start"}>
                        {existingAmendmentsTitle}
                        {existingAmendmentsUIs}
                    </Flex>
                    <Flex direction={"column"} gap={"2vh"} alignItems={"flex-start"}>
                        {rejectedAmendmentsTitle}
                        {rejectedAmendmentsUIs}
                    </Flex>
                </Flex>
                <Flex position={"relative"} direction={"column"} column={calendarColumn} row={calendarRow} alignItems={calendarAlign}>
                    <Text {...standardTextProps} textAlign={"left"} marginBottom={"-10px"}>Upcoming lessons:</Text>
                    {datePickerFlex}
                    {selectedDateFlex}
                    <Text {...requestTextProps} marginTop={"-10px"} marginLeft={calendarTextMargin} fontSize={"15px"} maxWidth={"250px"} textAlign={"center"}>To make changes to a specific date, select on the calendar above</Text>
                    {amendLessonButton}
                </Flex>
                <Flex column={mapColumn} row={mapRow}>
                    {embeddedMap}
                </Flex>
            </Grid>
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default ViewLesson;