import { Flex } from "@aws-amplify/ui-react";
import React from "react";
import {Outlet} from "react-router-dom";
import Navbar from "../home/HomeNavbar";
import FooterTemplate from "../../custom-ui-components/FooterTemplate";
import { LoadingSpinnerPage } from "../LoadingPage";


const UserLayout = function(props) {
  const height = props.height;
  const sideMarginSize = props.sideMarginSize;

  const userModel = props.userModel;
  if (userModel === null) {
    return (
      <div className="Page" style={{minHeight:height}}>
        <Navbar {...props} pageType={"user"}/>
        <LoadingSpinnerPage {...props} height={height} />
      </div>
    )
  } 

  return (
    <div className="Page" style={{minHeight:height}}>
      <Flex direction={"column"} marginLeft={sideMarginSize} marginRight={sideMarginSize} alignItems={"center"}>
        <Flex style={{zIndex:2}} marginBottom={"50px"}>
          <Navbar {...props} pageType={"user"}/>
        </Flex>
        <Flex style={{zIndex:1}}>
          <Outlet />
        </Flex>
        <br />
        <FooterTemplate {...props}/>
      </Flex>
    </div>
  );

}

export default UserLayout;