import * as React from "react";
import { Text, Flex, Button, Badge, Grid, Icon, SelectField } from "@aws-amplify/ui-react";
import { useState, useEffect, useCallback } from 'react';
import { validateField } from "./FormValidation";
import { compareObjects, educationTypes, generateUUID, isJSON } from "../../App";
import FieldTemplate from "../FieldTemplate";
import { RiArrowGoBackFill } from "react-icons/ri";

export const TutorEducationTemplate = function(props) {
	try {
		//Set question variables from passed properties
		const questionID = props.id;
		const defaultValue = props.defaultValue;
		const questionValidations = props.validations || [];
		const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;
		const educationAnswerValidations = [{"type":"ValidTutorEducation"}];

		let educationValidationExists = false;
		for (let validation of questionValidations) {
			if (validation.type === "ValidTutorEducationList") {
				educationValidationExists = true;
			}
		}
		if (educationValidationExists === false) {
			questionValidations.push ({"type":"ValidTutorEducationList"});
		}

		//Define a blank education answer for resetting to
		const blankEducation = useCallback(function() {
			return {
				type: null,
				institution: null,
				graduated: null,
				currentYear: null,
				graduationYear: null,
				tempID: generateUUID()
			};
		}, []);

		const [educationAnswers, setEducationAnswers] = useState([]);
		const [existingEducationAnswers, setExistingEducationAnswers] = useState([]);
		const [deletedEducationAnswers, setDeletedEducationAnswers] = useState([]);
		const [newEducationAnswers, setNewEducationAnswers] = useState([]);
		const [updatedEducationAnswers, setUpdatedEducationAnswers] = useState([]);
		const [currentEducationAnswer, setCurrentEducationAnswer] = useState(blankEducation());
		const [cancelButton, setCancelButton] = useState(null);
		const [addButton, setAddButton] = useState(null);
		const [addNewItemButton, setAddNewItemButton] = useState(null);
		const [badgeUIs, setBadgeUIs] = useState([]);
		const [errors, setErrors] = useState({questionHasError:false, questionError:"", currentEducationError:true});
		const [buttonOrEducation, setButtonOrEducation] = useState("button");
		const [institutionNameUI, setInstitutionNameUI] = useState(null);
		const [educationTypeUI, setEducationTypeUI] = useState(null);
		const [graduatedUI, setGraduatedUI] = useState(null);
		const [containsCurrentEducation, setContainsCurrentEducation] = useState(null);
		const [graduationYearOptions, setGraduationYearOptions] = useState(null);
		const [graduationYearUI, setGraduationYearUI] = useState(null);
		const [currentYearOptions, setCurrentYearOptions] = useState(null);
		const [currentYearUI, setCurrentYearUI] = useState(null);

		const sortEducation = useCallback(function (educationArray) {
			const sortedEducation = educationArray.sort((a, b) => {
				const aGraduated = a.graduated;
				const bGraduated = b.graduated;
				if (aGraduated == true && bGraduated != false) {
					return -1;
				}
				if (bGraduated == true && aGraduated != false) {
					return 1;
				}
				if (aGraduated == true && bGraduated == true) {
					if (a.graduationYear > b.graduationYear) {
						return -1;
					}
					if (a.graduationYear < b.graduationYear) {
						return 1;
					}
				}
				const aInstitution = a.institution;
				const bInstitution = b.institution;
				if (aInstitution > bInstitution) {
					return 1;
				}
				if (aInstitution < bInstitution) {
					return -1;
				}
				return 0;
			});
			return sortedEducation;
		}, []);

		//Set the default value of the education answers
		useEffect(() => {
			if (defaultValue == null) {
				setExistingEducationAnswers([]);
				return;
			}
			setNewEducationAnswers([]);
			setUpdatedEducationAnswers([]);
			setDeletedEducationAnswers([]);

			const defaultEducationAnswers = [];
			if (Array.isArray(defaultValue)) {
				for (const educationItem of defaultValue) {
					const newEducationItem = {
						...educationItem,
						unedited: educationItem
					}
					if (newEducationItem.graduationYear == null) {
						newEducationItem.graduated = false;
					}
					else {
						newEducationItem.graduated 	= true;
					}
					defaultEducationAnswers.push(newEducationItem);
				}
			}
			const validationResult = validateField(defaultEducationAnswers, questionValidations);
			const questionHasError = validationResult.hasError;
			if (questionHasError == true) {
				console.log("Error with given default tutor education: " + validationResult.errorMessage);
				setExistingEducationAnswers([]);
			}
			else {
				setExistingEducationAnswers(sortEducation(defaultEducationAnswers));
			}
		}, [defaultValue]);

		//Validate the education answers
		useEffect(() => {
			const answersToValidate = [...existingEducationAnswers, ...newEducationAnswers, ...updatedEducationAnswers];
			setEducationAnswers(answersToValidate);
			const validationResult = validateField(answersToValidate, questionValidations);
			const questionHasError = validationResult.hasError;
			const errorMessage = validationResult.errorMessage;
			setErrors((prev) => {
				return {
					...prev,
					questionHasError: questionHasError, 
					questionError: errorMessage
				};
			});

			const returnAnswers = {
				add: newEducationAnswers,
				update: updatedEducationAnswers,
				delete: deletedEducationAnswers,
				answers: answersToValidate.map((answer) => {
					const newAnswer = {...answer};
					delete newAnswer.unedited;
					if (answer.modelTypeID != null) {
						delete newAnswer.graduated;
					}
					return newAnswer;
				})
			}

			props.onChange(returnAnswers, validationResult);
		}, [existingEducationAnswers, deletedEducationAnswers, newEducationAnswers, updatedEducationAnswers]);

		//Validate the current education answer
		useEffect(() => {
			const currentValidationResult = validateField(currentEducationAnswer, educationAnswerValidations);
			const currentQuestionHasError = currentValidationResult.hasError;
			setErrors((prev) => {
				return {
					...prev,
					currentEducationError: currentQuestionHasError
				};
			});
		}, [currentEducationAnswer]);

		//Define the add new item button
		useEffect(() => {
			let newItemButtonText = "Add education";
			if (Array.isArray(educationAnswers) && educationAnswers.length > 0) {
				newItemButtonText = "Add more education";
			}
			setAddNewItemButton(<Button
				//When clicked, all this does is change the mode to the adding new time range item mode
				disabled={answersSuccessfullySubmitted}
				onClick={() => {
					setButtonOrEducation("subject");
				}}
			>
				{newItemButtonText}
			</Button>);
		}, [educationAnswers, answersSuccessfullySubmitted]);

		const checkEducationEdited = useCallback(function (education) {
			if (education == null || !isJSON(education)) {
				return null;
			}
			if (education.unedited == null) {
				return null;
			}
			if (education.modelTypeID == null) {
				return null;
			}

			const testEducation = {...education};
			delete testEducation.unedited;
			return !compareObjects(testEducation, education.unedited);
		}, []);

		//Define the cancel item button
		useEffect(() => {
			setCancelButton(<Button
				isDisabled={answersSuccessfullySubmitted}
				onClick={() => {
					if (answersSuccessfullySubmitted == true) {
						return;
					}

					const editStatus = checkEducationEdited(currentEducationAnswer);
					//Edit status is true it it has been edited from an existing education answer
					if (editStatus === true) {
						setExistingEducationAnswers((prev) => {
							const newEducationAnswer = {...currentEducationAnswer.unedited};
							newEducationAnswer.unedited = currentEducationAnswer.unedited;
							return sortEducation([...prev, newEducationAnswer]);
						});
					}
					//Edit status is false if it is an existing education answer but not edited
					else if (editStatus === false) {
						setExistingEducationAnswers((prev) => {
							return sortEducation([...prev, currentEducationAnswer]);
						});
					}
					else if (editStatus === null && currentEducationAnswer.unedited != null) {
						setNewEducationAnswers((prev) => {
							const newEducationAnswer = {...currentEducationAnswer.unedited};
							newEducationAnswer.unedited = currentEducationAnswer.unedited;
							return sortEducation([...prev, newEducationAnswer]);
						});
					}

					setButtonOrEducation("button");
					setCurrentEducationAnswer(blankEducation());
					
					const validationResult = validateField(existingEducationAnswers, questionValidations);

					setErrors((prev) => {
						return {
							...prev, 
							currentEducationError: true,
							questionError: validationResult.errorMessage
						};
					});
				}}
			>
				Cancel
			</Button>);
		}, [answersSuccessfullySubmitted, existingEducationAnswers, currentEducationAnswer, blankEducation]);

		//Check if the education answers contains a current education answer
		useEffect(() => {
			let includesCurrentEducation = false;
			for (const educationAnswer of educationAnswers) {
				if (educationAnswer.graduated == false) {
					includesCurrentEducation = true;
				}
			}
			setContainsCurrentEducation(includesCurrentEducation);
		}, [educationAnswers]);

		//Now create the array of badges with the correct properties so that to appear in the correct location
		//The row and column they're placed in depends on their index in the array
		useEffect(() => {
			const badgeArray = [];
			let badgeIndex = 0;
			const educationTitleProps = {
				fontSize: "16px",
				fontWeight: "bold",
				color: "#000000",
				marginTop: "15px",
				marginBottom: "-5px"
			}
			const getEducationString = function (educationItem) {
				const answerString = educationItem.institution + " - " + (educationItem.graduationYear || educationItem.currentYear);
				return answerString;
			}
			const getEducationKey = function (educationItem) {
				let answerKey = educationItem.institution + "-" + (educationItem.graduationYear || educationItem.currentYear);
				if (educationItem.modelTypeID != null) {
					answerKey = educationItem.modelTypeID;
				}
				return answerKey;
			}

			const EducationBadge = function (props) {
				const educationAnswer = props.educationAnswer;
				const answerString = getEducationString(educationAnswer);
				let clickFunction = props.clickFunction;
				let deleteFunction = props.deleteFunction;
				let restoreFunction = props.restoreFunction;
				if (answersSuccessfullySubmitted == true) {
					clickFunction = null;
					deleteFunction = null;
					restoreFunction = null;
				}

				let deleteButton = null;
				if (deleteFunction != null) {
					deleteButton = <Icon
						//This is a cross icon that when clicked removes the time range answer
						style={{
							cursor: "pointer",
							paddingLeft: 3,
							width: 20,
							height: 20,
						}}
						viewBox={{ width: 20, height: 20 }}
						paths={[
							{
								d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
								stroke: "white",
							},
						]}
						ariaLabel="button"
						onClick={deleteFunction}
					/>
				}

				let restoreButton = null;
				if (restoreFunction != null) {
					restoreButton = <Icon
					key={"recoverIcon"}
					width={"20px"}
					height={"20px"}
					style={{
						cursor: "pointer",
						paddingLeft: 3,
						width: 20,
						height: 20,
					}}
					as={RiArrowGoBackFill}
					onClick={restoreFunction}
				/>
				}

				let noButtonsIcon = null;
				if (deleteButton == null && restoreButton == null) {
					noButtonsIcon = <Icon 
						width={"20px"}
						height={"20px"}
					/>
				}
				
				return <Badge
					style={{
						alignItems: "center",
						marginRight: 10,
						marginTop: 10,
						backgroundColor: props.backgroundColor || props.backgroundColour || "#4c96e0",
						color:"#fff",
						cursor: clickFunction != null && "pointer"
					}}
					size="small"
					columnStart={(badgeIndex % 2) + 1}
					rowStart={Math.floor(badgeIndex / 2) + 1}
					onClick={clickFunction}
				>
					{answerString}
					{restoreButton}
					{deleteButton}
					{noButtonsIcon}
				</Badge>
			};

			//New answers
			if (Array.isArray(newEducationAnswers) && newEducationAnswers.length > 0) {
				badgeArray.push(<Text {...educationTitleProps} key={"NewEducationText"}>New Education:</Text>);
			}
			for (let answerIndex = 0; answerIndex < newEducationAnswers.length; answerIndex = answerIndex + 1) {
				const educationAnswer = newEducationAnswers[answerIndex];
				badgeArray.push(<EducationBadge
					key={getEducationKey(educationAnswer)}
					educationAnswer={educationAnswer}
					clickFunction={() => {
						setButtonOrEducation("subject");
						setNewEducationAnswers((prev) => {
							const newEducationArray = [...prev];
							newEducationArray.splice(answerIndex, 1);
							return sortEducation(newEducationArray);
						});
						setCurrentEducationAnswer(educationAnswer);
					}}
					deleteFunction={(event) => {
						event.stopPropagation();
						setNewEducationAnswers((prev) => {
							const newEducationArray = [...prev];
							newEducationArray.splice(answerIndex, 1);
							return sortEducation(newEducationArray);
						});
					}}
				/>);
				badgeIndex = badgeIndex + 1;
			}
			//Updated answers
			if (Array.isArray(updatedEducationAnswers) && updatedEducationAnswers.length > 0) {
				badgeArray.push(<Text {...educationTitleProps} key={"UpdatedEducationText"}>Updated Education:</Text>);
			}
			for (let answerIndex = 0; answerIndex < updatedEducationAnswers.length; answerIndex = answerIndex + 1) {
				const educationAnswer = updatedEducationAnswers[answerIndex];
				badgeArray.push(<EducationBadge
					key={getEducationKey(educationAnswer)}
					educationAnswer={educationAnswer}
					backgroundColor={"#a88d39"}
					clickFunction={() => {
						setButtonOrEducation("subject");
						setUpdatedEducationAnswers((prev) => {
							const newEducationArray = [...prev];
							newEducationArray.splice(answerIndex, 1);
							return sortEducation(newEducationArray);
						});
						setCurrentEducationAnswer(educationAnswer);
					}}
					restoreFunction={(event) => {
						event.stopPropagation();
						setExistingEducationAnswers((prev) => {
							const originalEducation = {...educationAnswer.unedited};
							originalEducation.unedited = educationAnswer.unedited;
							return sortEducation([...prev, originalEducation]);
						});
						setUpdatedEducationAnswers((prev) => {
							const newEducationArray = [...prev];
							newEducationArray.splice(answerIndex, 1);
							return sortEducation(newEducationArray);
						});
					}}
					deleteFunction={(event) => {
						event.stopPropagation();
						setUpdatedEducationAnswers((prev) => {
							const newEducationArray = [...prev];
							newEducationArray.splice(answerIndex, 1);
							return sortEducation(newEducationArray);
						});
						setDeletedEducationAnswers((prev) => {
							return sortEducation([...prev, educationAnswer]);
						});
					}}
				/>);
				badgeIndex = badgeIndex + 1;
			}
			//Deleted answers
			if (Array.isArray(deletedEducationAnswers) && deletedEducationAnswers.length > 0) {
				badgeArray.push(<Text {...educationTitleProps} key={"DeletedEducationText"}>Deleted Education:</Text>);
			}
			for (let answerIndex = 0; answerIndex < deletedEducationAnswers.length; answerIndex = answerIndex + 1) {
				const educationAnswer = deletedEducationAnswers[answerIndex];
				badgeArray.push(<EducationBadge
					key={getEducationKey(educationAnswer)}
					educationAnswer={educationAnswer}
					backgroundColor={"#c90808"}
					strikeThrough={true}
					restoreFunction={(event) => {
						event.stopPropagation();
						setDeletedEducationAnswers((prev) => {
							const newEducationArray = [...prev];
							newEducationArray.splice(answerIndex, 1);
							return sortEducation(newEducationArray);
						});
						const editStatus = checkEducationEdited(educationAnswer);
						if (editStatus === true) {
							setUpdatedEducationAnswers((prev) => {
								return sortEducation([...prev, educationAnswer]);
							});
						}
						else if (editStatus === false) {
							setExistingEducationAnswers((prev) => {
								return sortEducation([...prev, educationAnswer]);
							});
						}
					}}
				/>);
				badgeIndex = badgeIndex + 1;
			}
			//Existing answers
			if (Array.isArray(existingEducationAnswers) && existingEducationAnswers.length > 0) {
				badgeArray.push(<Text {...educationTitleProps} key={"ExistingEducationText"}>Existing Education:</Text>);
			}
			for (let answerIndex = 0; answerIndex < existingEducationAnswers.length; answerIndex = answerIndex + 1) {
				const educationAnswer = existingEducationAnswers[answerIndex];
				badgeArray.push(<EducationBadge
					key={getEducationKey(educationAnswer)}
					educationAnswer={educationAnswer}
					backgroundColor={"#0e418f"}
					clickFunction={() => {
						setButtonOrEducation("subject");
						setExistingEducationAnswers((prev) => {
							const newEducationArray = [...prev];
							newEducationArray.splice(answerIndex, 1);
							return sortEducation(newEducationArray);
						});
						setCurrentEducationAnswer(educationAnswer);
					}}
					deleteFunction={(event) => {
						event.stopPropagation();
						setExistingEducationAnswers((prev) => {
							const newEducationArray = [...prev];
							newEducationArray.splice(answerIndex, 1);
							return sortEducation(newEducationArray);
						});
						setDeletedEducationAnswers((prev) => {
							return sortEducation([...prev, educationAnswer]);
						});
					}}
				/>);
				badgeIndex = badgeIndex + 1;
			}
			setBadgeUIs(badgeArray);
		}, [answersSuccessfullySubmitted, existingEducationAnswers, newEducationAnswers, updatedEducationAnswers, deletedEducationAnswers]);

		//Update the graduated answer if there's already a current education answer
		useEffect(() => {
			setCurrentEducationAnswer((prev) => {
				if (containsCurrentEducation == true && prev.graduated != true) {
					return {...prev, graduated: true};
				}
				return prev
			});
		}, [containsCurrentEducation, currentEducationAnswer.graduated]);

		//Set the graduation year answer to null if gratuated is false
		//Set the current year answer to null if graduated is true
		useEffect(() => {
			//console.log(currentEducationAnswer.graduated);
			setCurrentEducationAnswer((prev) => {
				if (currentEducationAnswer.graduated == true && prev.currentYear != null) {
					return {...prev, currentYear: null};
				}
				if (currentEducationAnswer.graduated != true && prev.graduationYear != null) {
					return {...prev, graduationYear: null};
				}
				return prev;
			});
		}, [currentEducationAnswer.graduated]);

		//Define the add button that submits a new answer to the subject answers
		useEffect(() => {
			let addButtonColour = "";
			let addButtonDisabled = true;
			if (errors.currentEducationError !== true && answersSuccessfullySubmitted === false) {
				addButtonColour = "#82f768";
				addButtonDisabled = false;
			}

			setAddButton(<Button
				backgroundColor={addButtonColour}
				key={"AddButton"}
				isDisabled={addButtonDisabled}
				onClick={() => {
					//When clicked, run the necessary validations first
					//Rerun the basic validation
					const validationResultForAnswer = validateField(currentEducationAnswer, educationAnswerValidations);
					if (validationResultForAnswer.hasError != true) {
						//Create a temperary array of answers that can be used by the validation function to ensure they are valid, even with the new item
						const newEducationAnswers = [...educationAnswers, currentEducationAnswer];
						//Validate that none of the subject answers (including the new one) are for the same subject
						const validationResultForAnswers = validateField(newEducationAnswers, questionValidations);
						if (validationResultForAnswers.hasError != true) {
							const editStatus = checkEducationEdited(currentEducationAnswer);
							if (editStatus === true) {
								setUpdatedEducationAnswers((prev) => {
									return sortEducation([...prev, currentEducationAnswer]);
								});
							}
							else if (editStatus === false) {
								setExistingEducationAnswers((prev) => {
									return sortEducation([...prev, currentEducationAnswer]);
								});
							}
							else if (editStatus === null) {
								setNewEducationAnswers((prev) => {
									const newEducationAnswer = {...currentEducationAnswer};
									newEducationAnswer.unedited = currentEducationAnswer;
									return sortEducation([...prev, newEducationAnswer]);
								});
							}
							//Reset the state back to 'button' for a new time range to potentially be added
							setButtonOrEducation("button");
							//Reset the current subject answer to be blank for inputting a new subject
							setCurrentEducationAnswer(blankEducation());
							//Reset all errors
							setErrors((prev) => {
								return {
									...prev,
									currentEducationError: true,
								};
							});
						}
						else {
							//If the validation was unsuccessful, flag an error to tell the user
							setErrors((prev) => {
								return {
									...prev,
									currentEducationError: true,
									questionError: validationResultForAnswers.errorMessage,
								};
							});
						}
					}
					else {
						//If the validation was unsuccessful, flag an error to tell the user
						setErrors((prev) => {
							return {
								...prev,
								currentEducationError: true,
								questionError: validationResultForAnswer.errorMessage,
							};
						});
					}
				}}
			>
			Add
			</Button>);
		}, [currentEducationAnswer, educationAnswers, answersSuccessfullySubmitted, errors.currentEducationError, blankEducation]);

		//Set the year options
		useEffect(() => {
			const newGraduationYearOptions = [];
			const currentYear = new Date().getFullYear();
			for (let year = currentYear; year >= 1980; year = year - 1) {
				newGraduationYearOptions.push(year.toString());
			}
			setGraduationYearOptions(newGraduationYearOptions);

			const newCurrentYearOptions = {};
			for (const educationType of educationTypes) {
				let yearOptions = [];
				if (educationType == "Home Education" || educationType == "Secondary School" || educationType == "Sixth Form/College") {
					yearOptions = [
						"Year 12",
						"Year 13",
					];
				}
				else if (educationType == "University") {
					yearOptions = [
						"1st Year",
						"2nd Year",
						"3rd Year",
						"4th Year",
						"5th Year",
						"6th Year"
					];
				}
				else {
					for (let age = 16; age <= 80; age = age + 1) {
						yearOptions.push("Age " + age);
					}
				}
				newCurrentYearOptions[educationType] = yearOptions;
			}
			setCurrentYearOptions(newCurrentYearOptions);
		}, []);

		//Eduction type UI
		useEffect(() => {
			setEducationTypeUI(<FieldTemplate
				id={"InstitutionType"}
				fieldType={"dropdown"}
				label={"Institution type:"}
				value={currentEducationAnswer.type}
				disabled={answersSuccessfullySubmitted}
				options={educationTypes}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentEducationAnswer((prev) => {
						const newEducationModel = {...prev, type: newValue};
						if (newValue == "Home Education") {
							newEducationModel.institution = "Home Education";
						}
						else if (prev.type == "Home Education") {
							newEducationModel.institution = null;
						}
						return newEducationModel;
					});
				}}
			/>);
		}, [currentEducationAnswer.type]);

		//Institution name UI
		useEffect(() => {
			let label = "Institution name";
			if (currentEducationAnswer.type == "Secondary School") {
				label = "School name";
			}
			else if (currentEducationAnswer.type == "Sixth Form/College") {
				label = "College name";
			}
			else if (currentEducationAnswer.type == "University") {
				label = "University name";
			}
			// if (currentEducationAnswer.type == "Home Education") {
			// 	setCurrentEducationAnswer((prev) => {
			// 		return {...prev, institution: "Home Education"};
			// 	});
			// 	//setInstitutionNameUI(null);
			// 	//return;
			// }
			setInstitutionNameUI(<FieldTemplate
				id={"Institution"}
				fieldType={"text"}
				label={label + ":"}
				value={currentEducationAnswer.institution}
				// defaultValue={"charters"}
				placeholder={label}
				disabled={answersSuccessfullySubmitted}
				hidden={currentEducationAnswer.type == "Home Education"}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentEducationAnswer((prev) => {
						return {...prev, institution: newValue};
					});
				}}
			/> );
		} , [currentEducationAnswer.type, currentEducationAnswer.institution]);

		//Already graduated UI
		useEffect(() => {
			setGraduatedUI(<FieldTemplate
				id={"Graduated"}
				fieldType={"switch"} 
				label={"Already graduated?"}
				isChecked={currentEducationAnswer.graduated}
				disabled={answersSuccessfullySubmitted || containsCurrentEducation}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentEducationAnswer((prev) => {
						const newEducationModel = {...prev, graduated: newValue};
						return newEducationModel;
					});
				}}
			/>);
		} , [currentEducationAnswer.graduated, containsCurrentEducation]);

		//Graduation year UI
		useEffect(() => {
			if (graduationYearOptions == null) {
				//setGraduationYearUI(null);
				return;
			}
			setGraduationYearUI(<FieldTemplate
				id={"GraduationYear"}
				fieldType={"dropdown"}
				label={"Graduation year:"}
				value={currentEducationAnswer.graduationYear}
				disabled={answersSuccessfullySubmitted}
				options={graduationYearOptions}
				hidden={currentEducationAnswer.graduated != true}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentEducationAnswer((prev) => {
						return {...prev, graduationYear: newValue};
					});
				}}
			/>);
		} , [currentEducationAnswer.graduated, currentEducationAnswer.graduationYear, graduationYearOptions]);

		//Current year UI
		useEffect(() => {
			if (currentEducationAnswer.type == null || currentYearOptions == null) {
				//setCurrentYearUI(null);
				return;
			}
			setCurrentYearUI(<FieldTemplate
				id={"CurrentYear"}
				fieldType={"dropdown"} 
				label={"Current year:"}
				value={currentEducationAnswer.currentYear}
				disabled={answersSuccessfullySubmitted}
				hidden={currentEducationAnswer.graduated == true}
				options={currentYearOptions[currentEducationAnswer.type]}

				//Runs when it detects a change in the input field
				onChange={(newValue) => {
					setCurrentEducationAnswer((prev) => {
						return {...prev, currentYear: newValue};
					});
				}}
			/>);
		} , [currentEducationAnswer.graduated, currentEducationAnswer.currentYear, currentYearOptions, currentEducationAnswer.type]);

			
		//Depending on the current state, return the correct components
		let formItem = null
		if (buttonOrEducation == "button") {
			//If the current state is 'button', return the add new item button
			formItem = addNewItemButton;
		}
		else if (buttonOrEducation == "subject") {
			//If the current state is 'time' (meaning a new time range is being inputted), return the time fields as well as errors and relevant buttons
			formItem = <Flex direction={"column"} gap={"2vh"}>
				<Flex direction={"column"} gap={"2vh"}>
					<Flex direction={"column"}>
						{educationTypeUI}
						{institutionNameUI}
						{graduatedUI}
						{graduationYearUI}
						{currentYearUI}
					</Flex>
					<Flex justifyContent={"right"}>
						{cancelButton}
						{addButton}
					</Flex>
				</Flex>
			</Flex>
		}

		//Return text field component to calling function (Most likely a Form Template)
		return (
			<Flex direction="column" alignItems={"center"} alignSelf={"center"}>
				{formItem}
				<Flex direction={"column"} alignItems={"center"} gap={"0vh"}>
					{badgeUIs}
				</Flex>  
			</Flex>
		);
	}
	catch (error) {
		throw "TutorEducationTemplate Error: " + error;
	}
}

export default TutorEducationTemplate;