/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "adminFunctions",
            "endpoint": "https://8xz4epqjn9.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "airwallex",
            "endpoint": "https://ptpahjzatg.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "chats",
            "endpoint": "https://dlzedr54jb.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "errorReporting",
            "endpoint": "https://asfqpt9km8.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "formValidation",
            "endpoint": "https://054ysz0vw8.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "lessonDataModels",
            "endpoint": "https://x841dszzwj.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "lessonRequests",
            "endpoint": "https://asmp5oz4m3.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "matchingAlgorithm",
            "endpoint": "https://87xzfsxkdh.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "parentDataModels",
            "endpoint": "https://nqeyggu9xg.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "stripe",
            "endpoint": "https://m7x4qlio4g.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "studentDataModels",
            "endpoint": "https://fatto3mcec.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "teachingResources",
            "endpoint": "https://49d1cpwg92.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "transactions",
            "endpoint": "https://ohrc0b9j93.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "tutorDataModels",
            "endpoint": "https://uwjjzm62b5.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "updateUserAttributes",
            "endpoint": "https://zeo17tg7ek.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        },
        {
            "name": "userModels",
            "endpoint": "https://ucloxui5t2.execute-api.eu-west-2.amazonaws.com/testing",
            "region": "eu-west-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://6cqd5msqdzf3bcoo4dmbopvs34.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-2:033ad659-4c3c-43e3-8d42-0fcec4ba5b20",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_QB8DzYMsM",
    "aws_user_pools_web_client_id": "2pntdvfbdeo3om5e7jqqm8bmjh",
    "oauth": {
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.nextdoortutor.co.uk/",
        "redirectSignOut": "https://app.nextdoortutor.co.uk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
