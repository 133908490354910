/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const sendUserMessage = /* GraphQL */ `mutation SendUserMessage($id: ID!, $userID: String!, $message: AWSJSON!) {
  sendUserMessage(id: $id, userID: $userID, message: $message)
}
` as GeneratedMutation<
  APITypes.SendUserMessageMutationVariables,
  APITypes.SendUserMessageMutation
>;
export const sendParentMessage = /* GraphQL */ `mutation SendParentMessage($id: ID!, $message: AWSJSON!) {
  sendParentMessage(id: $id, message: $message)
}
` as GeneratedMutation<
  APITypes.SendParentMessageMutationVariables,
  APITypes.SendParentMessageMutation
>;
export const sendStudentMessage = /* GraphQL */ `mutation SendStudentMessage($id: ID!, $message: AWSJSON!) {
  sendStudentMessage(id: $id, message: $message)
}
` as GeneratedMutation<
  APITypes.SendStudentMessageMutationVariables,
  APITypes.SendStudentMessageMutation
>;
export const sendTutorMessage = /* GraphQL */ `mutation SendTutorMessage($id: ID!, $message: AWSJSON!) {
  sendTutorMessage(id: $id, message: $message)
}
` as GeneratedMutation<
  APITypes.SendTutorMessageMutationVariables,
  APITypes.SendTutorMessageMutation
>;
export const sendAdminMessage = /* GraphQL */ `mutation SendAdminMessage($id: ID!, $message: AWSJSON!) {
  sendAdminMessage(id: $id, message: $message)
}
` as GeneratedMutation<
  APITypes.SendAdminMessageMutationVariables,
  APITypes.SendAdminMessageMutation
>;
export const sendAdminRegionMessage = /* GraphQL */ `mutation SendAdminRegionMessage($id: ID!, $message: AWSJSON!) {
  sendAdminRegionMessage(id: $id, message: $message)
}
` as GeneratedMutation<
  APITypes.SendAdminRegionMessageMutationVariables,
  APITypes.SendAdminRegionMessageMutation
>;
export const logFormError = /* GraphQL */ `mutation LogFormError(
  $currentPage: String!
  $error: String!
  $answers: AWSJSON!
  $time: String!
  $userAgent: String
) {
  logFormError(
    currentPage: $currentPage
    error: $error
    answers: $answers
    time: $time
    userAgent: $userAgent
  )
}
` as GeneratedMutation<
  APITypes.LogFormErrorMutationVariables,
  APITypes.LogFormErrorMutation
>;
export const registration = /* GraphQL */ `mutation Registration($registrationType: String!, $attributes: AWSJSON) {
  registration(registrationType: $registrationType, attributes: $attributes)
}
` as GeneratedMutation<
  APITypes.RegistrationMutationVariables,
  APITypes.RegistrationMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser($attributes: AWSJSON!) {
  updateUser(attributes: $attributes)
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const updateUserAttributes = /* GraphQL */ `mutation UpdateUserAttributes($attributes: AWSJSON!) {
  updateUserAttributes(attributes: $attributes)
}
` as GeneratedMutation<
  APITypes.UpdateUserAttributesMutationVariables,
  APITypes.UpdateUserAttributesMutation
>;
export const parentAddStudent = /* GraphQL */ `mutation ParentAddStudent($attributes: AWSJSON) {
  parentAddStudent(attributes: $attributes)
}
` as GeneratedMutation<
  APITypes.ParentAddStudentMutationVariables,
  APITypes.ParentAddStudentMutation
>;
export const updateStudentModel = /* GraphQL */ `mutation UpdateStudentModel($studentID: String, $attributes: AWSJSON!) {
  updateStudentModel(studentID: $studentID, attributes: $attributes)
}
` as GeneratedMutation<
  APITypes.UpdateStudentModelMutationVariables,
  APITypes.UpdateStudentModelMutation
>;
export const updateTutorModel = /* GraphQL */ `mutation UpdateTutorModel($attributes: AWSJSON!) {
  updateTutorModel(attributes: $attributes)
}
` as GeneratedMutation<
  APITypes.UpdateTutorModelMutationVariables,
  APITypes.UpdateTutorModelMutation
>;
export const createTutorRequest = /* GraphQL */ `mutation CreateTutorRequest(
  $input: CreateTutorRequestInput!
  $condition: ModelTutorRequestConditionInput
) {
  createTutorRequest(input: $input, condition: $condition) {
    id
    tutorID
    lessonRequestID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTutorRequestMutationVariables,
  APITypes.CreateTutorRequestMutation
>;
export const updateTutorRequest = /* GraphQL */ `mutation UpdateTutorRequest(
  $input: UpdateTutorRequestInput!
  $condition: ModelTutorRequestConditionInput
) {
  updateTutorRequest(input: $input, condition: $condition) {
    id
    tutorID
    lessonRequestID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTutorRequestMutationVariables,
  APITypes.UpdateTutorRequestMutation
>;
export const deleteTutorRequest = /* GraphQL */ `mutation DeleteTutorRequest(
  $input: DeleteTutorRequestInput!
  $condition: ModelTutorRequestConditionInput
) {
  deleteTutorRequest(input: $input, condition: $condition) {
    id
    tutorID
    lessonRequestID
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTutorRequestMutationVariables,
  APITypes.DeleteTutorRequestMutation
>;
export const createParent = /* GraphQL */ `mutation CreateParent(
  $input: CreateParentInput!
  $condition: ModelParentConditionInput
) {
  createParent(input: $input, condition: $condition) {
    id
    name
    phoneNumber
    students
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateParentMutationVariables,
  APITypes.CreateParentMutation
>;
export const updateParent = /* GraphQL */ `mutation UpdateParent(
  $input: UpdateParentInput!
  $condition: ModelParentConditionInput
) {
  updateParent(input: $input, condition: $condition) {
    id
    name
    phoneNumber
    students
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateParentMutationVariables,
  APITypes.UpdateParentMutation
>;
export const deleteParent = /* GraphQL */ `mutation DeleteParent(
  $input: DeleteParentInput!
  $condition: ModelParentConditionInput
) {
  deleteParent(input: $input, condition: $condition) {
    id
    name
    phoneNumber
    students
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteParentMutationVariables,
  APITypes.DeleteParentMutation
>;
export const createLessonRequest = /* GraphQL */ `mutation CreateLessonRequest(
  $input: CreateLessonRequestInput!
  $condition: ModelLessonRequestConditionInput
) {
  createLessonRequest(input: $input, condition: $condition) {
    id
    studentID
    parentID
    subject
    sessionLength
    numSessions
    address
    availability
    lessonType
    schoolSet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLessonRequestMutationVariables,
  APITypes.CreateLessonRequestMutation
>;
export const updateLessonRequest = /* GraphQL */ `mutation UpdateLessonRequest(
  $input: UpdateLessonRequestInput!
  $condition: ModelLessonRequestConditionInput
) {
  updateLessonRequest(input: $input, condition: $condition) {
    id
    studentID
    parentID
    subject
    sessionLength
    numSessions
    address
    availability
    lessonType
    schoolSet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLessonRequestMutationVariables,
  APITypes.UpdateLessonRequestMutation
>;
export const deleteLessonRequest = /* GraphQL */ `mutation DeleteLessonRequest(
  $input: DeleteLessonRequestInput!
  $condition: ModelLessonRequestConditionInput
) {
  deleteLessonRequest(input: $input, condition: $condition) {
    id
    studentID
    parentID
    subject
    sessionLength
    numSessions
    address
    availability
    lessonType
    schoolSet
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLessonRequestMutationVariables,
  APITypes.DeleteLessonRequestMutation
>;
export const createStudent = /* GraphQL */ `mutation CreateStudent(
  $input: CreateStudentInput!
  $condition: ModelStudentConditionInput
) {
  createStudent(input: $input, condition: $condition) {
    id
    studentName
    parentID
    yearGroup
    postcode
    address
    defaultAvailability
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStudentMutationVariables,
  APITypes.CreateStudentMutation
>;
export const updateStudent = /* GraphQL */ `mutation UpdateStudent(
  $input: UpdateStudentInput!
  $condition: ModelStudentConditionInput
) {
  updateStudent(input: $input, condition: $condition) {
    id
    studentName
    parentID
    yearGroup
    postcode
    address
    defaultAvailability
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStudentMutationVariables,
  APITypes.UpdateStudentMutation
>;
export const deleteStudent = /* GraphQL */ `mutation DeleteStudent(
  $input: DeleteStudentInput!
  $condition: ModelStudentConditionInput
) {
  deleteStudent(input: $input, condition: $condition) {
    id
    studentName
    parentID
    yearGroup
    postcode
    address
    defaultAvailability
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStudentMutationVariables,
  APITypes.DeleteStudentMutation
>;
export const createLesson = /* GraphQL */ `mutation CreateLesson(
  $input: CreateLessonInput!
  $condition: ModelLessonConditionInput
) {
  createLesson(input: $input, condition: $condition) {
    id
    tutorID
    studentID
    parentID
    tutorName
    studentName
    subject
    day
    startTime
    endTime
    lessonType
    lessonLocation
    firstLessonDate
    lessonDates
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateLessonMutationVariables,
  APITypes.CreateLessonMutation
>;
export const updateLesson = /* GraphQL */ `mutation UpdateLesson(
  $input: UpdateLessonInput!
  $condition: ModelLessonConditionInput
) {
  updateLesson(input: $input, condition: $condition) {
    id
    tutorID
    studentID
    parentID
    tutorName
    studentName
    subject
    day
    startTime
    endTime
    lessonType
    lessonLocation
    firstLessonDate
    lessonDates
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateLessonMutationVariables,
  APITypes.UpdateLessonMutation
>;
export const deleteLesson = /* GraphQL */ `mutation DeleteLesson(
  $input: DeleteLessonInput!
  $condition: ModelLessonConditionInput
) {
  deleteLesson(input: $input, condition: $condition) {
    id
    tutorID
    studentID
    parentID
    tutorName
    studentName
    subject
    day
    startTime
    endTime
    lessonType
    lessonLocation
    firstLessonDate
    lessonDates
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLessonMutationVariables,
  APITypes.DeleteLessonMutation
>;
export const createTutor = /* GraphQL */ `mutation CreateTutor(
  $input: CreateTutorInput!
  $condition: ModelTutorConditionInput
) {
  createTutor(input: $input, condition: $condition) {
    id
    name
    email
    phoneNumber
    availability
    subjects
    referralCode
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTutorMutationVariables,
  APITypes.CreateTutorMutation
>;
export const updateTutor = /* GraphQL */ `mutation UpdateTutor(
  $input: UpdateTutorInput!
  $condition: ModelTutorConditionInput
) {
  updateTutor(input: $input, condition: $condition) {
    id
    name
    email
    phoneNumber
    availability
    subjects
    referralCode
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTutorMutationVariables,
  APITypes.UpdateTutorMutation
>;
export const deleteTutor = /* GraphQL */ `mutation DeleteTutor(
  $input: DeleteTutorInput!
  $condition: ModelTutorConditionInput
) {
  deleteTutor(input: $input, condition: $condition) {
    id
    name
    email
    phoneNumber
    availability
    subjects
    referralCode
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTutorMutationVariables,
  APITypes.DeleteTutorMutation
>;
export const createIssues = /* GraphQL */ `mutation CreateIssues(
  $input: CreateIssuesInput!
  $condition: ModelIssuesConditionInput
) {
  createIssues(input: $input, condition: $condition) {
    id
    title
    details
    submittedBy
    due
    priority
    progress
    assignedTo
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIssuesMutationVariables,
  APITypes.CreateIssuesMutation
>;
export const updateIssues = /* GraphQL */ `mutation UpdateIssues(
  $input: UpdateIssuesInput!
  $condition: ModelIssuesConditionInput
) {
  updateIssues(input: $input, condition: $condition) {
    id
    title
    details
    submittedBy
    due
    priority
    progress
    assignedTo
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIssuesMutationVariables,
  APITypes.UpdateIssuesMutation
>;
export const deleteIssues = /* GraphQL */ `mutation DeleteIssues(
  $input: DeleteIssuesInput!
  $condition: ModelIssuesConditionInput
) {
  deleteIssues(input: $input, condition: $condition) {
    id
    title
    details
    submittedBy
    due
    priority
    progress
    assignedTo
    type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIssuesMutationVariables,
  APITypes.DeleteIssuesMutation
>;
export const createUserActivity = /* GraphQL */ `mutation CreateUserActivity(
  $input: CreateUserActivityInput!
  $condition: ModelUserActivityConditionInput
) {
  createUserActivity(input: $input, condition: $condition) {
    userID
    time
    activity
    sessionStart
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserActivityMutationVariables,
  APITypes.CreateUserActivityMutation
>;
export const updateUserActivity = /* GraphQL */ `mutation UpdateUserActivity(
  $input: UpdateUserActivityInput!
  $condition: ModelUserActivityConditionInput
) {
  updateUserActivity(input: $input, condition: $condition) {
    userID
    time
    activity
    sessionStart
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserActivityMutationVariables,
  APITypes.UpdateUserActivityMutation
>;
export const deleteUserActivity = /* GraphQL */ `mutation DeleteUserActivity(
  $input: DeleteUserActivityInput!
  $condition: ModelUserActivityConditionInput
) {
  deleteUserActivity(input: $input, condition: $condition) {
    userID
    time
    activity
    sessionStart
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserActivityMutationVariables,
  APITypes.DeleteUserActivityMutation
>;
export const createUserInfo = /* GraphQL */ `mutation CreateUserInfo(
  $input: CreateUserInfoInput!
  $condition: ModelUserInfoConditionInput
) {
  createUserInfo(input: $input, condition: $condition) {
    userID
    firstNames
    lastName
    lastOnline
    currentSessionStartTime
    lastSessionDuration
    active
    pageVisibility
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserInfoMutationVariables,
  APITypes.CreateUserInfoMutation
>;
export const updateUserInfo = /* GraphQL */ `mutation UpdateUserInfo(
  $input: UpdateUserInfoInput!
  $condition: ModelUserInfoConditionInput
) {
  updateUserInfo(input: $input, condition: $condition) {
    userID
    firstNames
    lastName
    lastOnline
    currentSessionStartTime
    lastSessionDuration
    active
    pageVisibility
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserInfoMutationVariables,
  APITypes.UpdateUserInfoMutation
>;
export const deleteUserInfo = /* GraphQL */ `mutation DeleteUserInfo(
  $input: DeleteUserInfoInput!
  $condition: ModelUserInfoConditionInput
) {
  deleteUserInfo(input: $input, condition: $condition) {
    userID
    firstNames
    lastName
    lastOnline
    currentSessionStartTime
    lastSessionDuration
    active
    pageVisibility
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserInfoMutationVariables,
  APITypes.DeleteUserInfoMutation
>;
export const createWebsocketConnections = /* GraphQL */ `mutation CreateWebsocketConnections(
  $input: CreateWebsocketConnectionsInput!
  $condition: ModelWebsocketConnectionsConditionInput
) {
  createWebsocketConnections(input: $input, condition: $condition) {
    connectionID
    userID
    startTime
    active
    pageVisibility
    userAgent
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWebsocketConnectionsMutationVariables,
  APITypes.CreateWebsocketConnectionsMutation
>;
export const updateWebsocketConnections = /* GraphQL */ `mutation UpdateWebsocketConnections(
  $input: UpdateWebsocketConnectionsInput!
  $condition: ModelWebsocketConnectionsConditionInput
) {
  updateWebsocketConnections(input: $input, condition: $condition) {
    connectionID
    userID
    startTime
    active
    pageVisibility
    userAgent
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWebsocketConnectionsMutationVariables,
  APITypes.UpdateWebsocketConnectionsMutation
>;
export const deleteWebsocketConnections = /* GraphQL */ `mutation DeleteWebsocketConnections(
  $input: DeleteWebsocketConnectionsInput!
  $condition: ModelWebsocketConnectionsConditionInput
) {
  deleteWebsocketConnections(input: $input, condition: $condition) {
    connectionID
    userID
    startTime
    active
    pageVisibility
    userAgent
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteWebsocketConnectionsMutationVariables,
  APITypes.DeleteWebsocketConnectionsMutation
>;
export const createTestLesson = /* GraphQL */ `mutation CreateTestLesson(
  $input: CreateTestLessonInput!
  $condition: ModelTestLessonConditionInput
) {
  createTestLesson(input: $input, condition: $condition) {
    id
    tutorID
    studentOwnerID
    modelType
    sortKey
    typeID
    createdAt
    updatedAt
    version
    studentIDs
    subject
    lessonType
    sessionLength
    address {
      address1stLine
      postcode
      geocode
      __typename
    }
    day
    startTime
    endTime
    lessonStartDate
    lessonEndDate
    status
    confirmed
    type
    cancelType
    date
    lessonCost
    lessonDate
    disputed
    paidTutor
    lessonWage
    paidForLateCancellation
    qualificationScore
    firstNames
    lastNames
    phoneNumber
    yearGroup
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTestLessonMutationVariables,
  APITypes.CreateTestLessonMutation
>;
export const updateTestLesson = /* GraphQL */ `mutation UpdateTestLesson(
  $input: UpdateTestLessonInput!
  $condition: ModelTestLessonConditionInput
) {
  updateTestLesson(input: $input, condition: $condition) {
    id
    tutorID
    studentOwnerID
    modelType
    sortKey
    typeID
    createdAt
    updatedAt
    version
    studentIDs
    subject
    lessonType
    sessionLength
    address {
      address1stLine
      postcode
      geocode
      __typename
    }
    day
    startTime
    endTime
    lessonStartDate
    lessonEndDate
    status
    confirmed
    type
    cancelType
    date
    lessonCost
    lessonDate
    disputed
    paidTutor
    lessonWage
    paidForLateCancellation
    qualificationScore
    firstNames
    lastNames
    phoneNumber
    yearGroup
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTestLessonMutationVariables,
  APITypes.UpdateTestLessonMutation
>;
export const deleteTestLesson = /* GraphQL */ `mutation DeleteTestLesson(
  $input: DeleteTestLessonInput!
  $condition: ModelTestLessonConditionInput
) {
  deleteTestLesson(input: $input, condition: $condition) {
    id
    tutorID
    studentOwnerID
    modelType
    sortKey
    typeID
    createdAt
    updatedAt
    version
    studentIDs
    subject
    lessonType
    sessionLength
    address {
      address1stLine
      postcode
      geocode
      __typename
    }
    day
    startTime
    endTime
    lessonStartDate
    lessonEndDate
    status
    confirmed
    type
    cancelType
    date
    lessonCost
    lessonDate
    disputed
    paidTutor
    lessonWage
    paidForLateCancellation
    qualificationScore
    firstNames
    lastNames
    phoneNumber
    yearGroup
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTestLessonMutationVariables,
  APITypes.DeleteTestLessonMutation
>;
