import * as React from "react";
import { RadioGroupField, SelectField, SwitchField, TextField } from "@aws-amplify/ui-react";
import { useState, useEffect, useCallback } from 'react';
import { validateField } from "./form-components/FormValidation";
import OptionsTemplate from "./form-components/OptionsTemplate";
import { compareObjects, isJSON } from "../App";

export const fieldTypes = {
	dropdown: {
		component: SelectField,
		options: true,
		nullValue: null
		
	},
	switch: {
		component: SwitchField,
		eventValue: "checked",
		componentValue: "isChecked",
		nullValue: false
	},
	text: {
		component: TextField,
		nullValue: ""
	},
	radio: {
		component: RadioGroupField,
		options: "radio",
		style: {
			//size: "small",
			marginLeft: null,
		}
	}
};

const FieldTemplate = function(props) {
	const [fieldType, setFieldType] = useState(null);
	const [fieldHasOptions, setFieldHasOptions] = useState(false);
	const [options, setOptions] = useState(null);
	const [questionValidations, setQuestionValidations] = useState(null);

	const givenFieldType = props.fieldType || props.type;
	const disabled = props.disabled || props.isDisabled || props.answersSuccessfullySubmitted;
	const hidden = props.hidden;
	const placeholder = props.placeholder || props.placeHolder;
	const fieldTypeProps = (fieldTypes[fieldType || givenFieldType]) || ({} && console.error("Invalid field type: " + givenFieldType));
	const defaultValue = props.defaultValue;

	const extraLogging = props.extraLogging || false;
	
	const [answer, setAnswer] = useState(undefined);
	const [lastParentAnswer, setLastParentAnswer] = useState(undefined);

	const blankHandleChange = useCallback(function() {
		console.error(props.id + ": handleChange function not passed to component");
	}, [props.id]);
	const onChange = props.onChange || props.handleChange || blankHandleChange;

	const getFieldNullValue = useCallback(function() {
		let nullValue = fieldTypeProps.nullValue;
		if (nullValue === undefined) {
			nullValue = null;
		}
		return nullValue;
	}, [fieldTypeProps]);

	//Set the answer to the default value
	useEffect(() => {
		extraLogging && console.log("Setting " + props.id + " answer to new default value: " + defaultValue);
		setAnswer(defaultValue);
	}, [defaultValue]);

	//Set field type and default value
	useEffect(() => {
		if (givenFieldType == null) {
			console.error("Field type not given");
			return;
		}
		if (typeof(givenFieldType) != "string") {
			console.error("Invalid field type: " + givenFieldType);
			return;
		}
		const lowerCaseFieldType = givenFieldType.toLowerCase();
		const fieldProperties = fieldTypes[lowerCaseFieldType];
		if (fieldProperties == null) {
			console.error("Invalid field type: " + givenFieldType);
			return;
		}
		setFieldType(lowerCaseFieldType);
		if (fieldProperties.options != null) {
			setFieldHasOptions(fieldProperties.options);
		}
	}, []);

	//Set question validations
	useEffect(() => {
		const newValidations = [];
		if (Array.isArray(props.validations)) {
			for (const validation of props.validations) {
				if (isJSON(validation) && validation.hasOwnProperty("type")) {
					newValidations.push(validation);
				}
				else {
					console.error("Invalid validation: " + JSON.stringify(validation));
				}
			}
		}
		setQuestionValidations(newValidations);
	}, [props.validations]);

	const radioChange = useCallback(function (option) {
		setAnswer((prev) => {
			if (prev === option) {
				return null;
			}
			return prev;
		});
	}, []);

	//Set question options and set selected answer to default value
	useEffect(() => {
		if (fieldHasOptions == null || fieldHasOptions == false) {
			return;
		}
		const givenOptions = props.options || props.children;
		if (!Array.isArray(givenOptions) || givenOptions.length == 0) {
			//console.log("No options given for " + fieldType);
			setOptions([]);
			return;
		}
		let optionType = fieldType;
		if (fieldHasOptions !== true) {
			optionType = fieldHasOptions;
		}
		const newOptionsArray = OptionsTemplate(givenOptions, fieldType, radioChange);
		setOptions(newOptionsArray);
	}, [fieldHasOptions, fieldType, props.options, props.children]);

	//Set the answer to the first option if answer is null and placeholder is not given and options are given
	useEffect(() => {
		if (!fieldHasOptions || fieldHasOptions == "radio" || !Array.isArray(options) || options.length == 0 || hidden) {
			return;
		}

		setAnswer((prev) => {
			let answerInOptions = false;
			try {
				answerInOptions = options.find(option => (option.props.id || option.props.value) == prev) != null;
			}
			catch {
				answerInOptions = options.includes(prev);
			}
			if (!answerInOptions) {
				if (placeholder != null) {
					extraLogging && console.log("Setting " + props.id + " answer to placeholder as current answer wasn't in new options: " + null);
					return null;
				}
				else {
					let firstOption = options[0];
					if (typeof(firstOption) == "object" && firstOption.hasOwnProperty("props")) {
						firstOption = firstOption.props.id || firstOption.props.value;
					}
					extraLogging && console.log("Setting " + props.id + " answer to first option as current answer wasn't in new options: " + firstOption);
					return firstOption;
				}
			}

			return prev;
		});
	}, [fieldHasOptions, options, hidden, placeholder]);

	//Pass the new answer to the parent component
	useEffect(() => {
		if (questionValidations == null) {
			return;
		}
		if (answer === undefined) {
			extraLogging && console.log("Not sending " + props.id + " answer back to parent as answer is undefined: " + answer);
			return;
		}
		
		if (answer === lastParentAnswer) {
			extraLogging && console.log("Not sending " + props.id + " answer back to parent as answer hasn't changed: " + answer);
			return;
		}

		let validationResult = null;
		if (questionValidations.length > 0) {
			validationResult = validateField(answer, questionValidations)
		}
		extraLogging && console.log("Sending " + props.id + " answer back to parent: " + answer);
		onChange(answer, validationResult);
	}, [answer, questionValidations, lastParentAnswer]);

	//Set the selected answer to the value passed in
	useEffect(() => {
		let parentValue = props.value;
		if (parentValue === undefined) {
			parentValue = props.isChecked;
		}
		extraLogging && console.log("Value received for " + props.id + " from parent: " + parentValue);
		setLastParentAnswer(parentValue);
		if (parentValue === undefined) {
			return;
		}
		let newValue = parentValue;
		if (newValue == null || newValue == "") {
			extraLogging && console.log("Setting " + props.id + " answer to null value as given value is null: " + getFieldNullValue());
			newValue = getFieldNullValue();
		}
		setAnswer((prev) => {
			if (!compareObjects(prev, newValue)) {
				extraLogging && console.log("Updating answer for " + props.id + " with given value from parent: " + newValue);
				return newValue;
			}
			else {
				return prev;
			}
		});
	}, [props.value, props.isChecked, getFieldNullValue]);

	//If the field has options and the answer is null (and doesn't have a placeholder and isn't hidden), set the answer to the first option
	useEffect(() => {
		if (answer != null || answer === undefined || hidden) {
			return;
		}
		if (!fieldHasOptions || fieldHasOptions == "radio") {
			const nullValue = getFieldNullValue();
			if (nullValue !== null) {
				extraLogging && console.log("Setting " + props.id + " answer to default null value as answer was null: " + nullValue);
				setAnswer(nullValue);
			}
		}
		else if (fieldHasOptions && placeholder == null && Array.isArray(options) && options.length > 0) {
			let firstOption = options[0];
			if (typeof(firstOption) == "object" && firstOption.hasOwnProperty("props")) {
				firstOption = firstOption.props.value;
			}
			extraLogging && console.log("Setting " + props.id + " answer to first option as answer was null: " + firstOption);
			setAnswer(firstOption);
		}
	}, [answer, fieldHasOptions, options, placeholder, hidden, getFieldNullValue]);

	if (hidden) {
		return;
	}
	
	const fieldProps = {
		className: "QuestionField",
		size: props.size,
		label: props.label,
		//labelHidden: props.labelHidden,
		placeholder: placeholder,
		isDisabled: disabled,
		hidden: props.hidden,
		children: options,
		width: props.fieldWidth || "100%",
		style: {
			backgroundColor: props.backgroundColor || "#0000",
			...(props.style || {}).style,
			...(fieldTypeProps.style || {}).style
		},
		...fieldTypeProps.style,
		...props.style,
		[fieldTypeProps.componentValue || "value"]: answer || fieldTypeProps.nullValue,
		onChange: function(event) {
			const eventValue = fieldTypeProps.eventValue || "value";
			let newValue = event.target[eventValue];
			if (newValue == null || newValue == "") {
				extraLogging && console.log("Setting " + props.id + " answer to null value as new value is null: " + getFieldNullValue());
				newValue = getFieldNullValue();
			}
			if (answer != newValue) {
				setAnswer(newValue);
			}
		}
	};

	const Component = fieldTypeProps.component;
	return <Component 
		{...fieldProps} 
		key={props.key || props.id || fieldType}
	/>;
}

export default FieldTemplate;