import * as React from "react";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
import { useState, useEffect, useRef, createRef } from 'react';
import FieldTemplate from "./FieldTemplate";
import { FaAsterisk } from "react-icons/fa";
import { isJSON } from "../App";
import TutorQualificationsTemplate from "./form-components/TutorQualificationsTemplate";
import TutorEducationTemplate from "./form-components/TutorEducationTemplate";

export const questionTypes = {
	tutorqualifications: {
		alignItems: "center"
	},
	tutoreducation: {
		alignItems: "center"
	}
};

const QuestionTemplate = function(props) {
	try {
		const [labelUI, setLabelUI] = useState(null);
		const [descriptionUI, setDescriptionUI] = useState(null);
		const [hasValidation, setHasValidation] = useState(false);
		const [error, setError] = useState({questionError: ""});
		const [errorTextUI, setErrorTextUI] = useState(null);
		const [fieldUI, setFieldUI] = useState(null);
		const [justMounted, setJustMounted] = useState(true);
		const [questionProps, setQuestionProps] = useState(props);
		const [highlightLabel, setHighlightLabel] = useState(false);
		const questionRef = useRef(null);
		
		//Set question props
		useEffect(() => {
			if (props.type == null) {
				setQuestionProps(props);
			}
			const questionTypeProps = questionTypes[props.type] || {};
			setQuestionProps({...questionTypeProps, ...props});
		}, [props]);

		//Set question ref
		useEffect(() => {
			props.questionRefs[props.id] = {
				ref: questionRef,
				setHighlightLabel: setHighlightLabel
			};
		}, []);

		//Label UI
		useEffect(() => {
			const labelText = questionProps.label;
			const labelTextProps = questionProps.questionLabelProps || {};
			if (questionProps.required != true && (labelTextProps == null || labelText == null || labelText == "")) {
				setLabelUI(null);
				return;
			}
			let labelColour = labelTextProps.color || labelTextProps.colour || "#000000";
			if (questionProps.validationError != null) {
				labelColour = questionProps.validationErrorColour || "#ff0000";
			}
			else if (highlightLabel) {
				labelColour = "#12d400";
			}
			const labelTextUI = <Text
				className="QuestionLabel"
				{...labelTextProps}
				color={labelColour}
				children={labelText}
				textAlign={"left"}
				//backgroundColor={"#ccc"}
			/>

			const standardRequiredAsterisk = <FaAsterisk 
				color={"#ff0000"}
				style={{
					position: "absolute",
					left: "-30px",
				}}
				
			/>;
			const requiredAsterisk = questionProps.requiredAsterisk || standardRequiredAsterisk;
			setLabelUI(<Flex 
				alignItems={"center"}
				position={"relative"}
				marginLeft={questionProps.alignItems != "center" && "30px"}
			>
				{questionProps.required && requiredAsterisk}
				{labelTextUI}
			</Flex>);
		}, [questionProps.label, questionProps.questionLabelProps, questionProps.validationError, questionProps.validationErrorColour, questionProps.required, questionProps.requiredAsterisk, questionProps.alignItems, highlightLabel]);

		//Description UI
		useEffect(() => {
			const descriptionText = questionProps.description;
			const descriptionTextProps = questionProps.questionDescriptionProps ||{};
			if (descriptionTextProps == null || descriptionText == null || descriptionText == "") {
				setDescriptionUI(null);
				return;
			}
			setDescriptionUI(<label
				className="QuestionDescription"
				style={{alignSelf:"center"}}
				{...descriptionTextProps}
				children={descriptionText}
			/>);
		}, [questionProps.description, questionProps.questionDescriptionProps]);

		//Set validations
		useEffect(() => {
			const validations = [];
			if (Array.isArray(questionProps.validations)) {
				for (const validation of questionProps.validations) {
					if (isJSON(validation) && validation.hasOwnProperty("type")) {
						validations.push(validation);
					}
				}
			}
			if (validations.length > 0) {
				setHasValidation(true);
			}
			else {
				setHasValidation(false);
			}
		}, [questionProps.validations]);

		//Error Text UI
		useEffect(() => {
			if (hasValidation == false || (justMounted == true && !questionProps.showErrorImmediately) || error.errorMessage == null || error.errorMessage == "") {
				setErrorTextUI(null);
				return;
			}

			// if (props.id == "firstNames") {
			// 	console.log(error);
			// }
			
			setErrorTextUI(<Text 
				{...questionProps.errorTextProps} 
				position={"absolute"} 
				bottom={"25px"}
				children={error.errorMessage}
				alignSelf={"center"}
			/>);

			// setErrorTextUI(<Flex 
			// 	className="ErrorText"
			// 	direction={"column"} 
			// 	alignItems={"center"} 
			// 	justifyContent={"center"} 
			// 	position={"relative"}
			// >
				
			// </Flex>);
		}, [hasValidation, justMounted, error, questionProps.errorTextProps, questionProps.showErrorImmediately]);
		
		//Field UI
		useEffect(() => {
			const onChange = questionProps.onChange || questionProps.handleChange || function (questionID, newValue, error) {
				console.error("No onChange function given for question: " + questionID);
			};

			const handleChangeFunction = function (newValue, validationResult) {
				setHighlightLabel(false);
				let questionHasError = false;
				if (validationResult != null) {
					setError(validationResult);
					if (validationResult.hasError) {
						questionHasError = true;
					}
				}
				setJustMounted((prev) => {
					if (prev == false) {
						return false;
					}
					if (prev == true && newValue != null && newValue != "") {
						return false;
					}
					return true;
				});
				onChange(questionProps.id, newValue, questionHasError);
			}

			const questionChildProps = {
				...questionProps,
				onChange: handleChangeFunction,
				handleChange: handleChangeFunction
			};

			if (questionProps.type == null) {
				console.error("No type given for question: " + questionProps.id);
				return;
			}
			else if (questionProps.type.toLowerCase() == "tutorqualifications") {
				setFieldUI(<TutorQualificationsTemplate 
					{...questionChildProps}
				/>);
			}
			else if (questionProps.type.toLowerCase() == "tutoreducation") {
				setFieldUI(<TutorEducationTemplate 
					{...questionChildProps}
				/>);
			}
			else {
				setFieldUI(<FieldTemplate 
					{...questionChildProps}
					labelHidden={labelUI != null}
					description={descriptionUI == null && questionProps.description}
				/>);
			}
		}, [questionProps.id, questionProps.type, questionProps.onChange, questionProps.handleChange, labelUI, descriptionUI]);

		//Return text field component to calling function (Most likely a Form Template)
		return (
			<Flex
				ref={questionRef}
				className="Question"
				direction={"column"}
				position={"relative"}
				alignItems={questionProps.alignItems || "flex-start"}
				gap={questionProps.gap || "15px"}
				paddingBottom={hasValidation && "60px"}
				width={questionProps.width || "100%"}
				maxWidth={questionProps.maxWidth || "400px"}
				padding={questionProps.padding}
				paddingLeft={questionProps.paddingLeft || "10px"}
				paddingRight={questionProps.paddingRight || "10px"}
			>
				{labelUI}
				{descriptionUI}
				{fieldUI}
				{errorTextUI}
			</Flex>
		);
	}
	catch (error) {
		throw "DropDownTemplate Error: " + error;
	}
}

export default QuestionTemplate;