import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { TextField, Text, Flex, Button, Badge, Grid, Icon, SelectField, Autocomplete } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import DropDownQuestionTemplate from "./DropDownQuestionTemplate";
import OptionsTemplate from "./OptionsTemplate";
import AutoCompleteTemplate from "./AutoCompleteTemplate";

export const ParentChooseStudentsTemplate = function(props) {
  // try {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    let defaultValue = props.defaultSelectedStudents;
    const questionValidations = props.validations || [];
    const extraProps = props.extraProps || {};
    const selectType = extraProps.selectType || "dropdown";
    const disabled = props.disabled;

    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted;

    //Reset the default value incase it needs to be 
    if (defaultValue == null) {
      defaultValue = [];
    }
    //Define a blank time range for resetting to
    const blankStudent = {
      id: null,
      label: null
    };

    const [studentAnswers, setStudentAnswers] = useState(defaultValue);

    const studentOptions = props.studentOptions;
    const usedStudentOptions = [...studentOptions];
    for (let studentIndex = 0; studentIndex < usedStudentOptions.length; studentIndex = studentIndex + 1) {
      const studentOption = usedStudentOptions[studentIndex];
      const studentID = studentOption.id;
      for (const selectedStudent of studentAnswers) {
        if (selectedStudent.id == studentID) {
          usedStudentOptions.splice(studentIndex, 1);
          studentIndex = studentIndex - 1;
        }
      }
    }

    const defaultDropDown = usedStudentOptions[0];

    //Set state variables used to store the current state and any errors
    
    const [currentStudentAnswer, setCurrentStudentAnswer] = useState(defaultDropDown);
    const [errors, setErrors] = useState({questionHasError:false, questionError:"", currentStudentError:false});
    let defaultState = "student";
    if (defaultValue.length >= 1) {
      defaultState = "button";
    }
    const [buttonOrStudent, setButtonOrStudent] = useState(defaultState);

    const [forceUpdate, setForceUpdate] = useState(0);

    useEffect(() => {
      const validationResult = validateField(studentAnswers, questionValidations);
      const questionHasError = validationResult.hasError;
      props.handleChange(questionID, studentAnswers, questionHasError);
    }, []);


    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }
    //Get the text for the question label
    const labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>
    
    const descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"} textAlign={"center"}>
      {questionDescription}
    </Text>

    let errorText = null;
    if (errors.questionError != null && errors.questionError != "") {
      errorText = <Text {...errorTextProps} top={"0px"}>
        {errors.questionError}
      </Text>
    }

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    //Define the add new item button
    let newItemButtonText = "Add student";
    if (studentAnswers.length > 0) {
      newItemButtonText = "Add another student";
    }
    let addNewItemButton = null;
    if (usedStudentOptions.length > 0) {
      addNewItemButton = <Button
        disabled={answersSuccessfullySubmitted || disabled}
        onClick={() => {
          setButtonOrStudent("student");
          setCurrentStudentAnswer(defaultDropDown);
          const validationResult = validateField(defaultDropDown, [{type: "ValidStudentSelected", options: usedStudentOptions}]);
          const existingErrors = {...errors};
          existingErrors.currentStudentError = validationResult.hasError;
          existingErrors.questionError = validationResult.errorMessage;
          setErrors(existingErrors);
        }}
      >
        {newItemButtonText}
      </Button>
    }

    //Define the cancel item button
    const cancelButton = <Button
      //When clicked, it set the mode back to button (where the button is needed to be pressed to add a new time range)
      //It also resets the currently inputted time range and errors
      isDisabled={answersSuccessfullySubmitted || disabled}
      onClick={() => {
        setButtonOrStudent("button");
        setCurrentStudentAnswer(defaultDropDown);

        const validationResult = validateField(studentAnswers, questionValidations);

        const existingErrors = errors;
        existingErrors.currentStudentError = true;
        //existingErrors.questionError = "";
        existingErrors.questionError = validationResult.errorMessage;
        setErrors(existingErrors);
      }}
    >
      Cancel
    </Button>

    //This function is called when the cross on an existing student answer is pressed
    const removeStudent = function (student) {
      if (!disabled) {
        //Define a temperary new array for all the exising student answers
        const newStudents = [];
        //Add all the existing answers to the temperary array
        newStudents.push(...studentAnswers);

        //Find time range answer to remove and remove it
        for (let index = 0; index < newStudents.length; index = index + 1) {
          const existingStudent = newStudents[index];
          if (existingStudent === student) {
            newStudents.splice(index, 1);
            break
          }
        }
        //Update the main time range answers array with the updated array (with the item removed)
        setStudentAnswers(newStudents);

        const validationResult = validateField(newStudents, questionValidations);
        const questionHasError = validationResult.hasError;
        setErrors({questionHasError:questionHasError, questionError:validationResult.errorMessage, currentStudentError:errors.currentStudentError})
        props.handleChange(questionID, newStudents, questionHasError);
      }
    } 

    //Create badges for currently made times

    //Now create the actual array of badges with the correct properties so that to appear in the correct location
    //The row and column they're placed in depends on their index in the array
    const badgeArray = [];
    for (let badgeIndex = 0; badgeIndex < studentAnswers.length; badgeIndex = badgeIndex + 1) {
      const studentAnswer = studentAnswers[badgeIndex];
      const answerString = studentAnswer.label;

      let crossIcon = null
      if (!disabled) {
        crossIcon = <Icon
          //This is a cross icon that when clicked removes the time range answer
          style={{
            cursor: "pointer",
            paddingLeft: 3,
            width: 20,
            height: 20,
          }}
          viewBox={{ width: 20, height: 20 }}
          paths={[
            {
              d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
              stroke: "white",
            },
          ]}
          ariaLabel="button"
          onClick={() => {
            //When clicked, call the function to remove the item
            if (answersSuccessfullySubmitted=== false) {
              removeStudent(studentAnswer);
            }
          }}
        />
      }

      //Create the badge object
      const answerBadge = <Badge
        style={{
          alignItems: "center",
          marginRight: 10,
          marginTop: 10,
          backgroundColor:"#0e418f",
          color:"#e6edfa"
        }}
        key={answerString}
        size="small"
        columnStart={(badgeIndex % 3) + 1}
        rowStart={Math.floor(badgeIndex / 3) + 1}
        fontSize={"15px"}
      >
        {answerString}
        {crossIcon}
      </Badge>
      //Add the created badge to the badge array
      badgeArray.push(answerBadge)
    }

    let addButtonColour = "";
    let addButtonDisabled = true;
    if (errors.currentStudentError !== true && answersSuccessfullySubmitted === false) {
      addButtonColour = "#82f768";
      addButtonDisabled = false;
    }

    //Define the add button that submits a new answer to the student answers
    const addButton = <Button
      backgroundColor={addButtonColour}
      key={"AddButton"}
      isDisabled={addButtonDisabled}
      onClick={() => {
        //When clicked, run the necessary validations first
        //Rerun the basic validation
        const validationResultForAnswer = validateField(currentStudentAnswer, [{type: "ValidStudentSelected", options: usedStudentOptions}]);
        if (validationResultForAnswer.hasError != true) {
          //Create a temperary array of answers that can be used by the validation function to ensure they are valid, even with the new item
          const newStudentAnswers = [];
          newStudentAnswers.push(...studentAnswers);
          newStudentAnswers.push(currentStudentAnswer);
          //Validate that none of the student answers (including the new one) are for the same student
          const validationResultForAnswers = validateField(newStudentAnswers, questionValidations);

          if (validationResultForAnswers.hasError != true) {
            //If the validation was successful, update the actual student answers with the new answer added
            setStudentAnswers(newStudentAnswers);
            //Reset the state back to 'button' for a new student to potentially be added
            setButtonOrStudent("button");
            //Reset the current student answer to be blank for inputting a new student
            setCurrentStudentAnswer(blankStudent);
            //Reset all errors
            setErrors({questionHasError:false, questionError:"", currentStudentError:true});
            props.handleChange(questionID, newStudentAnswers, false);
          }
          else {
            //If the validation was unsuccessful, flag an error to tell the user
            const existingErrors = errors;
            existingErrors.currentStudentError = true;
            existingErrors.questionError = validationResultForAnswers.errorMessage;
            setErrors(existingErrors);
          }
        }
        else {
          //If the validation was unsuccessful, flag an error to tell the user
          const existingErrors = errors;
          existingErrors.currentStudentError = true;
          existingErrors.questionError = validationResultForAnswer.errorMessage;
          setErrors(existingErrors);
        }
      }}
    >
    Add
    </Button>

    const studentOptionComponents = OptionsTemplate(usedStudentOptions, "dropdown");
    
    let currentDropdownValue = "";
    if (currentStudentAnswer != null) {
      currentDropdownValue = currentStudentAnswer["id"];
    }
    let studentDropDown = <SelectField
      value={currentDropdownValue}
      disabled={answersSuccessfullySubmitted}

      //Runs when it detects a change in the input field
      onChange={(e) => {
        const currentIDValue = e.target.value;

        let currentLabelValue = null;
        for (const student of studentOptions) {
          if (student.id == currentIDValue) {
            currentLabelValue = student.label;
          }
        }

        let newAnswer = {
          id: currentIDValue,
          label: currentLabelValue,
          options: usedStudentOptions
        };

        setCurrentStudentAnswer(newAnswer);
        setForceUpdate(forceUpdate + 1);

        const validationResult = validateField(newAnswer, [{type: "ValidStudentSelected", options: usedStudentOptions}]);
        const questionHasError = validationResult.hasError;
        const questionErrorMessage = validationResult.errorMessage;

        const newStudentAnswers = [];
        newStudentAnswers.push(...studentAnswers);
        newStudentAnswers.push(currentStudentAnswer);
        const validationResultForAnswers = validateField(newStudentAnswers, questionValidations);
        if (questionHasError === true) {
          const existingErrors = errors;
          existingErrors.currentStudentError = questionHasError;
          existingErrors.questionError = questionErrorMessage;
          setErrors(existingErrors);
        }
        else {
          const existingErrors = errors;
          existingErrors.questionError = validationResultForAnswers.errorMessage;
          existingErrors.currentStudentError = validationResultForAnswers.hasError;
          setErrors(existingErrors);
        }
      }}

    > 
      {studentOptionComponents}
    </SelectField>

    const handleAutocompleteChange = function (questionID, answer, error) {
      const currentIDValue = answer.id;
      const currentLabelValue = answer.label;


      let newAnswer = {
        id: currentIDValue,
        label: currentLabelValue,
        options: usedStudentOptions
      };

      setCurrentStudentAnswer(newAnswer);
      setForceUpdate(forceUpdate + 1);

      const validationResult = validateField(newAnswer, [{type: "ValidStudentSelected", options: usedStudentOptions}]);
      const questionHasError = validationResult.hasError;
      const questionErrorMessage = validationResult.errorMessage;

      const newStudentAnswers = [];
      newStudentAnswers.push(...studentAnswers);
      newStudentAnswers.push(currentStudentAnswer);
      const validationResultForAnswers = validateField(newStudentAnswers, questionValidations);
      if (questionHasError === true) {
        const existingErrors = errors;
        existingErrors.currentStudentError = questionHasError;
        existingErrors.questionError = questionErrorMessage;
        setErrors(existingErrors);
      }
      else {
        const existingErrors = errors;
        existingErrors.questionError = validationResultForAnswers.errorMessage;
        existingErrors.currentStudentError = validationResultForAnswers.hasError;
        setErrors(existingErrors);
      }
    }
    if (selectType == "autocomplete") {
      studentDropDown = <AutoCompleteTemplate
        key={"StudentSelectAutocomplete" + JSON.stringify(studentAnswers)}
        id={"StudentSelectAutocomplete"}
        options={studentOptions}
        handleChange={handleAutocompleteChange}
      />
    }

    if (errors.questionHasError == false && errors.questionError != props.warning) {
      const newErrors = {...errors}
      newErrors.questionError = props.warning
      setErrors(newErrors);
    }

      
    //Depending on the current state, return the correct components
    let formItem = null
    if (buttonOrStudent == "button") {
      //If the current state is 'button', return the add new item button
      formItem = addNewItemButton;
    }
    else if (buttonOrStudent == "student") {
      //If the current state is 'time' (meaning a new time range is being inputted), return the time fields as well as errors and relevant buttons
      formItem = <Flex direction={"column"} gap={"2vh"}>
        <Flex direction={"column"} gap={"2vh"}>
          <Flex direction={"column"}>
            {studentDropDown}
          </Flex>
          <Flex justifyContent={"right"}>
            {cancelButton}
            {addButton}
          </Flex>
        </Flex>
      </Flex>
    }

    //Return text field component to calling function (Most likely a Form Template)
    return (
      studentAnswers,
      <Flex direction="column" maxWidth={"400px"}>
        <Flex direction="column" alignItems={"center"}>
          <Flex alignItems={"center"} >
            {renderedRequiredAsterisk}
            {labelText}
          </Flex>
          {descriptionText}
        </Flex>
        <Flex direction="column" alignItems={"center"} gap={"2vh"}>
          <Grid alignItems={"center"} rowGap={"0px"} columnGap={"1vw"}>
            {badgeArray}
          </Grid>  
          {formItem}
        </Flex>
        <Flex direction={"column"} alignItems={"center"} justifyContent={"center"} position={"relative"}>
          {errorText}
        </Flex>
      </Flex>
    );
  // }
  // catch (error) {
  //   throw "ParentChooseStudentTemplate Error: " + error;
  // }
}

export default ParentChooseStudentsTemplate;