import {React, useState, useEffect } from 'react';
import { AppNavButton, DropDownQuestionTemplate, FormTemplate } from '../../custom-ui-components';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { calculateLessonCost, compareObjects, getMatchingWeekAvailability, getMatchingWeekAvailabilityFromList, subjects, updateUserAttributesAPI } from '../../App';
import { LoadingSpinnerPage } from '../LoadingPage';
import PopUpTemplate from '../../custom-ui-components/form-components/PopUpTemplate';
import { Button, Divider, Flex, Icon, Text } from '@aws-amplify/ui-react';
import { ErrorLoadingPage } from '../ErrorLoadingPage';
import ParentChooseStudentsTemplate from '../../custom-ui-components/form-components/ParentChooseStudentsTemplate';
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

const ParentNewLessonRequest = function(props) {
    const titleTextProps = props.titleTextProps;
    const standardTextProps = props.standardTextProps;
    const APIFunctions = props.APIFunctions;
    //Get the current user to pass to subsequent functions
    const user = props.user;
    const userAttributes = user.attributes;
    const userAddressString = userAttributes["custom:addressList"] || "[]";
    const userAddressList = JSON.parse(userAddressString);

    const titleText = <Text {...titleTextProps} marginBottom={"5vh"}>Make a request for a new lesson</Text>

    const [selectedStudents, setSelectedStudents] = useState([]);
    const [questionAnswers, setQuestionAnswers] = useState({});
    const [pricingEstimate, setPricingEstimate] = useState(null);
    const [showPricingEstimate, setShowPricingEstimate] = useState(true);
    const [existingLessonRequests, setExistingLessonRequests] = useState(null);
    const [redirect, setRedirect] = useState(null);
    const [defaultAvailabilityPopUp1, setDefaultAvailabilityPopUp1] = useState(false);
    const [defaultAvailabilityPopUp2, setDefaultAvailabilityPopUp2] = useState(null);
    const [defaultAvailabilityPopUp3, setDefaultAvailabilityPopUp3] = useState(null);
    const [errorPopUp, setErrorPopUp] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [resetForm, setResetForm] = useState(0);
    const [bypassAvailabilitySanityCheck, setBypassAvailabilitySanityCheck] = useState({
        bypass: false,
        previousAvailability: null
    });
    const [matchedDefaultAvailability, setMatchedDefaultAvailability] = useState(null);

    useEffect(() => {
        if (props.parentLessonRequests != null && props.parentLessonRequests != "loading") {
            setExistingLessonRequests(props.parentLessonRequests);
        }
    }, [props.parentLessonRequests]);

    useEffect(() => {
        if (selectedStudents == null || selectedStudents.length == 0) {
            return;
        }

        const minCostAnswers = {...questionAnswers};
        const maxCostAnswers = {...questionAnswers};
        if (minCostAnswers.sessionLength == null || minCostAnswers.sessionLength == "") {
            minCostAnswers.sessionLength = "1 Hour";
        }
        if (maxCostAnswers.sessionLength == null || maxCostAnswers.sessionLength == "") {
            maxCostAnswers.sessionLength = "1 Hour";
        }
        if (minCostAnswers.numSessions == null || minCostAnswers.numSessions == "") {
            minCostAnswers.numSessions = 1;
        }
        if (maxCostAnswers.numSessions == null || maxCostAnswers.numSessions == "") {
            maxCostAnswers.numSessions = 1;
        }
        if (minCostAnswers.lessonType == null || minCostAnswers.lessonType == "Either" || minCostAnswers.lessonType == "") {
            minCostAnswers.lessonType = "Online";
        }
        if (maxCostAnswers.lessonType == null || maxCostAnswers.lessonType == "Either" || maxCostAnswers.lessonType == "") {
            maxCostAnswers.lessonType = "In-Person";
        }

        const aLevelMinCost = calculateLessonCost("A-Level", null, null, minCostAnswers.lessonType, true, minCostAnswers.sessionLength);
        const aLevelMaxCost = calculateLessonCost("A-Level", null, null, maxCostAnswers.lessonType, true, maxCostAnswers.sessionLength);
        const universityMinCost = calculateLessonCost("University", null, null, minCostAnswers.lessonType, true, minCostAnswers.sessionLength);
        const universityMaxCost = calculateLessonCost("University", null, null, maxCostAnswers.lessonType, true, maxCostAnswers.sessionLength);

        let lessonLengthString = minCostAnswers.sessionLength.toLowerCase();
        if (lessonLengthString[lessonLengthString.length - 1] == "s") {
            lessonLengthString = lessonLengthString.substring(0, lessonLengthString.length - 1);
        }

        let lessonTypeText = <Text {...standardTextProps} textAlign={"center"} fontSize={"16px"} alignSelf={"center"}>(Online/In-Person)</Text>;
        let aLevelPriceText = "A-Level tutor: " + aLevelMinCost + "/" + aLevelMaxCost;
        if (aLevelMinCost == aLevelMaxCost) {
            aLevelPriceText = "A-Level tutor: " + aLevelMinCost;
        }
        let universityPriceText = "University tutor: " + universityMinCost + "/" + universityMaxCost;
        if (universityMinCost == universityMaxCost) {
            universityPriceText = "University tutor: " + universityMinCost;
        }
        if (aLevelMinCost == aLevelMaxCost && universityMinCost == universityMaxCost) {
            lessonTypeText = <Text {...standardTextProps} textAlign={"center"} fontSize={"16px"} alignSelf={"center"}>({minCostAnswers.lessonType})</Text>;
        }

        let flexContents = <Flex 
            alignItems={"center"}
            gap={"0px"}
            minHeight={"50px"}
            padding={"10px"}
            height={"100%"}
            onClick={() => {
                setShowPricingEstimate(true);
            }}
            style={{cursor: "pointer"}}
        >   
            <Flex minHeight={"50px"} alignItems={"center"} gap={"0px"}>
                <Icon 
                    as={MdArrowBackIos}
                />
                <Text fontSize={"20px"}>£</Text>
            </Flex>
        </Flex>

        if (showPricingEstimate) {
            flexContents = <Flex
                alignItems={"center"}
            >   
                <Flex
                    onClick={() => {
                        setShowPricingEstimate(false);
                    }}
                    style={{cursor: "pointer"}}
                    height={"100%"}
                    minWidth={"30px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    marginRight={"-10px"}
                >
                    <Icon 
                        as={MdArrowForwardIos}
                    />
                </Flex>
                <Flex direction={"column"} gap={"0px"} padding={"10px"} paddingRight={"2vw"}>
                    <Text {...standardTextProps} textAlign={"left"} fontSize={"18px"} alignSelf={"center"}>Cost per {lessonLengthString} lesson:</Text>
                    {lessonTypeText}
                    <Text {...standardTextProps} textAlign={"left"} fontSize={"16px"}>{aLevelPriceText}</Text>
                    <Text {...standardTextProps} textAlign={"left"} fontSize={"16px"}>{universityPriceText}</Text>
                </Flex>
            </Flex>
        }
        
        setPricingEstimate(<Flex 
            position={"fixed"}  
            backgroundColor={"#a9db6b"}
            paddingRight={"10vw"}
            borderRadius={"5px"}
            right={"-10vw"}
            bottom={"15vh"}
            border={"2px solid black"}
            style={{zIndex: 2}}
        >
            {flexContents}
        </Flex>);
    }, [selectedStudents, questionAnswers, showPricingEstimate]);

    const location = useLocation();
    const extraProps = location.state || {};
    const defaultStudent = extraProps.student;

    const parentsStudents = props.parentsStudents;
    if (parentsStudents == null || existingLessonRequests == null) {
        return <LoadingSpinnerPage {...props} />
    }
    else if (parentsStudents == "loading" || existingLessonRequests == "loading") {
        return <LoadingSpinnerPage {...props} />
    }
    else if (parentsStudents == "error" || existingLessonRequests == "error") {
        return <ErrorLoadingPage {...props}/>
    }
    if (parentsStudents.length == 0) {
        return (<Flex direction={"column"}>
            <Text {...titleTextProps}>New Lesson Request</Text>
            <Flex paddingTop={"10vh"}>
                <Link to={"/Parent/AddNewStudent"}><AppNavButton text={"Please add a student first"}/></Link>
            </Flex>
        </Flex>);
    }

    if (existingLessonRequests == null) {
        return <LoadingSpinnerPage {...props} />
    }
    
    let numOutstandingLessonRequests = 0;
    for (const lessonRequest of existingLessonRequests) {
        if (lessonRequest.status != 3) {
            numOutstandingLessonRequests = numOutstandingLessonRequests + 1;
        }
    }

    if (numOutstandingLessonRequests >= 10 && formSubmitted == false) {
        const tooManyRequestsText1 = <Text {...standardTextProps}>You can only make 10 lesson requests at one time.</Text>
        const tooManyRequestsText2 = <Text {...standardTextProps}>If you would like to make another lesson request, please delete an old one.</Text>
        const tooManyRequestsText3 = <Text {...standardTextProps}>If you believe this is an error, please contact support.</Text>
        const viewRequestsButton = <Link to={"/Student/LessonRequests"} style={{textDecoration: 'none'}}><Button>View existing lesson requests</Button></Link>

        return (
            <div className='NewLessonRequestPage'>
                <Flex direction={"column"} alignItems={"center"} gap={"8vh"}>
                    {titleText}
                    <Flex direction={"column"} alignItems={"center"} >
                        {tooManyRequestsText1}
                        {tooManyRequestsText2}
                        {tooManyRequestsText3}
                    </Flex>
                    {viewRequestsButton}
                </Flex>
                {redirect}
            </div>
        );
    }

    const handleStudentChange = function (questionID, students, questionError) {
        const newSelectedStudents = [];
        for (const chosenStudent of students) {
            for (const studentModel of parentsStudents) {
                const studentID = studentModel.id;
                if (studentID == chosenStudent.id) {
                    newSelectedStudents.push(studentModel);
                    setResetForm(resetForm + 1);
                }
            }
        }
        setSelectedStudents(newSelectedStudents);

        const defaultAvailabilityList = [];
        for (const student of newSelectedStudents) {
            if (student.defaultAvailability != null) {
                defaultAvailabilityList.push(student.defaultAvailability);
            }
        }
        const matchingDefualtAvailability = getMatchingWeekAvailabilityFromList(defaultAvailabilityList);
        setMatchedDefaultAvailability(matchingDefualtAvailability);
    }

    const studentOptions = [];
    let defaultSelectedStudents = [];
    for (const studentModel of parentsStudents) {
        const studentID = studentModel.id;
        const studentName = studentModel.firstNames + " " + studentModel.lastName;
        const optionObject = {
            id: studentID,
            label: studentName
        };
        studentOptions.push(optionObject);
        if (defaultStudent == studentID) {
            defaultSelectedStudents.push(optionObject);
        }
    }

    const studentLabelProps = {...standardTextProps};
    studentLabelProps.fontSize = "19px";

    let defaultDropDownStudent = studentOptions[0].id;

    const selectStudentLabel = "Student(s):";
    let selectStudentDescription = null;
    if (studentOptions.length > 1) {
        selectStudentDescription = "You can request a lesson for multiple students";
    }
    const studentDropDown = <ParentChooseStudentsTemplate 
        id={"studentPicker"}
        required={true}
        handleChange={handleStudentChange}
        answersSuccessfullySubmitted={formSubmitted}
        studentOptions={studentOptions}
        description={selectStudentDescription}
        questionLabelProps={standardTextProps}
        label={selectStudentLabel}
        defaultDropDownStudent={defaultDropDownStudent}
        defaultSelectedStudents={defaultSelectedStudents}
    />

    const exitForm = function () {
        setRedirect(<Navigate to={"/Parent/LessonRequests"} />);
    }
    
    const askDefaultAvailability = async function() {
        if (selectedStudents.length == 1) {
            if (!compareObjects(selectedStudents[0].defaultAvailability, questionAnswers.availability )) {
                setDefaultAvailabilityPopUp1(true);
            }
            else {
                exitForm();
            }
        }
        else {
            exitForm();
        }
    }

    const submitAnswers = async function(returnedAnswers) {
        try {
            setFormSubmitted(true);
            const submitionAnswers = {...returnedAnswers};
            if (submitionAnswers.address == false) {
                submitionAnswers.address = null;
            }
            setQuestionAnswers(submitionAnswers);
            if (submitionAnswers.lessonType != "Online") {
                if (submitionAnswers.address.label == "New Address" || submitionAnswers.address.geocode == null) {
                    let addressAlreadyFound = false;
                    for (const existingAddress of userAddressList) {
                        const existingPostcode = existingAddress.postcode;
                        const newPostcode = submitionAnswers.address.postcode;
                        if (newPostcode == existingPostcode) {
                            addressAlreadyFound = true;
                            console.log("Found matching existing address");
                        }
                    }
                    if (addressAlreadyFound == false) {
                        delete submitionAnswers.address.label;
                        const newAddress = {address1stLine: submitionAnswers.address.address1stLine, postcode: submitionAnswers.address.postcode};
                        const newAddressList = [...userAddressList];
                        newAddressList.push(newAddress);
                        const newAddressListAttribute = {Name:"addressList", Value:newAddressList};

                        const updatedAttributes = await APIFunctions.updateUserAttributes(newAddressListAttribute);
                        let updatedAddressList = updatedAttributes[0]["custom:addressList"];
                        try {
                            updatedAddressList = JSON.parse(updatedAddressList);
                        } catch {}
                        for (const userAddress of updatedAddressList) {
                            const userAddress1stLine = userAddress.address1stLine;
                            const userPostcode = userAddress.postcode;
                            if (userAddress1stLine == newAddress.address1stLine && userPostcode == newAddress.postcode) {
                                const returnedGeocode = userAddress.geocode;
                                submitionAnswers.address.geocode = returnedGeocode;
                                break;
                            }
                        }
                    }
                }
                else {
                    delete submitionAnswers.address.label;
                }
            }
            submitionAnswers.numSessions = parseInt(submitionAnswers.numSessions, 10);
            submitionAnswers.parentID = user.username;
            submitionAnswers.studentIDs = [];
            for (const chosenStudent of selectedStudents) {
                submitionAnswers.studentIDs.push(chosenStudent.id);
            }
            submitionAnswers.subject = submitionAnswers.subjectInfo.subject;

            const answerArray = Object.entries(submitionAnswers);
            const newSchoolSets = {};
            for (const answer of answerArray) {
                const answerID = answer[0];
                const givenAnswer = answer[1];
                if (answerID.substring(0, 9) == "schoolSet") {
                    const studentID = answerID.split(",")[1];
                    newSchoolSets[studentID] = givenAnswer;
                    delete submitionAnswers[answerID];
                }
            }
            submitionAnswers.schoolSet = {...newSchoolSets};

            submitionAnswers.bypassSanityCheck = bypassAvailabilitySanityCheck;
            console.log(submitionAnswers);
            const newLessonRequest = await APIFunctions.createLessonRequest(submitionAnswers, "parent");
            console.log(newLessonRequest);
        }
        catch (error) {
            console.log(error);
            setFormSubmitted(false);
            if (error.response != null) {
                if (error.response.data != null) {
                    if (error.response.data == "Error invoking geocodeAddress function. Given error: Error geocoding address: \"Invalid address\"") {
                        const questionErrors = [{questionID: "address", errorMessage: "Invalid Address"}];
                        throw {questionErrors: questionErrors};
                    }
                    else {
                        throw {
                            message: "Error requesting a new lesson",
                            error: error
                        };
                    }
                }
                else {
                    throw error;
                }
            }
            else if (error.questionErrors != null) {
                for (const questionError of error.questionErrors) {
                    if (questionError.errorMessage == "Warning: Limited availability given" && error.questionErrors.length == 1) {
                        console.log("Limited availability");
                        setBypassAvailabilitySanityCheck({
                            bypass: true,
                            previousAvailability: {...returnedAnswers.availability}
                        });
                        throw {questionErrors: [{questionID: questionError.questionID, useErrorMessage: questionError.useErrorMessage, errorMessage: "Limited availability given, there is a lower chance of successfully matching with a tutor. Re-submit if you are sure you want to continue with the limited availability."}]}
                    }
                }
                throw error;
            }
            else {
                throw error;
            }
        }
    }

    
    const calculateExpectedCost = function (questionID, newAnswer) {
        const newAnswers = {...questionAnswers};
        newAnswers[questionID] = newAnswer;
        setQuestionAnswers(newAnswers);
    };

    const lessonRequestForm = function () {
        if (selectedStudents != null && selectedStudents.length > 0) {
            const questions = [];

            questions.push({
                id: "subjectInfo", 
                label: "Subject:", 
                type: "studentsubject",
                options: [...subjects],
                required: true, 
                validations: [{type: "ValidStudentSubject"}], 
                defaultValue: null, 
            });

            questions.push({
                id: "sessionLength", 
                label: "Session Length:", 
                type: "dropdown",
                options: ["45 Minutes", "1 Hour", "1 Hour 30 Minutes", "2 Hours"],
                required: true, 
                defaultValue: "1 Hour", 
            });

            questions.push({
                id: "numSessions", 
                label: "Number of lessons per week:", 
                type: "dropdown",
                options: [1, 2, 3, 4, 5, 6, 7],
                required: true, 
                defaultValue: 1, 
            });

            for (const selectedStudent of selectedStudents) {
                const studentID = selectedStudent.id;

                questions.push({
                    id: "schoolSet," + studentID, 
                    label: selectedStudent.firstNames + "'s current school set for subject:",
                    description: "What level is the student currently at",
                    options: ["Top Set", "Top/Middle Set", "Middle Set", "Middle/Bottom Set", "Bottom Set", "N/A"],
                    type: "dropdown",
                    required: false,
                    defaultValue: null, 
                });
            }
            
            questions.push({
                id: "lessonType", 
                label: "Lesson Type:",
                type: "radio",
                options: ["In-Person", "Online", "Either"],
                required: true, 
                defaultValue: "In-Person"
            });

            const addressOptions = [...userAddressList];
            questions.push({
                id: "address", 
                label: "Lesson address:", 
                type: "existingornewaddress", 
                required: true, 
                defaultValue: null, 
                options: addressOptions || [],
                onlyShow: {id:"lessonType", answers:["In-Person", "Either"]}
            });
            
            questions.push({
                id: "availability", 
                label: "Availability for lesson:", 
                type: "weekavailability", 
                required: true,
                defaultValue: matchedDefaultAvailability
            });

            questions.push({
                id: "requestedStartDate", 
                label: "Lesson start date:",
                description: "If you choose a date, we can't guarentee a lesson on that date, but we won't look for lessons before then", 
                type: "startdate", 
                required: true,
                defaultValue: new Date()
            });

            questions.push({
                id: "tutorLevelValue",
                label: "Tutor level:",
                description: "Would you prefer your tutor to be an A-Level student or university student? University student tutors will have a higher cost per hour than A-Level student tutors.", 
                type: "slider", 
                required: true,
                defaultValue: 4,
                minValue: 1,
                maxValue: 7,
                minValueText: "A-Level",
                maxValueText: "University",
                textValues: [
                    //"Only want an A-Level tutor",
                    "Would much rather an A-Level tutor",
                    "Would rather an A-Level tutor",
                    "A-Level tutor would be slightly preferable",
                    "I don't mind",
                    "University tutor would be slightly preferable",
                    "Would rather a university tutor",
                    "Would much rather a university tutor",
                    //"Only want a university tutor",
                ]
            });

            questions.push({
                id: "showExtraInfo", 
                label: "Is there anything else you want us to consider?", 
                type: "radio",
                options: ["Yes", "No"],
                required: true, 
                defaultValue: "No", 
            });

            questions.push({
                id: "notes", 
                label: "Extra notes:", 
                type: "text", 
                required: false,
                defaultValue: null,
                extraProps: {
                    textType: "paragraph"
                },
                validations: [{type: "ValidParagraph"}],
                onlyShow: {id:"showExtraInfo", answers:["Yes"]}
            });
        
            const lessonRequestFormTemplate = <FormTemplate
                {...props}
                key={"requestForm" + resetForm} 
                marginTop={"0px"} 
                submitAnswers={submitAnswers} 
                existingAnswers={questionAnswers} 
                questions={questions} 
                okButtonFunction={askDefaultAvailability} 
                returnAnswersEarly={{
                    questions: ["sessionLength", "numSessions", "lessonType", "tutorLevelValue"],
                    handleChange: calculateExpectedCost
                }}
            />
            return lessonRequestFormTemplate;
        }
        else {
            return null;
        }
    }
    
    const newRequestForm = lessonRequestForm();

    const defaultAvailabilityPopUpText = "Would you like to make this your default availability?";
    const yesButtonFunction = async function () {
        try {
            console.log("Updating default availability");
            setDefaultAvailabilityPopUp2("Updating default availability...");
            const newModel = await APIFunctions.updateParentsStudent({studentID: selectedStudents[0].id, defaultAvailability: questionAnswers.availability});
            console.log(newModel);
            setDefaultAvailabilityPopUp2(null)
            setDefaultAvailabilityPopUp3("Default availability updated");
        }
        catch (error) {
            console.log(error);
            setDefaultAvailabilityPopUp1(null);
            setDefaultAvailabilityPopUp2(null);
            setDefaultAvailabilityPopUp3(null);
            setErrorPopUp("Error setting default availability");
        }
    }
    const noButtonFunction = function () {
        exitForm();
    }

    const defaultAvailabilityPopUpObject1 = <PopUpTemplate 
        yesButtonFunction={yesButtonFunction} 
        noButtonFunction={noButtonFunction} 
        text={defaultAvailabilityPopUpText} 
        setPopUpVisibility={setDefaultAvailabilityPopUp1} 
        showXButton={false} 
    />

    const defaultAvailabilityPopUpObject2 = <PopUpTemplate 
        text={defaultAvailabilityPopUp2} 
        setPopUpVisibility={setDefaultAvailabilityPopUp2} 
        showXButton={false} 
    />

    const defaultAvailabilityPopUpObject3 = <PopUpTemplate 
        okButtonFunction={exitForm} 
        text={defaultAvailabilityPopUp3} 
        setPopUpVisibility={setDefaultAvailabilityPopUp3} 
        showXButton={false} 
    />

    const errorPopUpComponent = <PopUpTemplate 
        text={errorPopUp} 
        okButtonFunction={exitForm} 
        setPopUpVisibility={setErrorPopUp} 
        showXButton={false} 
    />

    return (
        <Flex direction={"column"} alignItems={"center"} gap={"2vh"} position={"relative"} width={"90vw"}>
            {redirect}
            {titleText}
            <Flex direction={"column"} alignItems={"center"}>
                {studentDropDown}
            </Flex>
            <Flex direction={"column"} alignItems={"left"}>
                {newRequestForm}
            </Flex>
            {pricingEstimate}
            {defaultAvailabilityPopUp1 && defaultAvailabilityPopUpObject1}
            {(defaultAvailabilityPopUp2 != null) && defaultAvailabilityPopUpObject2}
            {(defaultAvailabilityPopUp3 != null) && defaultAvailabilityPopUpObject3}
            {(errorPopUp != false) && errorPopUpComponent}
        </Flex>
    );
}

//Return the correct page back up to the app and index files (with authenticator to force login)
export default ParentNewLessonRequest;