import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { TextField, Text, Flex, Button, Badge, Grid, Icon, SelectField } from "@aws-amplify/ui-react";
import { useState, useEffect } from 'react';
import { validateField } from "./FormValidation";
import { blankAvailability, compareObjects, getSessionLength, getSessionLengthMins, isJSON } from "../../App";
import DropDownQuestionTemplate from "./DropDownQuestionTemplate";

export const DayAvailabilityTemplate = function(props) {
  // try {
    const labelTextProps = props.questionLabelProps || {};
    const errorTextProps = props.questionErrorProps || {};

    //Set question variables from passed properties
    const questionID = props.id;
    const questionLabel = props.label;
    const questionDescription = props.description;
    const placeHolder = props.placeHolder;
    let defaultValue = props.defaultValue;
    const required = props.required;
    const requiredAsterisk = props.requiredAsterisk;
    const questionValidations = props.validations || [];
    const answersSuccessfullySubmitted = props.answersSuccessfullySubmitted || false;
    const minSessionLength = props.minSessionLength || null;
    const minValidRanges = props.minValidRanges || 0;

    const currentWeekAvailability = props.weekAvailability || blankAvailability();
    const weekDay = props.day;

    let dayAvailabilityValidationExists = false;
    for (let validation of questionValidations) {
      if (validation.type === "ValidDayAvailability") {
        dayAvailabilityValidationExists = true;
      }
    }
    if (dayAvailabilityValidationExists === false) {
      questionValidations.push ({type:"ValidDayAvailability", minSessionLength: minSessionLength, minValidRanges: minValidRanges});
    }

    const timeRangeValidations = [{type: "ValidTimeRange"}]

    //Add an extra validation that will be in all day availability questions
    //This will ensure that the time range(s) given is always valid i.e. start time is before end time

    //Reset the default value incase it needs to be 
    if (defaultValue == null) {
      defaultValue = [];
    }

    //Define a blank time range for resetting to
    const blankTimeRange = {
      "startTime": "",
      "endTime": ""
    }

    if (minSessionLength != null) {
      blankTimeRange.minSessionLength = minSessionLength;
    }

    //Set state variables used to store the current state and any errors
    const [timeRangeAnswers, setTimeRangeAnswers] = useState([]);
    const [currentTimeRangeAnswer, setCurrentTimeRangeAnswer] = useState({...blankTimeRange});
    const [errors, setErrors] = useState({questionHasError:false, questionError:"", timeRangeError: false});
    const [buttonOrTime, setButtonOrTime] = useState("button");
    const [selectedCopyDay, setSelectedCopyDay] = useState(null);
    const [canCopyDay, setCanCopyDay] = useState(true);

    const [forceUpdate, setForceUpdate] = useState(0);

    useEffect(() => {
      document.addEventListener("keydown", keyPress);
      return () => {
        document.removeEventListener("keydown", keyPress);
      };
    }, [timeRangeAnswers, currentTimeRangeAnswer, errors, forceUpdate]);

    useEffect(() => {
      let validatedDefaults = [];
      if (defaultValue != null && defaultValue.length > 0) {
        let minSessionLengthMins = 0;
        if (minSessionLength != null) {
          minSessionLengthMins = getSessionLengthMins(minSessionLength);
        }
        for (const defaultTimeRange of defaultValue) {
          const validationResult = validateField(defaultTimeRange, timeRangeValidations);
          if (validationResult.hasError == true) {
            continue;
          }
          if (minSessionLength != null) {
            const startTime = defaultTimeRange.startTime;
            const endTime = defaultTimeRange.endTime;
            const timeRangeLength = getSessionLength(startTime, endTime, false);
            if (timeRangeLength < minSessionLengthMins) {
              continue;
            }
          }
          validatedDefaults.push(defaultTimeRange);
        }
        setTimeRangeAnswers(validatedDefaults);
      }
      

      const validationResult = validateField(validatedDefaults, questionValidations);
      const questionHasError = validationResult.hasError;
      setErrors({questionHasError:questionHasError, questionError:validationResult.errorMessage, timeRangeError: true});
      props.handleChange(questionID, validatedDefaults, questionHasError);
    }, []);

    let labelColour = labelTextProps.color || "#000000";
    if (props.validationError != null && props.validationError != null) {
      labelColour = props.validationErrorColour || "#ff0000";
    }

    const labelText = <Text {...labelTextProps} color={labelColour}>
      {questionLabel}
    </Text>
    
    let descriptionText = null
    if (questionDescription != null && questionDescription != "") {
      descriptionText = <Text {...labelTextProps} fontSize={"14px"} fontStyle={"italic"}>
        {questionDescription}
      </Text>
    }
    
    const errorText = <Text {...errorTextProps} position={"absolute"}>
      {errors.questionError}
    </Text>

    let renderedRequiredAsterisk = null;
    if (required == true) {
      renderedRequiredAsterisk = requiredAsterisk;
    }

    //Define the add new item button
    let newItemButtonText = "Add availability";
    if (timeRangeAnswers.length > 0) {
      newItemButtonText = "Add more availability";
    }
    const addNewItemButton = <Button
      //When clicked, all this does is change the mode to the adding new time range item mode
      disabled={answersSuccessfullySubmitted}
      onClick={() => {
        setButtonOrTime("time");
      }}
    >
      {newItemButtonText}
    </Button>

    //Define the cancel item button
    const cancelButton = <Button
      //When clicked, it set the mode back to button (where the button is needed to be pressed to add a new time range)
      //It also resets the currently inputted time range and errors
      disabled={answersSuccessfullySubmitted}
      onClick={() => {
        setButtonOrTime("button");
        setCurrentTimeRangeAnswer({...blankTimeRange});

        const validationResult = validateField(timeRangeAnswers, questionValidations);

        const existingErrors = {...errors};
        existingErrors.timeRangeError = true;
        //existingErrors.questionError = "";
        existingErrors.questionError = validationResult.errorMessage;
        setErrors(existingErrors);
      }}
    >
      Cancel
    </Button>

    //This function is called when the cross on an existing time range answer is pressed
    const removeTimeRange = function (timeRange) {
      //Define a temperary new array for all the exising time range answers
      const currentTimeRanges = [];
      //Add all the existing answers to the temperary array
      currentTimeRanges.push(...timeRangeAnswers);

      //Find time range answer to remove and remove it
      for (let index = 0; index < currentTimeRanges.length; index = index + 1) {
        const existingTimeRange = currentTimeRanges[index];
        if (compareObjects(existingTimeRange, timeRange)) {
          currentTimeRanges.splice(index, 1);
          break
        }
      }
      //Update the main time range answers array with the updated array (with the item removed)
      setTimeRangeAnswers(currentTimeRanges);

      const validationResultForAnswer = validateField(currentTimeRangeAnswer, timeRangeValidations);
      const validationResult = validateField(currentTimeRanges, questionValidations);
      const questionHasError = validationResult.hasError;
      setErrors({questionHasError:questionHasError, questionError:validationResult.errorMessage, timeRangeError: validationResultForAnswer.hasError});
      props.handleChange(questionID, currentTimeRanges, questionHasError);
    } 

    //Create badges for currently made times
    //This first loop adds all the time items to an array in chronological order
    const initialBadgeArray = [];
    for (let answerIndex = 0; answerIndex < timeRangeAnswers.length; answerIndex = answerIndex + 1) {
      //Iterate over every given time range answer and extract the time range details
      const timeRange = {...timeRangeAnswers[answerIndex]};
      const answerString = timeRange.startTime + " - " + timeRange.endTime;
      const currentStartTime = timeRange.startTime;
      const currentStartHour = parseInt(currentStartTime.split(":")[0]);
      const currentStartMinute = parseInt(currentStartTime.split(":")[1]);
      const currentStartTimeInt = (currentStartHour * 60) + currentStartMinute

      //Create a temperary badge element that will be used for ordering them
      const initialAnswerBadge = <Badge
        key={"initial" + answerString}
        starttime={timeRange.startTime}
        endtime={timeRange.endTime}
        timerange={timeRange}
      >
        {answerString}
      </Badge>

      //Perform a basic sorting algorithm where each item is added to a new array one at a time in the correct location
      let foundIndex = null;
      for (let badgeIndex = 0; badgeIndex < initialBadgeArray.length; badgeIndex = badgeIndex + 1) {
        const compareBadge = initialBadgeArray[badgeIndex];
        const compareStartTime = compareBadge.props.starttime;
        const compareStartHour = parseInt(compareStartTime.split(":")[0]);
        const compareStartMinute = parseInt(compareStartTime.split(":")[1]);
        const compareStartTimeInt = (compareStartHour * 60) + compareStartMinute;

        if (currentStartTimeInt < compareStartTimeInt) {
          initialBadgeArray.splice(badgeIndex, 0, initialAnswerBadge)
          foundIndex = badgeIndex;
          break
        }
      }
      if (foundIndex === null) {
        initialBadgeArray.push(initialAnswerBadge);
      }
    }

    //Now create the actual array of badges with the correct properties so that to appear in the correct location
    //The row and column they're placed in depends on their index in the array
    const badgeArray = [];
    for (let badgeIndex = 0; badgeIndex < timeRangeAnswers.length; badgeIndex = badgeIndex + 1) {
      const initialBadge = initialBadgeArray[badgeIndex];
      const timeRange = initialBadge.props.timerange;
      const answerString = timeRange.startTime + " - " + timeRange.endTime;

      //Create the badge object
      const answerBadge = <Badge
        style={{
          alignItems: "center",
          marginRight: 10,
          marginTop: 10,
          backgroundColor:"#0e418f",
          color:"#e6edfa"
        }}
        key={answerString}
        size="small"
        columnStart={(badgeIndex % 2) + 1}
        rowStart={Math.floor(badgeIndex / 2) + 1}
        starttime={timeRange.startTime}
        endtime={timeRange.endTime}
      >
        {answerString}
        <Icon
          //This is a cross icon that when clicked removes the time range answer
          style={{
            cursor: !answersSuccessfullySubmitted && "pointer",
            paddingLeft: 3,
            width: 20,
            height: 20,
          }}
          viewBox={{ width: 20, height: 20 }}
          paths={[
            {
              d: "M10 10l5.09-5.09L10 10l5.09 5.09L10 10zm0 0L4.91 4.91 10 10l-5.09 5.09L10 10z",
              stroke: "white",
            },
          ]}
          ariaLabel="button"
          onClick={() => {
            //When clicked, call the function to remove the item
            if (answersSuccessfullySubmitted === false) {
              removeTimeRange(timeRange);
            }
          }}
        />
      </Badge>
      //Add the created badge to the badge array
      badgeArray.push(answerBadge)
    }

    let addButtonColour = "";
    if (errors.timeRangeError !== true && answersSuccessfullySubmitted === false) {
      addButtonColour = "#82f768";
    }

    const addButtonFunction = function () {
      if (errors.timeRangeError !== true && answersSuccessfullySubmitted === false) {
        //When clicked, run the necessary validations first
        //Rerun the basic validation that ensures the start time is before the end time
        const validationResultForAnswer = validateField(currentTimeRangeAnswer, timeRangeValidations);
        if (validationResultForAnswer.hasError != true) {
          //Create a temperary array of answers that can be used by the validation function to ensure they are valid, even with the new item
          const currentTimeAnswers = [];
          currentTimeAnswers.push(...timeRangeAnswers);
          currentTimeAnswers.push(currentTimeRangeAnswer);
          //Validate that none of the time range answers (including the new one) overlap at all
          const validationResultForAnswers = validateField(currentTimeAnswers, [{"type": "NoTimeOverlaps"}]);

          if (validationResultForAnswers.hasError != true) {
            //If the validation was successful, update the actual time range answers with the new answer added
            setTimeRangeAnswers([...currentTimeAnswers]);
            //Reset the state back to 'button' for a new time range to potentially be added
            setButtonOrTime("button");
            //Reset the current time range to be blank for inputting a new range
            setCurrentTimeRangeAnswer({...blankTimeRange});
            //Reset all errors
            setErrors({questionHasError:false, questionError:"", timeRangeError:true});
            props.handleChange(questionID, currentTimeAnswers, false);
          }
          else {
            //If the validation was unsuccessful, flag an error to tell the user
            const existingErrors = {...errors};
            existingErrors.questionError = validationResultForAnswers.errorMessage;
            setErrors(existingErrors);
          }
        }
        else {
          //If the validation was unsuccessful, flag an error to tell the user
          const existingErrors = {...errors};
          existingErrors.timeRangeError = true;
          existingErrors.questionError = validationResultForAnswer.errorMessage;
          setErrors(existingErrors);
        }
      }
    }

    const keyPress = function (keyEvent) {
      const key = keyEvent.code;
      if (key == "Enter") {
        if (errors.timeRangeError != true && answersSuccessfullySubmitted == false) {
          addButtonFunction();
        }
      }
    }

    //Define the add button that submits a new answer to the time range answers
    const addButton = <Button
      backgroundColor={addButtonColour}
      key={"AddButton"}
      isDisabled={errors.timeRangeError == true || answersSuccessfullySubmitted == true}
      onClick={()=> {
        addButtonFunction();
      }}
    > 
      Add
    </Button>

    //Generate the text field (of type 'time') component for the start time
    const startTimeField = <TextField
      //color={myTheme.tokens.colors.font.primary.value}
      size={"medium"}
      placeholder={placeHolder}
      label={"Start Time"}
      errorMessage={errors.questionError}
      value={currentTimeRangeAnswer["startTime"]}
      type="time"
      disabled={answersSuccessfullySubmitted}

      autoFocus={true}

      //Runs when it detects a change in the input field
      onChange={(e) => {
        const currentValue = e.target.value;
        let newAnswer = {...currentTimeRangeAnswer};
        newAnswer["startTime"] = currentValue;
        setCurrentTimeRangeAnswer({...newAnswer});
        setForceUpdate(forceUpdate + 1);

        const validationResult = validateField(newAnswer, timeRangeValidations);
        const questionHasError = validationResult.hasError;
        const questionErrorMessage = validationResult.errorMessage;

        if (questionHasError === true) {
          const existingErrors = {...errors};
          existingErrors.timeRangeError = questionHasError;
          existingErrors.questionError = questionErrorMessage;
          setErrors(existingErrors);
        }
        else {
          const currentTimeAnswers = [];
          currentTimeAnswers.push(...timeRangeAnswers);
          currentTimeAnswers.push(newAnswer);
          const validationResultForAnswers = validateField(currentTimeAnswers, questionValidations);
          const existingErrors = {...errors};
          existingErrors.questionError = validationResultForAnswers.errorMessage;
          existingErrors.timeRangeError = validationResultForAnswers.hasError;
          setErrors(existingErrors);
        }
      }}
    ></TextField>

    //Generate the text field (of type 'time') component for the end time
    const endTimeField = <TextField
        //color={myTheme.tokens.colors.font.primary.value}
        size={"medium"}
        placeholder={placeHolder}
        label={"End Time"}
        errorMessage={errors.questionError}
        value={currentTimeRangeAnswer["endTime"]}
        type="time"
        disabled={answersSuccessfullySubmitted}

        //Runs when it detects a change in the input field
        onChange={(e) => {
          const currentValue = e.target.value;
          let newAnswer = {...currentTimeRangeAnswer};
          newAnswer["endTime"] = currentValue;

          setCurrentTimeRangeAnswer({...newAnswer});
          setForceUpdate(forceUpdate + 1);

          const validationResult = validateField(newAnswer, timeRangeValidations);
          const questionHasError = validationResult.hasError;
          const questionErrorMessage = validationResult.errorMessage;

          if (questionHasError === true) {
            const existingErrors = {...errors};
            existingErrors.timeRangeError = questionHasError;
            existingErrors.questionError = questionErrorMessage;
            setErrors(existingErrors);
          }
          else {
            const currentTimeAnswers = [];
            currentTimeAnswers.push(...timeRangeAnswers);
            currentTimeAnswers.push(newAnswer);
            const validationResultForAnswers = validateField(currentTimeAnswers, questionValidations);
            const existingErrors = {...errors};
            existingErrors.questionError = validationResultForAnswers.errorMessage;
            existingErrors.timeRangeError = validationResultForAnswers.hasError;
            setErrors(existingErrors);
          }
        }}
      ></TextField>
      

    const timeFormatWarning = <Text {...labelTextProps} fontSize={"14px"} alignSelf={"center"} marginTop={"-2vh"}> (Note 24hr time format)</Text>

    let copyAvailabilityButton = null;
    if (isJSON(currentWeekAvailability) && currentWeekAvailability[weekDay] != null && currentWeekAvailability[weekDay].length == 0) {
      const copyDays = [];
      const weekAvailabilityArray = Object.entries(currentWeekAvailability);
      for (const existingDayAvailabilityObject of weekAvailabilityArray) {
        const dayName = existingDayAvailabilityObject[0];
        const existingDayAvailability = existingDayAvailabilityObject[1];
        if (existingDayAvailability.length > 0 && dayName != weekDay) {
          copyDays.push(dayName);
        }
      }
      if (copyDays.length > 0) {
        if (selectedCopyDay == null) {
          setSelectedCopyDay(copyDays[0]);
        }
        const copyDropdown = <SelectField 
          options={copyDays} 
          onChange={(e) => {
            const currentValue = e.target.value;
            setSelectedCopyDay(currentValue);
          }} 
          value={selectedCopyDay}
          onMouseEnter={() => {
            setCanCopyDay(false);
          }}
          onMouseLeave={() => {
            setCanCopyDay(true);
          }}
          size={"small"}
        />
        copyAvailabilityButton = <Flex 

          backgroundColor={"#e6f595"}
          style={{cursor:"pointer", borderWidth:"3px", borderStyle:"solid"}}
          borderRadius={"15px"}
          alignItems={"center"}
          paddingTop={"4px"}
          paddingBottom={"6px"}
          paddingLeft={"10px"}
          paddingRight={"10px"}
          gap={"10px"}
          onClick={() => {
            if (canCopyDay) {
              const newTimeRangeAnswers = currentWeekAvailability[selectedCopyDay];
              const validationResultForAnswers = validateField(newTimeRangeAnswers, questionValidations);
              const existingErrors = {...errors};
              existingErrors.questionError = validationResultForAnswers.errorMessage;
              existingErrors.timeRangeError = validationResultForAnswers.hasError;
              setErrors(existingErrors);
              setTimeRangeAnswers(newTimeRangeAnswers);
              props.handleChange(questionID, newTimeRangeAnswers, validationResultForAnswers.hasError);
            }
          }}
        >
          <Text {...labelTextProps} fontSize={"14px"}>Copy from: </Text>
          {copyDropdown}
        </Flex>
      }
    }

    //Depending on the current state, return the correct components
    let formItem = null;
    if (buttonOrTime == "button") {
      //If the current state is 'button', return the add new item button
      formItem = addNewItemButton;
    }
    else if (buttonOrTime == "time") {
      //If the current state is 'time' (meaning a new time range is being inputted), return the time fields as well as errors and relevant buttons
      formItem = <Flex direction={"column"} gap={"2vh"}>
        <Flex direction={"column"} gap={"1vh"}>
          {timeFormatWarning}
          <Flex direction={"column"} gap={"2vh"}>
            <Flex>
              {startTimeField}
              {endTimeField}
            </Flex>
            <Flex justifyContent={"right"}>
              {cancelButton}
              {addButton}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    }

    //Return day availability component to calling function (Most likely a Form Template)
    return (
      <Flex direction="column" minWidth={"230px"} position={"relative"}>
        <Flex direction="column" marginBottom={"1vh"}>
          <Flex alignItems={"center"} justifyContent={"center"}>
            {renderedRequiredAsterisk}
            {labelText}
          </Flex>
          {descriptionText}
        </Flex>
        <Flex direction="column" alignItems={"center"}>
          {formItem}
          <Flex direction={"column"} alignItems={"center"} justifyContent={"center"}>
            {errorText}
          </Flex>
          {copyAvailabilityButton}
          <Grid >
            {badgeArray}
          </Grid>
        </Flex>
      </Flex>
    );
  // }
  // catch (error) {
  //   throw "DayAvailabilityTemplate Error: " + error;
  // }
}

export default DayAvailabilityTemplate;